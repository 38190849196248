import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import 'moment-timezone'
import '@asseinfo/react-kanban/dist/styles.css'
import 'antd/dist/reset.css'

import '../styles/style.css'
import Routes from './Routes'
import { light, dark } from './theme'
import { getNotificationCount } from '../redux/actions/notifications'
import io from 'socket.io-client'
import { setSocket } from '../redux/actions/socket'
import { base_url } from './constants'
import AlertModal from '../components/modal/AlertModal'
// import { useSound } from 'use-sound'
// import alertSound from '../assets/alert-long.mp3'
// import { Modal } from 'antd'
// import { t } from 'i18next'
// import useInteraction from '../service/useInteraction'

const App = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.theme.theme)
  const authenticatedID = localStorage.getItem('userId')
  const socket = io(base_url)
  // const [play, {stop}] = useSound(alertSound)
  // const [isModalVisible, setIsModalVisible] = useState(false)
  useEffect(() => {
    const type = localStorage.getItem('userType')
    type === 'employee' && dispatch(getNotificationCount())
    if (theme === 'light') {
      Object.keys(light).forEach((key) => {
        document.body.style.setProperty(`--${key}`, light[key])
      })
    } else {
      Object.keys(dark).forEach((key) => {
        document.body.style.setProperty(`--${key}`, dark[key])
      })
    }
  }, [theme])

  // for the use if first connect
  useEffect(() => {
    if (authenticatedID) {
      socket.emit('join', authenticatedID)
      dispatch(setSocket(socket))
    }
  }, [authenticatedID])

  // for the use if reconnect
  useEffect(() => {
    socket.on('reconnect', () => {
      if (authenticatedID) {
        socket.emit('join', authenticatedID)
      }
    })
  }, [])

  // socket.on('alert', (type, message) => {
  //   console.log(type, message)
  //   setIsModalVisible(true)
  //   play()
  // })

  // const onCancel = () => {
  //   setIsModalVisible(false)
  //   stop()
  // }

  // useEffect(() => {
  //   console.log('navigator.userActivation', navigator.userActivation)
  //   console.log('navigator', navigator.onLine)
  //   // if (authenticatedID) {
  //   //   const socket = io('http://192.168.100.12:5000')
  //   //   socket.emit('join', authenticatedID)
  //   //   dispatch(setSocket(socket))
  //   // }
  // }, [navigator.onLine])

  let language = localStorage.getItem('language')
  return (
    <>
      {console.log('V1.0.9')}
      <Routes />

      <div
        style={{
          background: 'var(--background)',
          color: 'var(--text)',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '1rem 2rem',
        }}
      >
        Language Preference :{' '}
        <span
          style={{
            marginLeft: '1rem',
            textDecoration: 'underline',
            cursor: 'pointer',
            color: language && language === 'en' ? 'var(--primary)' : 'var(--text)',
          }}
          onClick={() => {
            localStorage.setItem('language', 'en')
            window.location.reload()
          }}
        >
          EN
        </span>
        <span
          style={{
            marginLeft: '1rem',
            textDecoration: 'underline',
            cursor: 'pointer',
            color: language && language === 'ar' ? 'var(--primary)' : 'var(--text)',
          }}
          onClick={() => {
            localStorage.setItem('language', 'ar')
            window.location.reload()
          }}
        >
          AR
        </span>
      </div>
      <AlertModal />
      {/* <Modal title={t('Alert')} visible={isModalVisible} footer={null} onCancel={onCancel}>
       <div>
          <p>{t('You are offline')}</p>
       </div>
      </Modal> */}
    </>
  )
}

export default App
