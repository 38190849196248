import { useState } from 'react'
import { Modal, Button, Form, Input, Select, Radio, Spin } from 'antd'
import { createAccessories, updateAccessories, getCategories, getEmployeesName } from '../../redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'

const AddAccessories = ({ isEdit = false, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [categories, setCategories] = useState([])
  const [catId, setCatId] = useState(null)
  const [categoryType, setCateogryType] = useState('existing')
  const [catName, setCatName] = useState(null)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const employees = useSelector((state) => state.employees.employeesName)

  const showModal = async () => {
    setIsModalVisible(true)
    const cat = await getCategories()
    setCategories(cat)
    dispatch(getEmployeesName())
  }
  const onFinish = async (values) => {
    setLoading(true)
    if (isEdit) {
      await dispatch(
        updateAccessories(
          { ...values, categoryId: catId, categoryName: catName, id: data?._id },
          handleCancel,
        ),
      )
    } else {
      await dispatch(
        createAccessories({ ...values, categoryId: catId, categoryName: catName }, handleCancel),
      )
    }
    setLoading(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
    form.resetFields()
    setCatId(null)
    setCatName(null)
    setCateogryType('existing')
  }

  return (
    <>
      {isEdit ? (
        <Button
          style={{ marginRight: '5px' }}
          onClick={showModal}
          icon={<EditOutlined />}
          shape='circle'
        ></Button>
      ) : (
        <Button type='primary' className='addAccessoriesBtn' onClick={showModal}>
          {isEdit ? 'Update Accessory' : 'Add Accessories'}
        </Button>
      )}

      <Modal
        title={isEdit ? 'Update Accessory' : 'Add Accessories'}
        open={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
        style={{
          top: '20px',
        }}
      >
        <Form
          layout='vertical'
          form={form}
          fields={
            data
              ? [
                  {
                    name: 'category',
                    value:
                      categoryType === 'existing'
                        ? form.getFieldValue('category') || data?.category?._id
                        : '',
                  },
                  {
                    name: 'description',
                    value: data?.description,
                  },
                  {
                    name: 'name',
                    value: data?.name,
                  },
                  {
                    name: 'employee',
                    value: data?.employee?._id,
                  },
                ]
              : []
          }
          onFinish={onFinish}
        >
          <Radio.Group
            style={{ margin: '20px 0' }}
            onChange={(e) => {
              setCateogryType(e.target.value)
              if (e.target.value === 'existing') {
                form.setFieldsValue({
                  category: data && data.category?._id,
                })
              } else {
                form.setFieldsValue({
                  category: null,
                })
              }
            }}
            value={categoryType}
          >
            <Radio style={{ color: 'white' }} value={'existing'}>
              Select Existing Category
            </Radio>
            <Radio style={{ color: 'white' }} value={'new'}>
              Add New Category
            </Radio>
          </Radio.Group>
          <Form.Item
            label='Category'
            rules={[{ required: true, message: 'Category is Required' }]}
            name='category'
          >
            {categoryType === 'existing' ? (
              <Select
                showSearch
                optionFilterProp='children'
                allowClear
                placeholder='select category'
                style={{
                  border: '1px solid var(--borderLight)',
                }}
                onSelect={(value) => {
                  setCatId(value)
                  setCatName(null)
                }}
              >
                {categories?.map((item, ind) => (
                  <Select.Option key={ind} value={item._id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Input
                onChange={(e) => {
                  setCatName(e.target.value)
                  setCatId(null)
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            label='Name'
            rules={[{ required: true, message: 'Name is Required' }]}
            name='name'
          >
            <Input />
          </Form.Item>
          <Form.Item label='Description' name='description'>
            <Input />
          </Form.Item>
          <Form.Item
            label='Assigned To'
            rules={[{ required: true, message: 'Required' }]}
            name='employee'
          >
            <Select
              showSearch
              allowClear
              optionFilterProp='children'
              placeholder={t('select-employees')}
              style={{
                border: '1px solid var(--borderLight)',
              }}
            >
              {employees?.employee?.map((project, ind) => (
                <Select.Option key={ind} value={project._id}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            {loading ? (
              <Spin />
            ) : (
              <Button htmlType='submit' type='primary'>
                {isEdit ? 'Update' : 'Add'}
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddAccessories
