import { Column } from '@ant-design/charts'
import React from 'react'

const ColumnChartCompare = ({ data, yField, xField }) => {
  const config = {
    data,
    isGroup: true,
    xField: xField,
    yField: yField,
    seriesField: 'name',

    /** 设置颜色 */
    // color: ['#1ca9e6', '#f88c24'],

    /** 设置间距 */
    marginRatio: 0.4,
    label: false,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    // label: {
    //   // 可手动配置 label 数据标签位置
    //   position: 'middle',
    //   // 'top', 'middle', 'bottom'
    //   // 可配置附加的布局方法
    //   layout: [
    //     // 柱形图数据标签位置自动调整
    //     {
    //       type: 'interval-adjust-position',
    //     }, // 数据标签防遮挡
    //     {
    //       type: 'interval-hide-overlap',
    //     }, // 数据标签文颜色自动调整
    //     {
    //       type: 'adjust-color',
    //     },
    //   ],
    // },
  }
  return <Column {...config} className='coloumnChart' />
}

export default ColumnChartCompare
