import { Button, Col, DatePicker, notification, Row, Select } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import ColumnChart from '../components/Charts/ColumnChart'
import LineChart from '../components/Charts/LineChart'
import InfinityLoader from '../components/InfinityLoader'
import ProjectCostingPdf from '../components/PdfTemplate/ProjectCostingPdf'
import Layout from '../layout/Layout'
import { useTranslation } from 'react-i18next'
import {
  getAdminProject,
  getEmployeesName,
  getProjectCosting,
  getProjectDailyCosting,
  getProjectUsers,
  getUserProject,
} from '../redux'
import { MdOutlineArrowBack } from 'react-icons/md'
import ViewProjectLinks from '../components/modal/ViewProjectLinks'
import AddProject from '../components/modal/AddProject'
import ProjectDetailTable from '../components/view/ProjectDetailTable'
import ProjectDetailTableTwo from '../components/view/ProjectDetailTableTwo'
import { hoursToString } from '../functions/timeFormatter'
import EmployeeProjectReportPdf from '../components/PdfTemplate/EmployeeProjectReportPdf'

const ProjectSummary = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [DailyData, setDailyData] = useState(null)
  const [costingData, setcostingData] = useState(null)
  const [selectedYear, setselectedYear] = useState(moment().format('YYYY'))
  const [SelectedMonth, setSelectedMonth] = useState(moment().format('MMMM'))
  const [pdfMonth, setpdfMonth] = useState(null)
  const [detail, setDetail] = useState(null)
  const [Users, setUsers] = useState([])
  const [GroupFilter, setGroupFilter] = useState('Group Filter')
  const [PdfLoader, setPdfLoader] = useState(false)
  const history = useHistory()
  const [DailyCostingLoading, setDailyCostingLoading] = useState(false)
  const [CostingLoading, setCostingLoading] = useState(false)
  const employees = useSelector((state) => state.employees.employeesName)
  const { projectDetails } = useSelector((state) => state.project)

  const userType = localStorage.getItem('userType')
  const userId = localStorage.getItem('userId')

  const GetData = async () => {
    let dailyResponse = await dispatch(getProjectDailyCosting(id))
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      }
    })
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    })

    const costResponse = await dispatch(getProjectCosting(id))
    setcostingData(costResponse)
  }

  const getData = async () => {
    const data =
      userType === 'employee'
        ? await dispatch(
            getUserProject({
              id: id,
              users: null,
              date: null,
              keyword: null,
            }),
          )
        : await dispatch(
            getAdminProject({
              id: id,
              users: Users,
              date: null,
              keyword: null,
            }),
          )
    setDetail(data)
  }

  const getUsers = async () => {
    const users = await dispatch(getProjectUsers(id))
    setUsers(users)
  }
  useEffect(() => {
    setDetail(null)
    getData()
    getUsers()
    userType === 'admin' && dispatch(getEmployeesName())
  }, [id])

  useEffect(() => {
    GetData()
  }, [])

  const handleUserChange = async (value) => {
    const data = await dispatch(
      getAdminProject({
        id: id,
        users: value,
        date: '',
        keyword: '',
      }),
    )

    setDetail(data)
  }

  const handleUserChangeTL = async (value) => {
    // console.log('value', value)
    const data = await dispatch(
      getUserProject({
        id: id,
        users: value,
        // date: dateString,
        date: '',
        keyword: null,
      }),
    )

    setDetail(data)
  }

  const onDateFilter = async (value, dateString) => {
    let string = [dateString?.[0], dateString?.[1]]

    setGroupFilter('Group Filter')
    if (dateString?.every((v) => v === '')) {
      string = null
    }
    const data =
      userType === 'employee'
        ? await dispatch(
            getUserProject({
              id: id,
              users: null,
              date: string,
              keyword: null,
            }),
          )
        : await dispatch(
            getAdminProject({
              id: id,
              users: Users,
              date: string,
              keyword: '',
            }),
          )

    setDetail(data)
  }

  const handleGroupChange = async (value) => {
    // setDateChange(null)
    setGroupFilter(value)
    const data =
      userType === 'employee'
        ? await dispatch(
            getUserProject({
              id: id,
              users: null,
              date: null,
              keyword: value,
            }),
          )
        : await dispatch(
            getAdminProject({
              id: id,
              users: Users,
              date: null,
              keyword: value,
            }),
          )

    setDetail(data)
  }

  const OnDailyDateChange = async (value) => {
    const d = (v) => moment(value?.toISOString()).format(v)
    setpdfMonth(d('MM'))
    setSelectedMonth(d('MMMM'))
    setDailyCostingLoading(true)
    const dailyResponse = await dispatch(getProjectDailyCosting(id, { month: d('MM') }))
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      }
    })
    setDailyCostingLoading(false)
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    })
  }
  const OnCostingDateChange = async (value) => {
    const year = moment(value?.toISOString()).format('YYYY')
    setselectedYear(year)
    setCostingLoading(true)
    const costResponse = await dispatch(getProjectCosting(id, { year: year }))
    setcostingData(costResponse)
    setCostingLoading(false)
  }

  const onProjectCostingPdf = async () => {
    setPdfLoader(true)
    let detail = await dispatch(
      getAdminProject({
        id: id,
        month: pdfMonth,
      }),
    )
    if (detail?.timeline?.length !== 0) {
      const dateRange = [
        moment().month(SelectedMonth).startOf('month'),
        moment().month(SelectedMonth).endOf('day'),
      ]

      const start = moment(dateRange?.[0]).format('DD'),
        end = moment(dateRange?.[1]).format('DD'),
        month = moment(dateRange?.[1]).format('MM-YYYY')

      console.log({ dateRange })
      console.log({
        date: SelectedMonth,
        timeline: detail.timeline,
        totalHours: DailyData?.project?.totalHours,
        totalCost: DailyData?.project?.totalCost?.toFixed(0),
      })

      EmployeeProjectReportPdf(
        detail.timeline,
        dateRange,
        DailyData?.project?.totalHours,
        `${DailyData?.project?.title} - (${start}-${end})-${month}.pdf`,
        // 'Project-Costing-Monthly-Report.pdf',
        DailyData?.project,

        // `${t('project-report')}.pdf`,
      )
      // ProjectCostingPdf(
      //   detail?.timeline,
      //   SelectedMonth,
      //   DailyData?.project?.title,
      //   DailyData?.project?.totalHours,
      //   DailyData?.project?.totalCost?.toFixed(0),
      //   'Project-Costing-Monthly-Report.pdf',
      // )
      setPdfLoader(false)
    } else {
      notification.error({
        description: 'No Entries Found',
        duration: 2,
      })
      setPdfLoader(false)
    }
  }
  function thousands_separator(x) {
    x = x.toString()
    var pattern = /(-?\d+)(\d{3})/
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2')
    return x
  }
  return (
    <Layout active={'admin-projects'}>
      {DailyData === null ? (
        <InfinityLoader />
      ) : (
        <div className='summary-container projectDetails-container'>
          <MdOutlineArrowBack
            style={{
              cursor: 'pointer',
              color: 'var(--text)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '30px',
              width: '30px',
              padding: '4px',
              backgroundColor: 'rgba(128, 128, 128, 0.363)',
              borderRadius: '50%',
              marginBottom: '1rem',
            }}
            onClick={() => history.goBack()}
          />
          <br />
          <Row gutter={[20, 40]}>
            <Col xs={24} md={12} xl={6}>
              <h2 className='label'>
                {t('project-name')}: <span>{DailyData?.project?.title}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <h2 className='label'>
                {t('total-hours')}: <span>{hoursToString(DailyData?.project?.totalHours)}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <h2 className='label'>
                {t('total-cost')}:{' '}
                <span>
                  {t('rs')} {thousands_separator(DailyData?.project?.totalCost?.toFixed(0))}
                </span>{' '}
              </h2>
            </Col>
            {userType === 'admin' && (
              <Col xs={24} md={12} xl={6}>
                <h2 className='label'>
                  {t('budget')}:{' '}
                  <span>
                    {t('rs')} {thousands_separator(DailyData?.project?.budget)}
                  </span>{' '}
                </h2>
              </Col>
            )}
            <Col xs={24}>
              <h2 className='label'>
                {t('description')}: <span>{DailyData?.project?.description}</span>{' '}
              </h2>
            </Col>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {detail?.project?.links?.length > 0 && <ViewProjectLinks data={detail?.project} />}
                {userType === 'admin' && (
                  <AddProject
                    newProject={false}
                    data={detail?.project}
                    employees={employees}
                    getData={getData}
                  />
                )}
              </div>
            </Col>
            <Col xs={24}>
              <div className='header'>
                <h3 className='summaryHeading'>
                  {SelectedMonth} {t('day-wise-project-costing')}
                </h3>
                <div>
                  {DailyData && (
                    <Button
                      type='primary'
                      style={{ marginRight: '1rem' }}
                      onClick={() => onProjectCostingPdf()}
                      loading={PdfLoader}
                    >
                      {t('download-pdf')}
                    </Button>
                  )}
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={OnDailyDateChange}
                    style={{ width: '200px' }}
                    picker={'month'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <div className='card'>
                {DailyCostingLoading ? (
                  <div
                    style={{
                      height: '400px',
                    }}
                  >
                    <InfinityLoader />
                  </div>
                ) : (
                  <LineChart
                    data={DailyData?.costing ? DailyData?.costing : []}
                    yField='cost'
                    xField='day'
                  />
                )}
              </div>
            </Col>
            <Col xs={24}>
              <div className='header header2'>
                <h3 className='summaryHeading '>
                  {t('year')} {selectedYear} {t('monthly-costing')}
                </h3>
                <DatePicker
                  placeholder={t('select-year')}
                  onChange={OnCostingDateChange}
                  picker='year'
                  style={{ width: '200px' }}
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
              </div>
              <div className='card card2'>
                {CostingLoading ? (
                  <div
                    style={{
                      height: '400px',
                    }}
                  >
                    <InfinityLoader />
                  </div>
                ) : (
                  <ColumnChart
                    data={costingData?.costing ? costingData?.costing : []}
                    yField='cost'
                    xField='month'
                  />
                )}
              </div>
            </Col>
            <Col xs={24}>
              <div className='filterDiv'>
                {userType === 'admin' && (
                  <Select
                    mode='multiple'
                    allowClear
                    style={{
                      width: 'max-content',
                      minWidth: '200px',
                      cursor: 'pointer',
                    }}
                    placeholder={t('select-user')}
                    defaultValue={[]}
                    onChange={handleUserChange}
                  >
                    {Users?.map((data) => (
                      <Select.Option key={data?._id} value={data?._id}>
                        {' '}
                        {data?.name}{' '}
                      </Select.Option>
                    ))}
                  </Select>
                )}
                <div className='rightFilter'>
                  <Select
                    allowClear
                    style={{
                      width: '200px',
                      cursor: 'pointer',
                      marginRight: '1rem',
                    }}
                    placeholder='Group Filter'
                    onChange={handleGroupChange}
                    value={GroupFilter}
                  >
                    <Select.Option value={'week'}>{t('week')}</Select.Option>
                    <Select.Option value={'month'}>{t('month')}</Select.Option>
                  </Select>
                  {/* <DatePicker
                style={{ width: "200px" }}
                placeholder="Select Date"
                onChange={onDateFilter}
                // value={DateChange} 
              /> */}
                  <DatePicker.RangePicker
                    allowClear
                    onChange={onDateFilter}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <ProjectDetailTable detail={detail} userType={userType} getData={getData} />

              {userType === 'employee' && userId === projectDetails?.project?.teamLead[0] && (
                <div style={{ padding: '3rem 0' }}>
                  <div className='filterDiv'>
                    <Select
                      mode='multiple'
                      allowClear
                      style={{
                        width: 'max-content',
                        minWidth: '200px',
                        cursor: 'pointer',
                      }}
                      placeholder={t('select-user')}
                      defaultValue={[]}
                      onChange={handleUserChangeTL}
                    >
                      {Users?.map((data) => (
                        <Select.Option key={data?._id} value={data?._id}>
                          {' '}
                          {data?.name}{' '}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>

                  <ProjectDetailTableTwo detail={detail} userType={userType} getData={getData} />
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  )
}

export default ProjectSummary
