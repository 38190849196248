import * as types from '../types/generalTypes'

const initialState = {
  employees: null,
}

const cpuReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET_ALL_EMPLOYEES:
      return {
        ...state,
        employees: payload,
      }
    case types.GET_ALL_EMPLOYEES_NAME:
      return {
        ...state,
        employeesName: payload,
      }
    case types.GET_DESIGNATIONS: {
      return {
        ...state,
        designations: payload,
      }
    }
    default:
      return state
  }
}

export default cpuReducer
