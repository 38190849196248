import * as types from '../types/generalTypes'

const initialState = {
  moderators: null,
}

const moderatorReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET_ALL_MODERATORS:
      return {
        ...state,
        moderators: payload,
      }

    default:
      return state
  }
}

export default moderatorReducer
