import { DatePicker, Select, notification } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdOutlineArrowBack } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import InfinityLoader from '../components/InfinityLoader'
import AddProject from '../components/modal/AddProject'
import ViewProjectLinks from '../components/modal/ViewProjectLinks'
import ProjectDetailTable from '../components/view/ProjectDetailTable'
import ProjectDetailTableTwo from '../components/view/ProjectDetailTableTwo'
import Layout from '../layout/Layout'
import {
  addProjectTodo,
  getAdminProject,
  getEmployeesName,
  getProjectTodos,
  getProjectUsers,
  getUserProject,
} from '../redux'

const ProjectDetail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useParams()

  const allTodos = useSelector((state) => state.todo.projectTodos)
  const employees = useSelector((state) => state.employees.employeesName)
  const { projectDetails } = useSelector((state) => state.project)
  const userId = localStorage.getItem('userId')
  const { Option } = Select
  const history = useHistory()
  const [detail, setDetail] = useState(null)
  const [DateChange, setDateChange] = useState(null)
  const [GroupFilter, setGroupFilter] = useState('Group Filter')

  const userType = localStorage.getItem('userType')
  const [Users, setUsers] = useState([])

  const [title, setTitle] = useState('')
  const [desc, setDescription] = useState('')
  const [assignedTo, setAssignedTo] = useState([])
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   console.log('Users', Users)
  // }, [Users])

  const getData = async () => {
    const data =
      userType === 'employee'
        ? await dispatch(
            getUserProject({
              id: id,
              users: null,
              date: null,
              keyword: null,
            }),
          )
        : await dispatch(
            getAdminProject({
              id: id,
              users: Users,
              date: null,
              keyword: null,
            }),
          )
    setDetail(data)
  }

  const getUsers = async () => {
    const users = await dispatch(getProjectUsers(id))
    setUsers(users)
  }
  useEffect(() => {
    setDetail(null)
    getData()
    getUsers()
    dispatch(getProjectTodos(id))
    userType === 'admin' && dispatch(getEmployeesName())
  }, [id])

  const handleUserChange = async (value) => {
    const data = await dispatch(
      getAdminProject({
        id: id,
        users: value,
        date: '',
        keyword: '',
      }),
    )

    setDetail(data)
  }

  const handleUserChangeTL = async (value) => {
    // console.log('value', value)
    const data = await dispatch(
      getUserProject({
        id: id,
        users: value,
        // date: dateString,
        date: '',
        keyword: null,
      }),
    )

    setDetail(data)
  }

  const onDateFilter = async (value, dateString) => {
    setDateChange(moment(dateString))
    console.log('dateString', dateString)

    const start = moment(dateString[0]).toDate(),
      end = moment(dateString[1]).toDate()

    // console.log(DateChange)
    setGroupFilter('Group Filter')
    // console.log(dateString)
    const data =
      userType === 'employee'
        ? await dispatch(
            getUserProject({
              id: id,
              users: null,
              date: [start, end],
              keyword: null,
            }),
          )
        : await dispatch(
            getAdminProject({
              id: id,
              users: Users,
              date: [start, end],
              keyword: '',
            }),
          )

    setDetail(data)
  }

  const handleGroupChange = async (value) => {
    setDateChange(null)
    setGroupFilter(value)
    const data =
      userType === 'employee'
        ? await dispatch(
            getUserProject({
              id: id,
              users: null,
              date: null,
              keyword: value,
            }),
          )
        : await dispatch(
            getAdminProject({
              id: id,
              users: Users,
              date: null,
              keyword: value,
            }),
          )

    setDetail(data)
  }

  const addTodoItem = async () => {
    if (title && desc) {
      setLoading(true)
      let payload = {
        title,
        description: desc,
        assignedTo,
        project: id,
      }
      await dispatch(addProjectTodo(payload))
      setTitle('')
      setDescription('')
      setLoading(false)
    } else {
      notification.warning({
        message: 'Please add title and description both',
        duration: 5,
      })
    }
  }

  return (
    <Layout active={'admin-projects'}>
      {detail === null ? (
        <InfinityLoader />
      ) : (
        <div className='projectDetails-container'>
          <div className='header'>
            <MdOutlineArrowBack className='back' onClick={() => history.goBack()} />
            <h2>
              {t('project')} : <span>{detail?.project?.title}</span>
            </h2>
            {userType === 'admin' && (
              <>
                <h2>
                  {t('total-cost')} : <span>Rs. {detail?.project?.totalCost?.toFixed(0)}</span>
                </h2>
                <h2>
                  {t('budget')} : <span>{detail?.project?.budget}</span>
                </h2>
              </>
            )}
            <h2>
              {t('total-hours')} : <span>{Number(detail?.project?.totalHours).toFixed(2)}</span>
            </h2>

            <p>
              {t('description')} : <span>{detail?.project?.description}</span>
            </p>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {detail?.project?.links?.length > 0 && <ViewProjectLinks data={detail?.project} />}
              {userType === 'admin' && (
                <AddProject
                  newProject={false}
                  data={detail?.project}
                  employees={employees}
                  getData={getData}
                />
              )}
            </div>
            {/* {userType == 'admin' && <ViewProjectPresence />} */}
          </div>
          {/* <div className='project-todos'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <h2>{t('project-todos')}</h2>
              {userType === 'admin' && (
                <div
                >
                  <Button
                    type='primary'
                    onClick={() => {
                      history.push(`/admin/deleted-todos/${id}`)
                    }}
                  >
                    {t('deleted-todos')}
                  </Button>
                </div>
              )}
            </div>
            {(userType === 'admin' || userId === projectDetails?.project?.teamLead) && (
              <div style={{ margin: '30px 0' }}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} md={6}>
                    <Input
                      placeholder={t('add-title')}
                      onChange={(e) => {
                        setTitle(e.target.value)
                      }}
                    />
                  </Col>

                  <Col xs={24} md={9}>
                    <Input
                      placeholder={t('write-note')}
                      onChange={(e) => {
                        setDescription(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={24} md={5}>
                    <Mentions
                      className='mentions'
                      placeholder={t('assign-to')}
                      onChange={() => {}}
                      onSelect={(value) => {
                        setAssignedTo([value.key, ...assignedTo])
                      }}
                    >
                      {Users?.map((data) => {
                        return (
                          <Mentions.Option key={data._id} value={data.name}>
                            {data.name}
                          </Mentions.Option>
                        )
                      })}
                    </Mentions>
                  </Col>
                  <Col xs={24} md={2}>
                    <Button type='primary' loading={loading} onClick={addTodoItem}>
                      {t('add-todo')}
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
            <TodoComp allTodos={allTodos} loading={loading} />
          </div> */}
          <div className='filterDiv'>
            {userType === 'admin' && (
              <Select
                mode='multiple'
                allowClear
                style={{
                  width: 'max-content',
                  minWidth: '200px',
                  cursor: 'pointer',
                }}
                placeholder={t('select-user')}
                defaultValue={[]}
                onChange={handleUserChange}
              >
                {Users?.map((data) => (
                  <Option key={data?._id} value={data?._id}>
                    {' '}
                    {data?.name}{' '}
                  </Option>
                ))}
              </Select>
            )}
            <div className='rightFilter'>
              <Select
                allowClear
                style={{
                  width: '200px',
                  cursor: 'pointer',
                  marginRight: '1rem',
                }}
                placeholder='Group Filter'
                onChange={handleGroupChange}
                value={GroupFilter}
              >
                <Option value={'week'}>{t('week')}</Option>
                <Option value={'month'}>{t('month')}</Option>
              </Select>
              {/* <DatePicker
                style={{ width: "200px" }}
                placeholder="Select Date"
                onChange={onDateFilter}
                // value={DateChange} 
              /> */}
              <DatePicker.RangePicker
                allowClear
                onChange={onDateFilter}
                disabledDate={(d) => {
                  return d?.isAfter(moment())
                  // return moment(d).day() === 0 || d?.isAfter(moment());
                }}
              />
            </div>
          </div>

          <ProjectDetailTable detail={detail} userType={userType} getData={getData} />

          {userType === 'employee' && userId === projectDetails?.project?.teamLead[0] && (
            <div style={{ padding: '3rem 0' }}>
              <div className='filterDiv'>
                <Select
                  mode='multiple'
                  allowClear
                  style={{
                    width: 'max-content',
                    minWidth: '200px',
                    cursor: 'pointer',
                  }}
                  placeholder={t('select-user')}
                  defaultValue={[]}
                  onChange={handleUserChangeTL}
                >
                  {Users?.map((data) => (
                    <Option key={data?._id} value={data?._id}>
                      {' '}
                      {data?.name}{' '}
                    </Option>
                  ))}
                </Select>
              </div>

              <ProjectDetailTableTwo detail={detail} userType={userType} getData={getData} />
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

export default ProjectDetail
