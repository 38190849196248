import axios from 'axios'
import { notification } from 'antd'
import { publicAPI, attachToken, base_url } from '../../config/constants'
import swal from 'sweetalert'
import { MODERATOR_LOGIN } from '../types/generalTypes'
import { setSocket } from './socket'
import io from 'socket.io-client'

// const paths = [
//   { key: 'projects', path: '/admin/projects' },
//   { key: 'employees', path: '/admin/employees' },
//   { key: 'attendance', path: '/admin/attendance' },
//   { key: 'expenses', path: '/admin/expenses' },
//   { key: 'reports', path: '/admin/reports' },
//   { key: 'moderators', path: '/admin/moderators' },
//   { key: 'checkins', path: '/admin/checkins' },
// ]

export const authLogin = (payload, history) => {
  return async (dispatch) => {
    try {
      const res = await publicAPI.post(`/employee/login`, payload)
      if (res) {
        if (res.data.user.blocked) {
          swal('', 'You are Blocked by the Management', 'error')
        } else {
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('userName', res.data.user.name)
          localStorage.setItem('userId', res.data.user._id)
          localStorage.setItem('userType', res.data.userType)
          localStorage.setItem('company', res.data.user.company)
          const socket = io(base_url)
          socket.emit('join', res.data.user._id)
          dispatch(setSocket(socket))

          attachToken()
          notification.success({
            description: res.data.message,
            duration: 2,
          })
          attachToken()
          if (res.data.userType === 'employee') {
            history.replace('/employee/checkin')
          } else {
            if (res.data.userType === 'moderator') {
              history.replace('/moderator/checkin')
            } else {
              history.replace('/admin/dashboard')
            }
          }
          if (res.data.userType === 'moderator') {
            dispatch({
              type: MODERATOR_LOGIN,
              payload: res.data.user,
            })
            localStorage.setItem('moderator', JSON.stringify(res.data.user))
          }
        }
      }
    } catch (err) {
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const responseTime = () => {
  return async () => {
    try {
      const res = await axios.get('https://backend.trackkrr.com')
      if (res) {
        return true
      } else {
        return false
      }
    } catch (err) {
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const forgotPassword = async (payload) => {
  try {
    const res = await publicAPI.post(`/employee/forgot-password`, payload)
    if (res) {
      notification.success({
        description: res.data.message,
        duration: 2,
      })
      return true
    }
  } catch (error) {
    swal('', error?.response?.data?.message || 'Server Error', 'error')
  }
}

export const resetPassword = async (payload) => {
  try {
    const res = await publicAPI.post(`/employee/reset-password`, payload)
    if (res) {
      notification.success({
        description: res.data.message,
        duration: 2,
      })
      return true
    }
  } catch (error) {
    swal('', error?.response?.data?.message || 'Server Error', 'error')
  }
}
