import React, { useState } from 'react'
import { Modal, Form, Input, Button, Upload } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AiOutlineDelete, AiOutlineFileText } from 'react-icons/ai'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import { FiDownload } from 'react-icons/fi'

import { createDocument, getEmployeeDocs } from '../../redux'

const AddDocumentModal = ({ selectedEmployeeId, setActiveTab, setEmployeeDocs }) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [FileList, setFileList] = useState([])
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const showModal = () => {
    setIsModalVisible(true)
  }
  const onFinish = async (values) => {
    setLoading(true)
    let formData = new FormData()
    formData.append('title', values?.title || '')
    formData.append('employee', selectedEmployeeId)
    FileList?.map((file) => {
      formData.append('pictures', file?.originFileObj)
    })
    let result = await dispatch(createDocument(formData))
    if (result) {
      const res = await dispatch(getEmployeeDocs(selectedEmployeeId))
      if (res) {
        setEmployeeDocs(res)
      }
      form.resetFields()
      setLoading(false)
      setIsModalVisible(false)
      setActiveTab('documents')
    } else {
      setLoading(false)
    }
  }
  const onCancel = () => {
    setIsModalVisible(false)
  }

  const handleChange = ({ fileList }) => {
    let temArr = FileList
    if (fileList) {
      fileList?.map((file) => {
        file.status = 'success'
        temArr.push(file)
      })
      setFileList([...temArr])
    }
  }

  const deleteDocument = (index) => {
    const list = FileList
    list?.splice(index, 1)
    setFileList([...list])
  }

  return (
    <>
      <Button
        type='primary'
        className='addEmployeeBtn'
        onClick={showModal}
        style={{
          marginLeft: '10px',
        }}
      >
        {t('add-document')}
      </Button>
      <Modal title={t('add-document')} visible={isModalVisible} footer={null} onCancel={onCancel}>
        <Form form={form} onFinish={onFinish} layout='vertical'>
          <Form.Item
            label={t('title')}
            name='title'
            rules={[{ required: true, message: t('title-required') }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('documents')}
            rules={[{ required: true, message: t('document-required') }]}
          >
            {
              <div className='document-list'>
                {FileList?.map((file, ind) => (
                  <div key={ind} className='document-div'>
                    <AiOutlineDelete
                      //   className='icon'
                      className='download-icon'
                      onClick={() => deleteDocument(ind)}
                    />
                    {/* <FiDownload
                      className='download-icon'
                        onClick={() => {
                          window.open(`${''}`);
                        }}
                    /> */}
                    <AiOutlineFileText className='file-icon' />
                    <p>{file?.name || ''}</p>
                  </div>
                ))}
                {FileList?.length < 10 && (
                  <Upload
                    listType='picture-card'
                    customRequest={() => {}}
                    onChange={(e) => handleChange(e)}
                    fileList={[]}
                    multiple
                    //   accept='.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  >
                    <div className='Upload-div'>
                      <BsFillArrowUpCircleFill fontSize={24} className='icon' />
                      <h4>Upload Document here</h4>
                    </div>
                  </Upload>
                )}
              </div>
            }
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loading}
              style={{ marginRight: '10px' }}
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddDocumentModal
