import { useState } from 'react'
import { Modal, Button, Form, Input, Row, Col } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { updateTicket } from '../../redux'

const TicketRemarksModal = ({ open, setOpen, getEmployeesTickets }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const res = dispatch(
        updateTicket({ ticketId: open, status: 'Closed', remarks: values.remarks }),
      )
      if (res) {
        setLoading(false)
        form.resetFields()
        setOpen(null)
        getEmployeesTickets()
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(null)
  }

  return (
    <>
      <Modal
        title={t('remarks')}
        visible={open !== null ? true : false}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form layout='vertical' onFinish={onFinish} form={form}>
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item name='remarks'>
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default TicketRemarksModal
