import * as types from '../types/generalTypes'
import swal from 'sweetalert'
import privateAPI, { attachToken } from '../../config/constants'

export const getProjectsByEmployee = (id) => async (dispatch) => {
  try {
    attachToken()
    const res = await privateAPI.get(`/report/get-employee-projects/${id}`)
    if (res) {
      dispatch({
        type: types.GET_ALL_PROJECTS_BY_EMPLOYEE,
        payload: res?.data,
      })
    }
  } catch (err) {
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getEmployeesByProject = (id) => async (dispatch) => {
  try {
    attachToken()
    const res = await privateAPI.get(`/report/get-project-employees/${id}`)
    if (res) {
      dispatch({
        type: types.GET_ALL_EMPLOYEES_BY_PROJECT,
        payload: res?.data,
      })
    }
  } catch (err) {
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}
