import { useState } from 'react'
import { Button, Input, Modal, Form } from 'antd'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'

import { createNotice } from '../../redux'

const CreateNoticeModal = ({ callApi }) => {
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onFinish = async (values) => {
    setLoading(true)
    let payload = {
      ...values,
    }
    try {
      const result = await dispatch(createNotice(payload))
      if (result) {
        setLoading(false)
        callApi()
        setIsModalVisible(false)
      }
    } catch (error) {
      setLoading(false)
      // console.log('error', error)
    }
  }

  return (
    <div>
      <Button
        type='primary'
        size='middle'
        onClick={() => {
          setIsModalVisible(true)
        }}
      >
        Create Notice
      </Button>
      <Modal
        title={t('create-notice')}
        open={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <Form.Item label={t('notice')} name={'notice'}>
            <Input.TextArea rows={4} style={{ resize: 'none' }} />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {t('create-notice')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default CreateNoticeModal
