import { useState, useEffect } from 'react'
import { Typography, Row, Col, Select, Form, Button, Image, Tooltip, Divider, Table } from 'antd'
import { AiOutlineFieldTime, AiOutlineLoading, AiOutlineLogin } from 'react-icons/ai'
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import InfinityLoader from '../components/InfinityLoader'
import Layout from '../layout/Layout'
import { useTranslation } from 'react-i18next'
import { getCheckins, getEmployeesName, getUserDailyDetails } from '../redux'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
let intRun = false
const intRunChange = (val) => {
  intRun = val
  if (val == false) {
    // clearinterval
  }
}
const Todos = () => {
  const { t } = useTranslation()
  const employees = useSelector((state) => state.employees.employeesName)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  // const [loading2, setLoading2] = useState(false)
  const [details, setDetails] = useState(null)
  const [resources, setResources] = useState(null)
  // const previewsMonthStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  // const previewsMonthEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')

  useEffect(() => {
    dispatch(getEmployeesName())
    // fetchCheckins()
  }, [])

  const onFinish = async (values) => {
    setLoading(true)
    // const allSnaps = await getUserScreenshots(
    //   values.date?.length === 2
    //     ? {
    //         user: values.user,
    //         startDate: moment(values.date[0]?._d).format("YYYY-MM-DD"),
    //         endDate: moment(values.date[1]?._d).format("YYYY-MM-DD"),
    //       }
    //     : {
    //         user: values.user,
    //       }
    // );
    // console.log(allSnaps);

    let data = {}
    const conditions = {
      today: { start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
      yesterday: {
        start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      },
      last7Days: {
        start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      last30Days: {
        start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      thisMonth: {
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      lastMonth: {
        start: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      },
    }
    data = { ...conditions[values.date], user: values.user, userType: 'admin' }
    // const allDetails = await getUserDailyDetails(
    const details = await dispatch(getCheckins(data))
    setDetails(details)
    // setSnaps(allSnaps?.result);
    setLoading(false)
  }

  useEffect(() => {
    let arr = employees?.employee?.concat(employees?.moderator)
    arr = arr?.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      return 0
    })
    setResources(arr)
  }, [employees])

  // Third Table

  const fourthcolumns = [
    {
      title: 'Project Checkin',
      dataIndex: 'projectCheckin',
      key: 'projectCheckin',
      // render: (_, snap, index) => <p>Screenshot for checkin {index + 1}</p>,
    },
  ]

  // Second Table
  const secondcolumns = [
    {
      title: 'Project',
      dataIndex: 'projectName',
      key: 'projectName',
      // render: (_, item) => <p>{item?.project?.title}</p>,
    },
    {
      title: 'Number of Checkin',
      dataIndex: 'numberOfCheckin',
      key: 'numberOfCheckin',
    },
  ]
  // const SecondTable = (record) => {
  //   return (
  //     <>
  //       <Table
  //         columns={columns}
  //         expandable={{
  //           expandRowByClick: true,
  //           expandedRowRender: (_record) => thirdTable(record?.snaps, _record),
  //         }}
  //         dataSource={record?.projectCheckIns}
  //         pagination={false}
  //       />
  //     </>
  //   )
  // }

  // First Table
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => <p>{moment(record?.checkIn?.start).format('YYYY-MM-DD')}</p>,
    },
    {
      title: 'Number of Project',
      dataIndex: 'numberOfProject',
      key: 'numberOfProject',
      render: (_, record) => <p>{record?.projectCheckIns?.length}</p>,
    },
    {
      title: 'Shift Start',
      dataIndex: 'shiftStart',
      key: 'shiftStart',
      render: (_, record) => <p>{moment(record?.checkIn?.start).format('hh:mm a')}</p>,
    },
    {
      title: 'Shift End',
      dataIndex: 'shiftEnd',
      key: 'shiftEnd',
      render: (_, record) => (
        <p>{record?.checkIn?.end ? moment(record?.checkIn?.end).format('hh:mm a') : 'Ongoing'}</p>
      ),
    },
  ]

  return (
    <Layout active={'checkins'}>
      <div className='todo-main'>
        <Typography.Title level={4}>{t('checkin-screenshots')}</Typography.Title>

        <Form layout='vertical' onFinish={onFinish}>
          <Row gutter={[30, 30]} align='middle'>
            <Col xs={24} md={8} lg={6}>
              <Form.Item label={t('select-resource')} name='user'>
                <Select style={{ width: '100%' }} placeholder={t('select-resource')}>
                  {resources?.map((employee, ind) => (
                    <Select.Option key={ind} value={employee._id}>
                      {employee.name} {employee.adminType ? '(moderator)' : ''}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col xs={24} md={8} lg={6}>
              <Form.Item label={t('get-screenshots-by-range')} name='date'>
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
              </Form.Item>
            </Col> */}
            <Col xs={24} md={9} lg={7}>
              <Form.Item label={t('get-screenshots-by-range')} name='date'>
                <Select
                  // onChange={(e) => handleChnage(e)}
                  defaultValue='today'
                  style={{
                    width: '100%',
                  }}
                  options={[
                    {
                      value: 'today',
                      label: 'Today',
                    },
                    {
                      value: 'yesterday',
                      label: 'Yesterday',
                    },
                    {
                      value: 'last7Days',
                      label: 'Last 7 Days',
                    },
                    {
                      value: 'last30Days',
                      label: 'Last 30 Days',
                    },
                    {
                      value: 'thisMonth',
                      label: 'This Month',
                    },
                    {
                      value: 'lastMonth',
                      label: 'Last Month',
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={7} lg={5}>
              <Form.Item style={{ margin: '0px' }}>
                <Button type='primary' loading={loading} htmlType='submit'>
                  {t('search')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* {details === null ? null : details?.dates?.length > 0 ? (
          details &&
          details?.dates?.map(
            (date) =>
              !loading && (
                <SnapComponent
                  // snap={snap}
                  // count={snaps?.length}
                  date={date}
                  checkins={details?.checkins}
                />
              )
          )
        ) : (
          <div className="empty">
            <Empty description="No Screenshots Found" />
          </div>
        )} */}
        {/* {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '70vh',
            }}
          >
            <InfinityLoader />
          </div>
        ) : details === null ? null : details?.dates?.length > 0 ? (
          details &&
          details?.dates?.map(
            (date, ind) =>
              !loading && (
                <SnapComponent
                  key={ind}
                  // snap={snap}
                  // count={snaps?.length}
                  date={date}
                  checkins={details?.checkins}
                />
              ),
          )
        ) : (
          <div className='empty'>
            <Empty description={t('no-checkins-found')} />
          </div>
        )} */}
      </div>

      <Table
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => {
            return (
              <>
                <Table
                  columns={secondcolumns}
                  expandable={{
                    expandRowByClick: true,
                    expandedRowRender: (_record) => {
                      let temp = record.projectCheckIns?.find(
                        (item) => item.project._id === _record.key,
                      )
                      return (
                        <>
                          <Table
                            // showHeader={false}
                            columns={fourthcolumns}
                            expandable={{
                              expandRowByClick: true,
                              expandedRowRender: (record3) => {
                                let Psnaps = record?.snaps?.filter(
                                  (value) => value?.projectCheckIn === record3?.key,
                                )
                                return (
                                  <>
                                    <Row gutter={[30, 30]}>
                                      {Psnaps?.map((data, index) => {
                                        return (
                                          <Col xs={24} md={8} lg={4} key={index}>
                                            <Tooltip
                                              title={
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                  }}
                                                >
                                                  <span>
                                                    {t('date')}:{' '}
                                                    {moment(data.createdAt)
                                                      .local()
                                                      .format('DD-MM-YYYY')}
                                                  </span>
                                                  <span>
                                                    {t('time')}:{' '}
                                                    {moment(data.createdAt)
                                                      .local()
                                                      .format('hh:mm:ss a')}
                                                  </span>
                                                </div>
                                              }
                                            >
                                              <Image
                                                loading='lazy'
                                                src={
                                                  data?.imageCover ? data?.imageCover : data?.image
                                                }
                                                preview={{
                                                  src: data?.image,
                                                }}
                                              />
                                            </Tooltip>
                                          </Col>
                                        )
                                      })}
                                    </Row>
                                  </>
                                )
                              },
                            }}
                            dataSource={temp?.checkins?.map((item) => ({
                              key: item?._id,
                              projectCheckin: `${moment(item.start).format('hh:mm a')} - ${
                                item?.end ? moment(item.end).format('hh:mm a') : 'Ongoing'
                              }`,
                            }))}
                            pagination={false}
                          />
                        </>
                      )
                    },
                  }}
                  dataSource={record?.projectCheckIns?.map((item) => ({
                    key: item?.project?._id,
                    projectName: item?.project?.title,
                    numberOfCheckin: item?.checkins.length,
                  }))}
                  pagination={false}
                />
                {!!record?.general?.length && (
                  <p
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    General
                  </p>
                )}
                <Row gutter={[30, 30]}>
                  {record?.general?.map((data, index) => {
                    return (
                      <Col xs={24} md={8} lg={4} key={index}>
                        <Tooltip
                          title={
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span>
                                {t('date')}: {moment(data.createdAt).local().format('DD-MM-YYYY')}
                              </span>
                              <span>
                                {t('time')}: {moment(data.createdAt).local().format('hh:mm:ss a')}
                              </span>
                            </div>
                          }
                        >
                          <Image
                            loading='lazy'
                            src={data?.imageCover ? data?.imageCover : data?.image}
                            preview={{
                              src: data?.image,
                            }}
                          />
                        </Tooltip>
                      </Col>
                    )
                  })}
                </Row>
              </>
            )
          },
        }}
        dataSource={details?.map((value) => ({ ...value, key: value.checkIn?._id })) || []}
        pagination={false}
      />
    </Layout>
  )
}

const SnapComponent = ({ date, checkins }) => {
  const [show, setShow] = useState(false)
  const [data, setData] = useState(null)
  const [totalHours, setTotalHours] = useState(0)
  const { t } = useTranslation()

  let int
  useEffect(() => {
    return () => {
      if (intRun) {
        clearInterval(int)
        intRunChange(false)
      }
    }
  }, [])

  const btnShow = (date) => {
    let details = []
    let time = 0
    // let flag = false
    let flag = {
      flag: false,
      date: '',
    }

    checkins?.map((checkin) => {
      if (
        // moment(checkin.createdAt).format('YYYY-MM-DD') === date ||
        moment(checkin.date).format('YYYY-MM-DD') === date
      ) {
        details.push(checkin)
        // setTotalHours(totalHours + checkin.hours);
        if (checkin.time.end !== 'Ongoing') {
          time = time + checkin.hours
        } else {
          let ongoingTime = 0
          ongoingTime = new Date() - new Date(checkin.time.start)
          ongoingTime = ongoingTime / 1000 / 60 / 60
          time = time + ongoingTime
        }
      }
    })
    details = details.sort((a, b) => {
      return new Date(a.time.start) - new Date(b.time.start)
    })
    checkins?.find((checkin) => {
      if (checkin.time.end === 'Ongoing') {
        flag.flag = true
        flag.date = moment(checkin.date).format('YYYY-MM-DD')
      }
    })
    // console.log(flag)
    if (flag.flag && flag.date === date) {
      // console.log('flag true')
      if (intRun) {
        // console.log(intRun, 'int running already')

        setData(details)
        setShow(!show)
        return
      } else {
        // console.log(intRun, 'int not running')

        let interval = 1000
        time = time * 60 * 60 * 1000
        int = setInterval(() => {
          time = time + interval
          // console.log(time)
          const seconds = Math.floor(time / 1000) // convert milliseconds to seconds
          const minutes = Math.floor(seconds / 60) // convert seconds to minutes
          const hours = Math.floor(minutes / 60) // convert minutes to hours
          const remainingMinutes = minutes % 60 // calculate remaining minutes
          const remainingSeconds = seconds % 60 // calculate remaining seconds
          setTotalHours(
            `${hours.toString().padStart(2, '0')}:${remainingMinutes
              .toString()
              .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`,
          )
          // if (!localStorage.getItem('checkinId')) {
          //   console.log('clear')
          //   clearInterval(int)
          //   intRunChange(false)

          //   setTotalHours(
          //     new Date(time)
          //       .toISOString()
          //       .substr(11, 11 + 8)
          //       .split('.')[0],
          //   )
          // }
        }, interval)
        if (int) {
          intRunChange(true)
        }
      }
    } else {
      time = time * 60 * 60 * 1000
      const seconds = Math.floor(time / 1000) // convert milliseconds to seconds
      const minutes = Math.floor(seconds / 60) // convert seconds to minutes
      const hours = Math.floor(minutes / 60) // convert minutes to hours
      const remainingMinutes = minutes % 60 // calculate remaining minutes
      const remainingSeconds = seconds % 60 // calculate remaining seconds
      time = `${hours.toString().padStart(2, '0')}:${remainingMinutes
        .toString()
        .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`

      setTotalHours(time)
    }

    setData(details)
    setShow(!show)
  }

  return (
    <>
      <div className='screenshot-section'>
        <div
          style={{
            width: '100%',
            border: '1px solid var(--borderLight',
            padding: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              width: '100%',
              marginBottom: '10px',
            }}
            onClick={() => btnShow(date)}
          >
            <span
              style={{
                color: 'var(--text)',
                fontSize: '1.5rem',
              }}
            >
              {moment(date).local().format('dddd, DD-MMM-YYYY')}
            </span>
            {show ? (
              <IoIosArrowDropup
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            ) : (
              <IoIosArrowDropdown
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            )}
          </div>
          {show && (
            <>
              <Row
                gutter={[30, 15]}
                style={{
                  border: '1px solid var(--borderLight)',
                  borderRadius: '5px',
                  padding: '1rem',
                  margin: '1rem',
                  boxShadow: 'var(--shadow)',
                }}
              >
                <Col span={24}>
                  <h2
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      color: 'var(--text)',
                      margin: '0',
                    }}
                  >
                    {t('checkin-details')}
                  </h2>
                  <Divider
                    style={{
                      margin: '0',
                      borderColor: 'var(--text)',
                    }}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Row>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                      }}
                    >
                      <AiOutlineLogin
                        style={{
                          fontSize: '2rem',
                          color: 'var(--text)',
                        }}
                      />
                      <span
                        style={{
                          fontSize: '1rem',
                          color: 'var(--text)',
                          marginRight: '0.3rem',
                        }}
                      >
                        {t('no-of-checkins')} : {data?.length || 0}
                      </span>
                    </Col>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                      }}
                    >
                      <AiOutlineFieldTime
                        style={{
                          fontSize: '2rem',
                          color: 'var(--text)',
                        }}
                      />
                      <span
                        style={{
                          fontSize: '1rem',
                          color: 'var(--text)',
                          marginRight: '0.3rem',
                        }}
                      >
                        {t('time')} :
                      </span>
                      <span
                        style={{
                          fontSize: '1rem',
                          color: 'var(--text)',
                        }}
                      >
                        {totalHours}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} justify='space-around'>
                    {data?.map((checkin, index) => {
                      return (
                        <Col xs={24} md={10} lg={6} key={index}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              padding: '0.5rem',
                              border: '1px solid var(--borderLight)',
                              borderRadius: '5px',
                              boxShadow: 'var(--shadow)',
                              minWidth: '170px',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: 'var(--text)',
                              }}
                            >
                              {index + 1}
                            </span>
                            <span
                              style={{
                                fontSize: '0.8rem',
                                color: 'var(--text)',
                              }}
                            >
                              {t('start')} :{' '}
                              {moment(checkin.time.start).tz('Asia/Karachi').format('hh:mm a')}{' '}
                              (local)
                            </span>
                            <span
                              style={{
                                fontSize: '0.8rem',
                                color: 'var(--text)',
                              }}
                            >
                              {t('end')} :{' '}
                              {checkin.time.end == 'Ongoing'
                                ? checkin.time.end
                                : moment(checkin.time.end)
                                    .tz('Asia/Karachi')
                                    .format('hh:mm a')}{' '}
                              (local)
                            </span>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </Col>

                <Col span={24}>
                  {data?.map((checkin, index) => {
                    return (
                      <Row gutter={[30, 30]} key={index}>
                        <Col
                          span={24}
                          style={{
                            marginTop: '1rem',
                          }}
                        >
                          <h2
                            style={{
                              fontWeight: 'bold',
                              margin: '0',
                            }}
                          >
                            {t('screenshots-for-checkin')} {index + 1}
                          </h2>
                          <Divider
                            style={{
                              margin: '0',
                              borderColor: 'var(--text)',
                            }}
                          />
                        </Col>
                        {checkin.snaps.map((data, index) => {
                          return (
                            <Col xs={24} md={8} lg={4} key={index}>
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <span>
                                      {t('date')}:{' '}
                                      {moment(data.createdAt).local().format('DD-MM-YYYY')}
                                    </span>
                                    <span>
                                      {t('time')}:{' '}
                                      {moment(data.createdAt).local().format('hh:mm:ss a')}
                                    </span>
                                  </div>
                                }
                              >
                                <Image
                                  loading='lazy'
                                  src={data?.imageCover ? data?.imageCover : data?.image}
                                  preview={{
                                    src: data?.image,
                                  }}
                                />
                              </Tooltip>
                            </Col>
                          )
                        })}
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Todos
