import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { trackrr } from './trackkrrLogoBase64'
import { hoursToString } from '../../functions/timeFormatter'

const EmployeeProjectReportPdf = (projectData, month, totalHours, pdfname, projectDetails) => {
  // calculations
  //   total salary of user for this project and date range
  let amount = projectData?.reduce(
    (prev, current) => (prev += Number(current?.user?.hourlySalary * current?.hours)),
    0,
  )

  var doc = new jsPDF()

  var footerY = doc.internal.pageSize.getHeight() - 8
  let textX = (doc.internal.pageSize.getWidth() - doc.getTextWidth(`Detailed Report`)) / 2

  doc.addImage(trackrr, 'PNG', 120, 15, 80, 10)

  //   doc.setTextColor('#F55139')
  doc.setFontSize(16)

  doc.text(`Detailed Report`, 10, 20)

  doc.setFontSize(11)

  doc.setTextColor('#535c68')
  // DURATION ROW 1
  doc.text(
    10,
    28,
    `${moment(new Date(month[0]))?.format('DD/MM/yyyy')} - ${moment(new Date(month[1]))?.format(
      'DD/MM/yyyy',
    )}`,
  )

  doc.setTextColor('#000000')

  // COMPANY ROW 2
  // let width = doc.getTextWidth(`${localStorage.getItem('company')} `) + 10 + 20
  // doc.text(10, 35, `${localStorage.getItem('company')} `)

  // TOTAL HOURS ROW 3

  doc.setTextColor('#535c68')
  doc.text(10, 35, 'Total : ')

  doc.setTextColor('#000000')
  doc.setFontSize(13)
  doc.text(25, 35, `${hoursToString(totalHours)}`)

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(55, 35, 'Billed : ')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(70, 35, `${hoursToString(totalHours)}`)

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(100, 35, 'Amount: ')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(120, 35, `${Number(amount || 0)?.toFixed(2)} PKR`)
  doc.setFontSize(11)

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(10, 45, 'Project:')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(40, 45, `${projectData[0]?.project?.title || projectDetails?.title}`)

  doc.setFontSize(11)
  // var tHeaders = ['Date', 'Description', 'Duration', 'User']
  // var tData = []

  doc.setDrawColor(128)
  doc.setTextColor('#535c68')
  doc.text(10, 65, 'Date')
  doc.text(40, 65, 'Description')
  doc.text(120, 65, 'Duration')
  doc.text(160, 65, 'User')
  doc.line(10, 70, 200, 70) // horizontal line

  doc.setFontSize(11)
  let height = 78
  projectData.map((data) => {
    checkPageBreak(height)
    var maxWidth = 70
    doc.setTextColor('#000000')
    let remarks = `${data?.remarks || ''}`?.replace(/\n/g, ' ')
    const lines = doc.splitTextToSize(remarks, maxWidth)
    const totalHeight = lines.length * 11

    let heightToAdd = lines.length > 1 ? totalHeight - 10 : 6

    doc.text(10, height, moment(data?.CreatedAt).local().format('DD-MM-YYYY'))
    doc.text(40, height, lines) // .replace(/\n/g, ' ')
    doc.text(120, height, hoursToString(data?.hours))
    doc.text(160, height, `${data?.user?.name}`)

    doc.setTextColor('#535c68')
    doc.text(40, height + heightToAdd, `${data?.project?.title || projectDetails?.title}`)
    // doc.text(120, height + 6, '00:12:00')
    doc.text(
      160,
      height + heightToAdd,
      `${Number(data?.user?.hourlySalary * data?.hours).toFixed(2)} PKR`,
    )
    doc.line(10, height + heightToAdd + 3, 200, height + heightToAdd + 3) // horizontal line
    height += heightToAdd + 3 + 8
  })

  function checkPageBreak(yPosition) {
    const pageHeight = doc.internal.pageSize.height
    if (yPosition > pageHeight - 20) {
      doc.addPage() // Add a new page
      height = 20 // Reset the Y-coordinate to start from the top
    }
  }

  doc.text(120, footerY, `${localStorage.getItem('company')} - Created with TRACKKRR`)

  return doc.save(`${pdfname}`)
}

export default EmployeeProjectReportPdf
