import { notification } from 'antd'
import privateAPI, { attachToken } from '../../config/constants'
import * as types from '../types/generalTypes'

export const getAccessories = () => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/accessories/get`)
      if (res) {
        dispatch({
          type: types.GET_ALL_ACCESSORIES,
          payload: res.data,
        })
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }
}

export const createAccessories = (payload, handleCancel) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/accessories/create`, payload)
      if (res) {
        notification.success({
          message: res.data.message,
          duration: 3,
        })
        dispatch(getAccessories())
        handleCancel()
      }
      return res
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }
}
export const updateAccessories = (payload, handleCancel) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/accessories/update`, payload)
      if (res) {
        dispatch(getAccessories())
        notification.success({
          message: res.data.message,
          duration: 3,
        })
        handleCancel()
      }
      return res
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }
}

export const deleteAccessories = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/accessories/delete`, payload)
      if (res) {
        dispatch(getAccessories())
        notification.success({
          message: res.data.message,
          duration: 3,
        })
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Server Error',
        duration: 3,
      })
    }
  }
}
