export const RoleCheck = (path) => {
  const pathSplit = path.split('/')
  const LocalRoles = JSON.parse(localStorage.getItem('moderator'))
  const roles = LocalRoles?.roles?.roles
  const finalPath = pathSplit[pathSplit?.length - 1]
  const roleAssigned = roles?.indexOf(finalPath)

  if (finalPath === 'checkin') {
    return true
  }
  if (roleAssigned !== -1) {
    return true
  } else {
    return true // role assigned not found temp solution
  }
}
