import { SET_PROJECT_CHECKIN_ID, SET_SHIFT_CHECKINS, SET_START_TIME } from '../types/generalTypes'

const initialState = {
  checkins: [],
  projectCheckInId: '',
  currentCheckInStartTime: null,
}

const shiftCheckin = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_SHIFT_CHECKINS:
      return {
        ...state,
        checkins: payload,
      }
    case SET_START_TIME:
      return {
        ...state,
        checkins: payload,
      }
    case SET_PROJECT_CHECKIN_ID:
      return {
        ...state,
        checkins: payload,
      }

    default:
      return state
  }
}

export default shiftCheckin
