import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TbReportAnalytics } from 'react-icons/tb'
import { Table } from 'antd'

import { getAllEmployees } from '../../../../redux'
import InfinityLoader from '../../../../components/InfinityLoader'
import HistoryView from './historyView'

const EmployeeHistory = ({ activeTab, setSelectedEmployeeId, selectedEmployeeId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [employees, setEmployees] = useState(null)
  const [tableData, setTableData] = useState([])
  const [activeScreen, setActiveScreen] = useState('table')

  const getAllEmployeesData = async () => {
    try {
      const result = await dispatch(getAllEmployees())
      if (result) {
        setEmployees(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    if (activeTab === 'history') {
      getAllEmployeesData()
      setActiveScreen('table')
    } else {
      setSelectedEmployeeId(null)
    }
  }, [activeTab])

  const onHistoryClick = (id) => {
    setActiveScreen('history')
    setSelectedEmployeeId(id)
  }

  useEffect(() => {
    let data = []
    employees?.map((employee) => {
      data.push({
        name: employee?.name,
        email: employee?.email,
        phone: employee?.phone,
        history: (
          <div className='action-buttons'>
            <TbReportAnalytics className='editBtn' onClick={() => onHistoryClick(employee?._id)} />
          </div>
        ),
      })
    })
    setTableData(data)
  }, [employees])

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('email'),
      dataIndex: 'email',
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
    },
    {
      title: t('history'),
      dataIndex: 'history',
    },
  ]

  return (
    <div>
      {activeScreen === 'table' ? (
        employees ? (
          <Table dataSource={tableData} className='project-table' columns={columns} />
        ) : (
          <div style={{ height: '60vh' }}>
            <InfinityLoader />
          </div>
        )
      ) : (
        <>
          {selectedEmployeeId && (
            <>
              {selectedEmployeeId && (
                <HistoryView
                  selectedEmployeeId={selectedEmployeeId}
                  setActiveScreen={setActiveScreen}
                  setSelectedEmployeeId={setSelectedEmployeeId}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default EmployeeHistory
