// import { Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import { Button, Col, Row, Tabs, Typography } from 'antd'
import { getSubscriptionDetails } from '../redux/actions/settings'
// import { useDispatch } from 'react-redux'
import InfinityLoader from '../components/InfinityLoader'
import moment from 'moment'
const Settings = () => {
  const items = [
    {
      title: 'Subscription',
      content: <Subscription />,
    },
    {
      title: 'Profile',
      content: <Profile />,
    },
    {
      title: 'Security',
      content: 'Security',
    },
    {
      title: 'Notifications',
      content: 'Notifications',
    },
    {
      title: 'Billing',
      content: 'Billing',
    },
  ]
  return (
    <Layout active={'settings'}>
      <div className='settings-cont'>
        <Tabs defaultActiveKey='0'>
          {items.map((item, index) => (
            <Tabs.TabPane tab={item.title} key={index}>
              {item.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </Layout>
  )
}

const Subscription = () => {
  // const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const getData = async () => {
    setLoading(true)
    const res = await getSubscriptionDetails()
    setData(res)
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div className='subscription-cont'>
      <Typography.Title level={3}>
        Current Plan : <strong>{data?.admin?.subscription?.subType}</strong>
      </Typography.Title>
      <div className='subs-plan-top'>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Typography.Title level={4}>Assets</Typography.Title>
          </Col>
          <Col span={6}>
            <Typography.Title level={4}>Maximum Allowed</Typography.Title>
          </Col>
          <Col span={6}>
            <Typography.Title level={4}>Current Usage</Typography.Title>
          </Col>
        </Row>
      </div>

      {loading ? (
        <InfinityLoader />
      ) : (
        <>
          <div className='subs-plan-rows'>
            <Row gutter={[16, 16]} className='rows'>
              <Col span={12}>
                <Typography.Text className='text'>Number of Employees</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text className='text'>{data?.admin?.noOfEmployees}</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text className='text'>{data?.usage?.noOfEmployees}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='rows'>
              <Col span={12}>
                <Typography.Text className='text'>Number of Projects</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text className='text'>{data?.admin?.noOfProjects}</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text className='text'>{data?.usage?.noOfProjects}</Typography.Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='rows'>
              <Col span={12}>
                <Typography.Text className='text'>Number of Moderators</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text className='text'>{data?.admin?.noOfModerators}</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text className='text'>{data?.usage?.noOfModerators}</Typography.Text>
              </Col>
            </Row>
          </div>

          <div className='subs-plan-bottom'>
            <Row gutter={[16, 16]} justify='space-around' align='center'>
              <Col span={7}>
                <Typography.Title level={5} className='text textCenter'>
                  Subscription start date:{'  '}
                  {moment(data?.admin?.subscription?.startDate).format('DD-MM-YYYY')}
                </Typography.Title>
              </Col>
              <Col span={7}>
                <Typography.Title level={5} className='text textCenter'>
                  Subscription end date:{'  '}
                  {moment(data?.admin?.subscription?.expiryDate).format('DD-MM-YYYY')}
                </Typography.Title>
              </Col>
              {data?.admin?.subscription?.subType === 'trial' && (
                <Col span={7}>
                  <Button type='primary'>Upgrade Plan</Button>
                </Col>
              )}
            </Row>
          </div>
        </>
      )}
    </div>
  )
}

const Profile = () => {
  return (
    <div>
      <h1>Profile</h1>
    </div>
  )
}

export default Settings
