import { Button, Col, Form, Input, Modal, Row, Spin, notification } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaRegClock } from 'react-icons/fa'
import { TbListDetails } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import swal from 'sweetalert'
import InfinityLoader from '../components/InfinityLoader'
import AddRemarks from '../components/modal/AddRemarks'
import AddRemarksCsv from '../components/modal/AddRemarksCsv'
import Layout from '../layout/Layout'
import {
  _projectCheckIn,
  addOfflineScreenshot,
  addScreenshot,
  getAllEmployeeProjects,
  getProjectCheckIns,
  projectCheckOut,
} from '../redux'
import dataURItoBlob from '../service/dataURItoBlob'
let colors = ['orange', 'red', 'cyan', 'green', 'purple', 'blue', 'primary', 'yellow']

const UserProject = ({
  setIntervalState,
  setVideoEl,
  setTrackState,
  videoEL,
  intervalState,
  TrackState,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { projects } = useSelector((state) => state.project)
  let type = localStorage.getItem('userType')

  const [loading, setLoading] = useState(false)
  const { socket } = useSelector((state) => state.socket)
  const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  const [currentCheckIn, setCurrentCheckIn] = useState(null)

  const [form] = Form.useForm()
  const [remarksModal, setRemarksModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    if (socket) {
      socket.on('checkinStatus', (data) => {
        removeCheckInDetails()
        stopStreamedVideo()
        handleStopCapturing()
      })
    }
  }, [socket])

  useEffect(() => {
    window?.electronAPI?.onCapturedImage((image) => {
      console.log('single:image', image)
      captureImage({ image })
    })

    // Clean up the listener when the component unmounts
    return () => {
      if (window.electronAPI?.removeOnCapturedImageListener)
        window?.electronAPI?.removeOnCapturedImageListener()
    }
  }, [])

  // const onDateChange = (date, dateString) => {
  //   setDate({
  //     start: dateString[0],
  //     end: dateString[1],
  //   })
  // }

  // const handleChnage = (e) => {
  //   e === 'today'
  //     ? setDate({
  //         start: moment().format('YYYY-MM-DD'),
  //         end: moment().format('YYYY-MM-DD'),
  //       })
  //     : e === 'yesterday'
  //     ? setDate({
  //         start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  //         end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  //       })
  //     : e === 'last7Days'
  //     ? setDate({
  //         start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
  //         end: moment().format('YYYY-MM-DD'),
  //       })
  //     : e === 'last30Days'
  //     ? setDate({
  //         start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
  //         end: moment().format('YYYY-MM-DD'),
  //       })
  //     : e === 'thisMonth'
  //     ? setDate({
  //         start: moment().startOf('month').format('YYYY-MM-DD'),
  //         end: moment().format('YYYY-MM-DD'),
  //       })
  //     : setDate({
  //         start: moment().format('YYYY-MM-DD'),
  //         end: moment().format('YYYY-MM-DD'),
  //       })
  // }

  // const fetchCheckins = async () => {
  //   setLoading2(true)
  //   let allDetails = null
  //   if (date.start && date.end) {
  //     allDetails = await getUserDailyDetails({
  //       user: userId,
  //       startDate: moment(date.start).format('YYYY-MM-DD'),
  //       endDate: moment(date.end).format('YYYY-MM-DD'),
  //     })
  //     setData(allDetails)
  //   } else {
  //     allDetails = await getUserDailyDetails({ user: userId })
  //     setData(allDetails)
  //   }
  //   // console.log('allDetails fetch checkins', allDetails)
  //   setLoading2(false)
  //   intRunChange(false)
  // }

  function stopStreamedVideo() {
    if (videoEL) {
      const streamVar = videoEL.srcObject
      if (streamVar) {
        const tracks = streamVar.getTracks()

        tracks.forEach((track) => {
          track.stop()
        })
      }
      clearInterval(intervalState)
    }
  }

  const removeCheckInDetails = () => {
    localStorage.removeItem('project')
    localStorage.removeItem('projectCheckInId')
    setProjectCheckInId('')
    setCurrentCheckIn(null)
    setProjectId('')
  }
  const displayMediaOptions = {
    video: {
      cursor: 'always',
    },
    audio: false,
  }

  const sendOfflineScreenshots = () => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    if (offlineScreenshots.length > 0) {
      // .format("YYYY-MM-DD--HH-MM")

      let formData = new FormData()
      offlineScreenshots?.map((data) => {
        const blob = dataURItoBlob(data.file)

        var file = new File([blob], `${data.date}.jpg`, {
          type: 'application/octet-stream',
        })
        formData.append('screenshot', file)
      })
      formData.append('checkinId', localStorage.getItem('checkinId'))
      addOfflineScreenshot(formData)
    }
  }

  const setCapturetoLocal = (payload) => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    // console.log("offlineScreenshots", offlineScreenshots);

    localStorage.setItem('offlineScreenshots', JSON.stringify([...offlineScreenshots, payload]))
  }

  const captureImage = async ({ videoELReference, width, height, image = null }) => {
    if (!image) {
      let c = document.createElement('canvas'), // create a temp. canvas
        ctx = c.getContext('2d')
      c.width = width // set size = image, draw
      c.height = height
      ctx.drawImage(videoELReference, 0, 0)

      var imgAsDataURL = c.toDataURL('application/octet-stream')
      const blob = dataURItoBlob(imgAsDataURL)

      let payload = {
        file: imgAsDataURL,
        date: Date.now(),
      }
      fetch('https://backend.trackkrr.com', {
        method: 'GET',
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          var file = new File(
            [blob],
            `${Date.now()}.jpg`,

            {
              type: 'application/octet-stream',
            },
          )
          sendOfflineScreenshots()

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        })
        .catch(() => {
          setCapturetoLocal(payload)
        })
    } else {
      const blob = dataURItoBlob(image)
      console.log('image', image)
      let payload = {
        file: image,
        date: Date.now(),
      }

      console.log('payload.file', payload?.file?.slice(0, 10))
      fetch('https://backend.trackkrr.com', {
        method: 'GET',
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          var file = new File(
            [blob],
            `${Date.now()}.jpg`,

            {
              type: 'application/octet-stream',
            },
          )
          sendOfflineScreenshots()

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        })
        .catch(() => {
          setCapturetoLocal(payload)
        })
    }
  }

  const handleStopCapturing = () => {
    if (window?.electronAPI?.stopCapturing) window.electronAPI.stopCapturing()
  }
  const startCapture = async (id) => {
    const checkinId = localStorage.getItem('checkinId')
    if (!checkinId) {
      swal('Error!', 'Please start shift checkin first!', 'error')
      return
    }
    try {
      if (!window?.electronAPI?.stopCapturing) {
        let mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
        let track = mediaStream.getVideoTracks()[0]
        let { width, height } = track.getSettings()
        if (track.label.includes('screen')) {
          // setLoading(true)
          const zone = moment.tz.guess(true) // modification required
          // setLoading2(true)
          const allDetails = await dispatch(
            _projectCheckIn({
              project: id,
              shiftCheckIn: checkinId,
            }),
          )
          if (allDetails) {
            setProjectCheckInId(allDetails?.data?._id)
            setProjectId(id)
          }
          let videoELReference = document.createElement('video')
          videoELReference.srcObject = mediaStream
          videoELReference.play()
          setVideoEl(videoELReference)
          setLoading(false)
          let interval = setInterval(
            () =>
              captureImage({
                videoELReference,
                width,
                height,
              }),
            180000,
          )
          setIntervalState(interval)
          dispatch({
            type: 'SET_SCREEN_INTERVAL',
            payload: interval,
          })
        } else {
          let stopTracks = mediaStream.getTracks()
          stopTracks.forEach((track) => {
            track.stop()
          })
          notification.error({
            message: 'You must select entire screen, for sharing',
            duration: 5,
          })
        }
        setTrackState(track)
      } else {
        const allDetails = await dispatch(
          _projectCheckIn({
            project: id,
            shiftCheckIn: checkinId,
          }),
        )
        if (allDetails) {
          setProjectCheckInId(allDetails?.data?._id)
          setProjectId(id)
          let obj = {
            checkinId: localStorage.getItem('checkinId'),
            projectCheckIn: localStorage.getItem('projectCheckInId'),
            project: localStorage.getItem('project'),
          }
          window.electronAPI.startCapturing(obj)
        }
      }
    } catch (err) {
      console.error(`Error: ${err}`)
    }
  }

  const checkOut = async (data) => {
    setLoading(true)
    // console.log('checkOut')
    await sendOfflineScreenshots()
    const allDetails = await dispatch(
      projectCheckOut({
        checkIn: projectCheckInId,
        remarks: data?.remarks,
      }),
    )
    if (allDetails) {
      setProjectCheckInId('')
      setProjectId('')
    }
    stopStreamedVideo()
    setCurrentCheckIn(null)
    setLoading(false)
    handleStopCapturing()
  }

  const _projectCheckIns = async () => {
    let obj = {
      projectCheckIn: localStorage.getItem('projectCheckInId'),
      shiftCheckIn: localStorage.getItem('checkinId'),
    }
    if (localStorage.getItem('checkinId')) {
      let response = await dispatch(getProjectCheckIns(obj))
      if (response.message === 'Already checked out') {
        removeCheckInDetails()
      } else if (response) setCurrentCheckIn(response?.data)
    }
  }

  useEffect(() => {
    if (intervalState && TrackState && localStorage.getItem('checkinId')) {
      TrackState.onended = async () => {
        clearInterval(intervalState)
        checkOut()
      }
    }
  }, [intervalState, TrackState])

  // Get All Employee Projects
  useEffect(() => {
    if (localStorage.getItem('token')) {
      getProjects()
      _projectCheckIns()
    }
  }, [])

  const randomLoop = (index) => {
    if (index >= colors.length) {
      return index % colors.length
    } else {
      return index
    }
  }

  const getProjects = async () => {
    setLoading(true)
    let response = await dispatch(getAllEmployeeProjects())
    if (response) setLoading(false)
  }

  const openRemarksModal = () => setRemarksModal(true)
  const closeRemarksModal = () => {
    handleRemarks()
    setRemarksModal(false)
  }
  const handleRemarks = async (value) => {
    // add remarks and checkout
    setBtnLoading(true)
    await checkOut({ remarks: value?.remarks || '' })
    setRemarksModal(false)
    setBtnLoading(false)
  }

  // component
  const ReturnCheckIn = ({ data, time }) => {
    return !projectCheckInId ? (
      <>
        <FaRegClock
          style={{ color: '#fff', fontSize: '25px', cursor: 'pointer' }}
          onClick={() => startCapture(data?._id)}
        />
      </>
    ) : (
      <>
        {projectId === data?._id && (
          <>
            <FaRegClock
              style={{ color: '#90EE90', fontSize: '25px', cursor: 'pointer' }}
              onClick={openRemarksModal}
            />
            <h2 style={{ fontSize: '10px', width: '40px' }}>{time || ''}</h2>
          </>
        )}
      </>
    )
  }

  const Project = ({ data, index }) => {
    const [checkinTime, setCheckInTime] = useState('')

    useEffect(() => {
      if (currentCheckIn?.start) {
        var startDate = new Date(currentCheckIn?.start).getTime()
        // Update the timer every second
        var timer = setInterval(function () {
          // Get the current date and time
          var now = new Date().getTime()

          // Find the time elapsed since the start date
          var elapsedTime = now - startDate

          // Calculate the elapsed time components
          var hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60))
          var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000)
          hours = hours.toString().padStart(2, '0')
          minutes = minutes.toString().padStart(2, '0')
          seconds = seconds.toString().padStart(2, '0')
          setCheckInTime(`${hours}:${minutes}:${seconds}`)
        }, 1000)
      }
      return () => {
        clearInterval(timer)
      }
    }, [currentCheckIn?.start])

    return (
      <Col
        xs={24}
        md={10}
        lg={11}
        xl={7}
        key={index}
        className={`${colors[randomLoop(index)]} box`}
        style={{
          minHeight: '220px',
          maxHeight: '220px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h2>{data?.title}</h2>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <ReturnCheckIn data={data} time={checkinTime} />
          </div>
        </div>
        <p>{data?.description?.length > 0 ? `${data?.description?.slice(0, 50)}` : '-'}</p>
        <div className='action'>
          <h4 onClick={() => history.push(`/project-e/${data?._id}`)}>
            <TbListDetails className='icon' />
            {t('details')}
          </h4>
          <AddRemarks id={data?._id} />
        </div>
      </Col>
    )
  }

  if (localStorage.getItem('token') && type !== 'employee') {
    return <Redirect to='/admin/projects' />
  } else {
    return (
      <Layout active={'admin-projects'}>
        {projects === null ? (
          <InfinityLoader />
        ) : (
          <>
            {/* <Modal
              title='Check Out'
              open={visible}
              onOk={() => {
                // checkOut()
                setVisible(false)
              }}
              onCancel={() => {
                setVisible(false)
              }}
            >
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '1rem',
                  fontWeight: '400',
                  color: 'var(--text)',
                }}
              >
                Are you sure you want to check out?
              </p>
            </Modal> */}
            <div className='admin-project-container user-project-style'>
              <div
                className='csv-button-box'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '20px',
                }}
              >
                <AddRemarksCsv />
              </div>

              {loading ? (
                <div className='d-flex align-center'>
                  <Spin />
                </div>
              ) : (
                <Row className='projects'>
                  {projects?.map((data, index) => (
                    <Project key={index} data={data} index={index} />
                  ))}
                </Row>
              )}
            </div>
            {/* Only Remarks update after checkout */}
            <Modal
              title={t('add-remarks')}
              open={remarksModal}
              footer={false}
              onCancel={closeRemarksModal}
              width={'600px'}
            >
              <Form
                requiredMark={false}
                layout='vertical'
                form={form}
                onFinish={handleRemarks}
                fields={[
                  {
                    name: 'remarks',
                    value: '',
                  },
                ]}
              >
                <Row gutter={[20, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      label={t('remarks')}
                      name={'remarks'}
                      rules={[{ required: true, message: 'Please input remarks!' }]}
                    >
                      <Input.TextArea rows={4} style={{ resize: 'none' }} />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button htmlType='submit' loading={btnLoading} type='primary'>
                    {t('add-remarks')}
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </>
        )}
      </Layout>
    )
  }
}

export default UserProject
