import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Table } from 'antd'
import moment from 'moment'

import Layout from '../layout/Layout'
import { getModerators } from '../redux'
import InfinityLoader from '../components/InfinityLoader'
import AddModerator from '../components/modal/AddModerator'
import { getRoles } from '../redux/actions/roles'

const Moderators = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const moderators = useSelector((state) => state.moderator.moderators)
  const { roles } = useSelector((state) => state.roles)

  const [moderatorData, setModeratorData] = useState(null)

  useEffect(() => {
    dispatch(getModerators())
    dispatch(getRoles())
  }, [])

  useEffect(() => {
    let data = []
    moderators?.map((moderator) => {
      data.push({
        name: moderator.name,
        email: moderator.email,
        roles: moderator.roles.roles,
        date: <span>{moment(moderator?.CreatedAt).format('D-MM-YYYY')}</span>,
        edit: (
          <div className='action-buttons'>
            <AddModerator newModerator={false} data={moderator} roles={roles} />
            {/* <AddEmployee newEmployee={false} data={employee} /> */}
          </div>
        ),
        // block: (
        //   <div className="action-buttons">
        //     <Switch
        //       defaultChecked={false}
        //       // onChange={(checked) => blockEmp(employee._id, checked)}
        //     />
        //   </div>
        // ),
      })
    })
    setModeratorData(data)
  }, [moderators, roles])

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('email'),
      dataIndex: 'email',
    },
    {
      title: t('roles'),
      dataIndex: 'roles',
      render: (role) => {
        // console.log('role 2 in column', role)
        return role?.map((item) => {
          return `${item}, `
        })
      },
    },
    {
      title: t('created-date'),
      dataIndex: 'date',
    },
    {
      title: t('edit'),
      dataIndex: 'edit',
    },
    // {
    //   title: "Block",
    //   dataIndex: "block",
    // },
  ]

  return (
    <Layout active={'admin-moderator'}>
      {moderators === null ? (
        <InfinityLoader />
      ) : (
        <div className='admin-employee-container'>
          <div className='header'>
            <AddModerator moderator={true} roles={roles} />
          </div>
          <Table
            sticky
            dataSource={moderatorData}
            className='project-table'
            columns={columns}
            scroll={{ x: 700 }}
          />
        </div>
      )}
    </Layout>
  )
}

export default Moderators
