import { DeleteOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import { deleteAccessories } from '../redux'
import AddAccessories from './modal/AddAccessories'
import swal from 'sweetalert'

const AccessoriesCard = ({ data }) => {
  const dispatch = useDispatch()
  const { _id, category, name, description, employee } = data

  const _deleteAccessories = async () => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    if (willDelete) {
      dispatch(deleteAccessories({ id: _id }))
    }
  }
  return (
    <div className='accessories-card'>
      {/* <div className="info">
                <p>ID</p>
                <p>{id}</p>
            </div> */}
      <div className='info'>
        <p>Category</p>
        <p>{category?.name}</p>
      </div>
      <div className='info'>
        <p>Name</p>
        <Tooltip placement='bottom' title={name}>
          <p>{name?.length > 13 ? `${name?.slice(0, 13)}...` : name}</p>
        </Tooltip>
      </div>
      <div className='info'>
        <p>Description</p>
        <Tooltip placement='bottom' title={description}>
          <p>{description?.length > 13 ? `${description?.slice(0, 13)}...` : description || '-'}</p>
        </Tooltip>
      </div>
      <div className='info'>
        <p>Assigned To</p>
        <p>{employee ? employee?.name : 'None'}</p>
      </div>
      <div style={{ marginLeft: 'auto', marginTop: '10px' }}>
        <AddAccessories isEdit data={data} />
        <Button icon={<DeleteOutlined />} onClick={_deleteAccessories} shape='circle'></Button>
      </div>
    </div>
  )
}

export default AccessoriesCard
