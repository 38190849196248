import { Button, Col, DatePicker, Divider, Empty,Input,Modal,Form, Row, Select, Table } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

import swal from 'sweetalert'
import InfinityLoader from '../components/InfinityLoader'
import { getTimeInHoursMins } from '../functions/timeFormatter'
import Layout from '../layout/Layout'
import {
  _projectCheckIn,
  addOfflineScreenshot,
  addScreenshot,
  endTask,
  getAllEmployeeProjects,
  projectCheckOut,
  getCheckins,
  getUserTimelineScreenshots,
  startTask,
} from '../redux'
import dataURItoBlob from '../service/dataURItoBlob'
import '../styles/pages/checkins.css'
import ProjectCheckIn from './ProjectCheckIn'
let intRun = false
const intRunChange = (val) => {
  intRun = val
  if (val == false) {
    // clearinterval
  }
}
const Checkins = ({
  intervalState,
  videoEL,
  TrackState,
  setIntervalState,
  setVideoEl,
  setTrackState,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)

  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const [data, setData] = useState()
  const dispatch = useDispatch()
  // const projects = useSelector(state => state.projects.projects);
  // const projects = useSelector(state => state.projects);
  const { projects } = useSelector((state) => state.project)
  const userId = localStorage.getItem('userId')
  const checkins = useSelector((state) => state.shiftCheckin.checkins)
  const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  const [currentCheckIn, setCurrentCheckIn] = useState(null)
  const { socket } = useSelector((state) => state.socket)
  const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  const [timer, setTimer] = useState(null)
  const [forceRender, setForceRender] = useState(false)
  const [checkinTime, setCheckInTime] = useState('')
  const [remarksModal, setRemarksModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [form] = Form.useForm() // Initialize 

  // const { socket } = useSelector((state) => state.socket)

  // const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  // const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  // const [currentCheckIn, setCurrentCheckIn] = useState(null)

  // const [form] = Form.useForm()
  // const [remarksModal, setRemarksModal] = useState(false)
  // const [btnLoading, setBtnLoading] = useState(false)

  const handleShiftEnd=()=>{
    localStorage.removeItem('project')
    if (!localStorage.getItem('project') && !localStorage.getItem('projectCheckInId')) {
      setVisible(true)
    } else {
      //  swal('Project is Checked In', 'Please checkout the project first', 'warning')
      openRemarksModal()
    }
  }

  const openRemarksModal = () => {
    console.log('clicked on openremark model')
    setRemarksModal(true)
  }
  const closeRemarksModal = async () => {
    await handleRemarks()
    stopStreamedVideo()
    setRemarksModal(false)
  }
  const handleRemarks = async (value) => {
    // add remarks and checkout
    setBtnLoading(true)
    await checkOut2({ remarks: value?.remarks || '' })
    checkOut()
    
    setRemarksModal(false)
    setBtnLoading(false)
  }

  const checkOut2 = async (data) => {
    localStorage.removeItem('project')
    // localStorage.removeItem('projectCheckInId');
    // console.log('checkOut')
    await sendOfflineScreenshots()
    const allDetails = await dispatch(
      projectCheckOut({
        checkIn: localStorage.getItem('projectCheckInId'),
        remarks: data?.remarks,
      }),
    )
    if (allDetails) {
      setProjectCheckInId('')
      setProjectId('')
    }
    stopStreamedVideo()
    setCurrentCheckIn(null)
    handleStopCapturing()
    clearInterval(timer) // Clear the timer interval
    setTimer(null) // Reset the timer state
    setVideoEl(null) // Reset the video element state
  }



  function stopStreamedVideo() {
    if (videoEL) {
      const streamVar = videoEL.srcObject
      if (streamVar) {
        const tracks = streamVar.getTracks()

        tracks.forEach((track) => {
          track.stop()
        })
      }
      clearInterval(intervalState)
    }
  }

  const removeCheckInDetails = () => {
    localStorage.removeItem('project')
    localStorage.removeItem('projectCheckInId')
    setProjectCheckInId('')
    setCurrentCheckIn(null)
    setProjectId('')
  }

  


  useEffect(() => {
    if (socket) {
      socket.on('checkinStatus', (data) => {
        removeCheckInDetails()
        stopStreamedVideo()
        handleStopCapturing()
      })
    }
  }, [socket])

  // useEffect(() => {
  //   if (currentCheckIn?.start) {
  //     var startDate = new Date(currentCheckIn?.start).getTime()
  //     // Update the timer every second
  //     var timer = setInterval(function () {
  //       // Get the current date and time
  //       var now = new Date().getTime()

  //       // Find the time elapsed since the start date
  //       var elapsedTime = now - startDate

  //       // Calculate the elapsed time components
  //       var hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  //       var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60))
  //       var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000)
  //       hours = hours.toString().padStart(2, '0')
  //       minutes = minutes.toString().padStart(2, '0')
  //       seconds = seconds.toString().padStart(2, '0')
  //       setCheckInTime(`${hours}:${minutes}:${seconds}`)
  //       console.log(`${hours}:${minutes}:${seconds}`)
  //     }, 1000)
  //   }
  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [currentCheckIn?.start])

  const onDateChange = (date, dateString) => {
    setDate({
      start: dateString[0],
      end: dateString[1],
    })
  }

  //   // Clean up the listener when the component unmounts
  //   return () => {
  //     if (window.electronAPI?.removeOnCapturedImageListener)
  //       window?.electronAPI?.removeOnCapturedImageListener()
  //   }
  // }, [])

  // useEffect(() => {
  //   dispatch(getAllEmployeeProjects());
  // }, [dispatch]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(getAllEmployeeProjects())
    }
  }, [dispatch])

  const displayMediaOptions = {
    video: {
      cursor: 'always',
    },
    audio: false,
  }

  const setCapturetoLocal = (payload) => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    // console.log("offlineScreenshots", offlineScreenshots);

    localStorage.setItem('offlineScreenshots', JSON.stringify([...offlineScreenshots, payload]))
  }

  const sendOfflineScreenshots = () => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    if (offlineScreenshots.length > 0) {
      // .format("YYYY-MM-DD--HH-MM")

      let formData = new FormData()
      offlineScreenshots?.map((data) => {
        const blob = dataURItoBlob(data.file)

        var file = new File([blob], `${data.date}.jpg`, {
          type: 'application/octet-stream',
        })
        formData.append('screenshot', file)
      })
      formData.append('checkinId', localStorage.getItem('checkinId'))
      addOfflineScreenshot(formData)
    }
  }

  const captureImage = async ({ videoELReference, width, height, image = null }) => {
    if (!image) {
      let c = document.createElement('canvas'), // create a temp. canvas
        ctx = c.getContext('2d')
      c.width = width // set size = image, draw
      c.height = height
      ctx.drawImage(videoELReference, 0, 0)

      var imgAsDataURL = c.toDataURL('application/octet-stream')
      const blob = dataURItoBlob(imgAsDataURL)

      let payload = {
        file: imgAsDataURL,
        date: Date.now(),
      }
      fetch('https://backend.trackkrr.com', {
        method: 'GET',
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          var file = new File(
            [blob],
            `${Date.now()}.jpg`,

            {
              type: 'application/octet-stream',
            },
          )
          sendOfflineScreenshots()

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        })
        .catch(() => {
          setCapturetoLocal(payload)
        })
    } else {
      const blob = dataURItoBlob(image)
      console.log('image', image)
      let payload = {
        file: image,
        date: Date.now(),
      }

      console.log('payload.file', payload?.file?.slice(0, 10))
      fetch('https://backend.trackkrr.com', {
        method: 'GET',
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          var file = new File(
            [blob],
            `${Date.now()}.jpg`,

            {
              type: 'application/octet-stream',
            },
          )
          sendOfflineScreenshots()

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        })
        .catch(() => {
          setCapturetoLocal(payload)
        })
    }
  }

  // const startCapture = async (id) => {
  //   const checkinId = localStorage.getItem('checkinId')
  //   if (!checkinId) {
  //     swal('Error!', 'Please start shift checkin first!', 'error')
  //     return
  //   }
  //   try {
  //     if (!window?.electronAPI?.stopCapturing) {
  //       let mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
  //       let track = mediaStream.getVideoTracks()[0]
  //       let { width, height } = track.getSettings()
  //       if (track.label.includes('screen')) {
  //         // setLoading(true)
  //         const zone = moment.tz.guess(true) // modification required
  //         // setLoading2(true)
  //         const allDetails = await dispatch(
  //           _projectCheckIn({
  //             project: id,
  //             shiftCheckIn: checkinId,
  //           }),
  //         )
  //         if (allDetails) {
  //           setProjectCheckInId(allDetails?.data?._id)
  //           setProjectId(id)
  //         }
  //         let videoELReference = document.createElement('video')
  //         videoELReference.srcObject = mediaStream
  //         videoELReference.play()
  //         setVideoEl(videoELReference)
  //         setLoading(false)
  //         let interval = setInterval(
  //           () =>
  //             captureImage({
  //               videoELReference,
  //               width,
  //               height,
  //             }),
  //           180000,
  //         )
  //         setIntervalState(interval)
  //         dispatch({
  //           type: 'SET_SCREEN_INTERVAL',
  //           payload: interval,
  //         })
  //       } else {
  //         let stopTracks = mediaStream.getTracks()
  //         stopTracks.forEach((track) => {
  //           track.stop()
  //         })
  //         notification.error({
  //           message: 'You must select entire screen, for sharing',
  //           duration: 5,
  //         })
  //       }
  //       setTrackState(track)
  //     } else {
  //       const allDetails = await dispatch(
  //         _projectCheckIn({
  //           project: id,
  //           shiftCheckIn: checkinId,
  //         }),
  //       )
  //       if (allDetails) {
  //         setProjectCheckInId(allDetails?.data?._id)
  //         setProjectId(id)
  //         let obj = {
  //           checkinId: localStorage.getItem('checkinId'),
  //           projectCheckIn: localStorage.getItem('projectCheckInId'),
  //           project: localStorage.getItem('project'),
  //         }
  //         window.electronAPI.startCapturing(obj)
  //       }
  //     }
  //   } catch (err) {
  //     console.error(`Error: ${err}`)
  //   }
  // }

  // const startCapture = async (id) => {
  //   const checkinId = localStorage.getItem('checkinId');
  //   if (!checkinId) {
  //     swal('Error!', 'Please start shift checkin first!', 'error');
  //     return;
  //   }

  //   try {
  //     if (!window?.electronAPI?.stopCapturing) {
  //       const mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
  //       const track = mediaStream.getVideoTracks()[0];
  //       if (track.label.includes('screen')) {
  //         const allDetails = await dispatch(_projectCheckIn({ project: id, shiftCheckIn: checkinId }));
  //         if (allDetails) {
  //           setProjectCheckInId(allDetails?.data?._id);
  //           setProjectId(id);
  //         }
  //         const videoELReference = document.createElement('video');
  //         videoELReference.srcObject = mediaStream;
  //         videoELReference.play();
  //         setVideoEl(videoELReference);
  //         const { width, height } = track.getSettings();
  //         const interval = setInterval(() => captureImage({ videoELReference, width, height }), 180000);
  //         setIntervalState(interval);
  //         dispatch({ type: 'SET_SCREEN_INTERVAL', payload: interval });
  //       } else {
  //         mediaStream.getTracks().forEach((track) => track.stop());
  //         notification.error({ message: 'You must select entire screen for sharing', duration: 5 });
  //       }
  //       setTrackState(track);
  //     } else {
  //       const allDetails = await dispatch(_projectCheckIn({ project: id, shiftCheckIn: checkinId }));
  //       if (allDetails) {
  //         setProjectCheckInId(allDetails?.data?._id);
  //         setProjectId(id);
  //         const obj = { checkinId: localStorage.getItem('checkinId'), projectCheckIn: localStorage.getItem('projectCheckInId'), project: localStorage.getItem('project') };
  //         window.electronAPI.startCapturing(obj);
  //       }
  //     }

  //   } catch (err) {
  //     swal('Error!', err.message || 'An error occurred', 'error');
  //     console.error(`Error: ${err}`);
  //   }
  // };

  const handleChnage = (e) => {
    e === 'today'
      ? setDate({
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'yesterday'
      ? setDate({
          start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        })
      : e === 'last7Days'
      ? setDate({
          start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'last30Days'
      ? setDate({
          start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : e === 'thisMonth'
      ? setDate({
          start: moment().startOf('month').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
      : setDate({
          start: moment().format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
  }

  const fetchCheckins = async () => {
    if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
      setLoading2(true)
      let allDetails = null
      if (date.start && date.end) {
        allDetails = await dispatch(
          getCheckins({
            start: moment(date.start).format('YYYY-MM-DD'),
            end: moment(date.end).format('YYYY-MM-DD'),
            userType: 'employee',
          }),
        )
        setData(allDetails)
      } else {
        allDetails = await dispatch(getCheckins({ userType: 'employee' }))
        setData(allDetails)
      }
      setLoading2(false)
      intRunChange(false)
    }
  }

  const startShiftCheckin = async () => {
    const zone = moment.tz.guess(true) // modification required
    try {
      setLoading(true)
      const allDetails = await dispatch(
        startTask(
          {
            latitude: 0,
            longitude: 0,
          },
          zone,
          userId,
        ),
      )
      setData(allDetails)
      fetchCheckins()

      setLoading(false)
    } catch ({ message }) {
      console.log(message)
    }
    // try {
    //   let mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)

    //   // console.log(mediaStream);

    //   let track = mediaStream.getVideoTracks()[0]
    //   // setTrackState(track) moving this to bottom for getting latest data as whole component re renders when this state changes and its causing loading issue

    //   let { width, height } = track.getSettings()

    //   if (track.label.includes('screen')) {
    //     setLoading(true)

    //     // await getLocation();

    //     const zone = moment.tz.guess(true) // modification required

    //     // // console.log(cordinates)
    //     setLoading2(true)
    //     const allDetails = await dispatch(
    //       startTask(
    //         {
    //           latitude: 0,
    //           longitude: 0,
    //         },
    //         zone,
    //         userId,
    //       ),
    //     )
    //     setData(allDetails)
    //     setLoading2(false)

    //     // setCheckedIn(true)
    //     // console.log(`setCheckedIn(true)`)

    //     // navigator.geolocation.getCurrentPosition(
    //     //   async (position) => {
    //     //     let cordinates = {
    //     //       latitude: 0,
    //     //       longitude: 0,
    //     //     }
    //     //     cordinates.latitude = position.coords.latitude
    //     //     cordinates.longitude = position.coords.longitude
    //     //     // console.log("cordinates", position.coords.latitude, position.coords.longitude);
    //     //     // let address = await fetch(
    //     //     //   `https://api.opencagedata.com/geocode/v1/json?q=${cordinates.latitude}+${cordinates.longitude}&key=03c48dae07364cabb7f121d8c1519492&no_annotations=1&language=en`
    //     //     // );
    //     //     // address = await address.json();
    //     //     const zone = moment.tz.guess(true) // modification required

    //     //     console.log(cordinates)

    //     //     const allDetails = await dispatch(startTask(cordinates, zone, userId, setData))
    //     //     if (allDetails) {
    //     //       setCheckedIn(true)
    //     //       console.log(`setCheckedIn(true)`)

    //     //       // setTimeout(() => {
    //     //       // console.log('timeout')
    //     //       // setLoading2(true)
    //     //       // fetchCheckins()
    //     //       // }, 5000)
    //     //     }
    //     //   },
    //     //   (e) => {
    //     //     console.log(e)
    //     //   },
    //     //   {
    //     //     enableHighAccuracy: false,
    //     //     timeout: 5000,
    //     //     maximumAge: 0,
    //     //   },
    //     // )
    //     // not using navigator.geolocation.getCurrentPosition as it is not letting the state update and currently we are not using it

    //     let videoELReference = document.createElement('video')
    //     videoELReference.srcObject = mediaStream
    //     videoELReference.play()
    //     setVideoEl(videoELReference)
    //     setLoading(false)

    //     let interval = setInterval(() => {
    //       let c = document.createElement('canvas'), // create a temp. canvas
    //         ctx = c.getContext('2d')
    //       c.width = width // set size = image, draw
    //       c.height = height
    //       ctx.drawImage(videoELReference, 0, 0)

    //       var imgAsDataURL = c.toDataURL('application/octet-stream')

    //       const blob = dataURItoBlob(imgAsDataURL)

    //       let payload = {
    //         file: imgAsDataURL,
    //         date: Date.now(),
    //       }
    //       fetch('https://backend.trackkrr.com', {
    //         method: 'GET',
    //         timeout: 1000,
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //       })
    //         .then(() => {
    //           var file = new File(
    //             [blob],
    //             `${Date.now()}.jpg`,

    //             {
    //               type: 'application/octet-stream',
    //             },
    //           )
    //           sendOfflineScreenshots()

    //           let formData = new FormData()
    //           formData.append('screenshot', file)
    //           formData.append('checkinId', localStorage.getItem('checkinId'))
    //           if (localStorage.getItem('checkinId')) {
    //             addScreenshot(formData, payload, setCapturetoLocal)
    //           }
    //         })
    //         .catch(() => {
    //           setCapturetoLocal(payload)
    //         })
    //       //   },
    //       //   "image/jpeg",
    //       //   0.75
    //       // );
    //     }, 30000)

    //     setIntervalState(interval)

    //     dispatch({
    //       type: 'SET_SCREEN_INTERVAL',
    //       payload: interval,
    //     })
    //   } else {
    //     let stopTracks = mediaStream.getTracks()
    //     stopTracks.forEach((track) => {
    //       track.stop()
    //     })
    //     notification.error({
    //       message: 'You must select entire screen, for sharing',
    //       duration: 5,
    //     })
    //   }
    //   setTrackState(track)
    //   // setIntervalState(interval);
    // } catch (err) {
    //   // setLoading(true);
    //   // dispatch(endTask());
    //   // stopStreamedVideo();
    //   // setLoading(false);
    //   console.error(`Error: ${err}`)
    // }
  }
  const checkOut = async () => {
    setLoading(true)
    const allDetails = await dispatch(endTask(false, userId))
    setData(allDetails)
    setLoading(false)
    localStorage.removeItem('checkinId')
  }
  // const isShiftOn = () => {
  //   let index = checkins?.findIndex((obj) => obj.user === userId && !obj.end)
  //   if (index >= 0 && !localStorage.getItem('checkinId'))
  //     localStorage.setItem('checkinId', checkins[index]?._id)
  //   return index === -1
  // }
  const isShiftOn = () => {
    if (!Array.isArray(checkins)) {
      console.error('checkins is not an array')
      return false // or handle this case as per your requirements
    }

    let index = checkins?.findIndex((obj) => obj.user === userId && !obj.end)
    if (index >= 0 && !localStorage.getItem('checkinId')) {
      localStorage.setItem('checkinId', checkins[index]?._id)
    }
    return index === -1
  }

  console.log('in checkins.js', isShiftOn())
  const handleStartCapturing = () => {
    if (window?.electronAPI?.startCapturing) window.electronAPI.startCapturing()
  }

  const handleStopCapturing = () => {
    if (window?.electronAPI?.stopCapturing) window.electronAPI.stopCapturing()
  }

  useEffect(() => {
    fetchCheckins()
  }, [date])

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userType') === 'employee') {
      // get all checkins of user from db
      dispatch(getUserTimelineScreenshots())
    }
  }, [])

  const [visible, setVisible] = useState(false)

  return (
    <Layout active='checkin'>
      {/* <div>
        <Button type='primary' onClick={handleStartCapturing}>
          Start Capture
        </Button>
        <Button type='primary' onClick={handleStopCapturing}>
          Stop Capture
        </Button>
      </div> */}
      <div>
        <Modal
          title={t('add-remarks')}
          open={remarksModal}
          footer={false}
          onCancel={closeRemarksModal}
          width={'600px'}
        >
          <Form
            requiredMark={false}
            layout='vertical'
            form={form}
            onFinish={handleRemarks}
            // fields={[
            //   {
            //     name: 'remarks',
            //     value: '',
            //   },
            // ]}
          >
            <Row gutter={[20, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t('remarks')}
                  name={'remarks'}
                  rules={[{ required: true, message: 'Please input remarks!' }]}
                >
                  <Input.TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button htmlType='submit' loading={btnLoading} type='primary'>
                {t('add-remarks')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
      <div className='checkin-btn'>
        <div style={{ position: 'absolute' }}>
          {isShiftOn() ? (
            <Button
              type='primary'
              loading={loading}
              onClick={async () => {
                startShiftCheckin()
              }}
            >
              {t('shift-start')}
            </Button>
          ) : (
            <Button
              type='primary'
              loading={loading}
              onClick={
                handleShiftEnd
                
              }
            >
              {t('shift-end')}
            </Button>
          )}
        </div>
        {/* {isShiftOn() ? (
          
          <Button
            type='primary'
            loading={loading}
            onClick={async () => {
              startShiftCheckin()
            }}
          >
            {t('shift-start')}
          </Button>
        ) : (
          <Button
            type='primary'
            loading={loading}
            onClick={() => {
              if (!localStorage.getItem('project') && !localStorage.getItem('projectCheckInId')) {
                setVisible(true)
              } else swal('Project is Checked In', 'Please checkout the project first', 'warning')
            }}
          >
            {t('shift-end')}
          </Button>
        )} */}
        <div style={{ marginRight: '110px', display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Select
            onChange={(value,option) => {
              setSelectedProject(option.label)
              console.log(selectedProject)
              localStorage.setItem('project', value)
            }}
            defaultValue='Projects'
            style={{ width: '250px' }}
            options={projects?.map((project) => ({
              value: project._id,
              label: project.title,
               disabled: project.title === selectedProject
              
            }))}
          />
          {selectedProject && (
            <ProjectCheckIn
              selectedProject={selectedProject}
              intervalState={intervalState}
              // isShiftOn={isShiftOn()}
              currentCheckIn={currentCheckIn}
              videoEL={videoEL}
              setVideoEl={setVideoEl}
              setIntervalState={setIntervalState}
              TrackState={TrackState}
              setTrackState={setTrackState}
              projects={projects} 
              
            />
          )}
          {/* <ProjectCheckIn
            intervalState={intervalState}
            currentCheckIn={currentCheckIn}
            videoEL={videoEL}
            setVideoEl={setVideoEl}
            setIntervalState={setIntervalState}
            TrackState={TrackState}
            setTrackState={setTrackState}
          /> */}
        </div>
      </div>

      {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' , marginLeft:'765px',}}>
        <Select 
          onChange={(value) => {
            setSelectedProject(value); console.log(selectedProject)
            localStorage.setItem('project', value);
          }}
          defaultValue='Projects'
          style={{ width: '250px' }}
          options={projects?.map((project) => ({
            value: project._id,
            label: project.title,
          }))}
          

        />
        <ProjectCheckIn intervalState={intervalState} currentCheckIn={currentCheckIn} videoEL={videoEL} setVideoEl={setVideoEl} setIntervalState={setIntervalState} TrackState={TrackState} setTrackState={setTrackState}  />
      </div> */}

      {/* <Select
          // onChange={(e) => checkInStart(e)}
          onChange={(value) => setSelectedProject(value)}
          defaultValue='Projects'
          style={{
            width: '250px',
          }}
          // options={projects.map(projects=>({
          //   label:projects.title
          // }))}
          options={projects?.map(project => ({

             value: project._id, // Assuming project has an _id field
             label: project.title, // Display project title in the dropdown
            
          }))}
        />
        <button onClick={checkInStart}>Start Project</button> */}

      <div className='checkin-head'>
        <Modal
          title='Check Out'
          open={visible}
          onOk={() => {

            checkOut()

            setVisible(false)
          }}
          onCancel={() => {
            setVisible(false)
          }}
        >
          <p
            style={{
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: '400',
              color: 'var(--text)',
            }}
          >
            Are you sure you want to check out?
          </p>
        </Modal>
        <h2>{t('checkin-history')}</h2>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Select
            onChange={(e) => handleChnage(e)}
            defaultValue='today'
            style={{
              width: '250px',
            }}
            options={[
              {
                value: 'today',
                label: 'Today',
              },
              {
                value: 'yesterday',
                label: 'Yesterday',
              },
              {
                value: 'last7Days',
                label: 'Last 7 Days',
              },
              {
                value: 'last30Days',
                label: 'Last 30 Days',
              },
              // {
              //   value: 'thisMonth',
              //   label: 'This Month',
              // },
              {
                value: 'lastMonth',
                label: 'Last Month',
              },
            ]}
          />
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={onDateChange}
            disabledDate={(d) => {
              return d?.isAfter(moment())
              // return moment(d).day() === 0 || d?.isAfter(moment());
            }}
          />
        </div>
      </div>
      {loading2 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70vh',
          }}
        >
          <InfinityLoader />
        </div>
      ) : (
        <div className='checkin-history'>
          {data ? (
            data?.length > 0 ? (
              <div className='checkin-history-c1'>
                {data.map((checkin, index) => {
                  return (
                    !loading2 &&
                    !loading && (
                      <SnapComponent
                        key={index}
                        checkin={checkin}
                        date={date}
                        checkins={data?.checkins}
                      />
                    )
                  )
                })}
              </div>
            ) : (
              <div className='empty'>
                <Empty description={t('no-checkins-today')} />
              </div>
            )
          ) : (
            <div className='empty'>
              <Empty description={t('no-checkins-found')} />
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

const SnapComponent = ({ date, checkins, checkin }) => {
  console.log('checkin', checkin)
  const [show, setShow] = useState(false)
  // const [data, setData] = useState(null)
  // const [totalHours, setTotalHours] = useState(0)
  const btnShow = (date) => {
    let details = []
    let time = 0
    let flag = {
      flag: false,
      date: '',
    }
    checkin?.projectCheckIns?.map((projectCheckIn) => {
      if (moment(checkin.date).format('YYYY-MM-DD') === date) {
        details.push(checkin)
        if (checkin.time.end !== 'Ongoing') {
          time = time + checkin.hours
        } else {
          let ongoingTime = 0
          ongoingTime = new Date() - new Date(checkin.time.start)
          ongoingTime = ongoingTime / 1000 / 60 / 60
          time = time + ongoingTime
        }
      }
    })
    // details = details.sort((a, b) => {
    //   return new Date(a.time.start) - new Date(b.time.start)
    // })
    // checkins.find((checkin) => {
    //   if (checkin.time.end === 'Ongoing') {
    //     flag.flag = true
    //     flag.date = moment(checkin.date).format('YYYY-MM-DD')
    //   }
    // })
    // if (flag.flag && flag.date === date) {
    //   if (intRun) {
    //     setData(details)
    //     setShow(!show)
    //     return
    //   } else {
    //     let interval = 1000
    //     time = time * 60 * 60 * 1000
    //     let int = setInterval(() => {
    //       time = time + interval
    //       const seconds = Math.floor(time / 1000) // convert milliseconds to seconds
    //       const minutes = Math.floor(seconds / 60) // convert seconds to minutes
    //       const hours = Math.floor(minutes / 60) // convert minutes to hours
    //       const remainingMinutes = minutes % 60 // calculate remaining minutes
    //       const remainingSeconds = seconds % 60 // calculate remaining seconds
    //       setTotalHours(
    //         `${hours.toString().padStart(2, '0')}:${remainingMinutes
    //           .toString()
    //           .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`,
    //       )
    //       if (!localStorage.getItem('checkinId')) {
    //         clearInterval(int)
    //         intRunChange(false)

    //         setTotalHours(
    //           `${hours.toString().padStart(2, '0')}:${remainingMinutes
    //             .toString()
    //             .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`,
    //         )
    //       }
    //     }, interval)
    //     if (int) {
    //       intRunChange(true)
    //     }
    //   }
    // } else {
    //   time = time * 60 * 60 * 1000
    //   const seconds = Math.floor(time / 1000) // convert milliseconds to seconds
    //   const minutes = Math.floor(seconds / 60) // convert seconds to minutes
    //   const hours = Math.floor(minutes / 60) // convert minutes to hours
    //   const remainingMinutes = minutes % 60 // calculate remaining minutes
    //   const remainingSeconds = seconds % 60 // calculate remaining seconds
    //   time = `${hours.toString().padStart(2, '0')}:${remainingMinutes
    //     .toString()
    //     .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`

    //   setTotalHours(time)
    // }
    console.log('checkin', date)
    // setData(checkin?.projectCheckIns)
    setShow(!show)
  }

  const columns = [
    {
      title: 'Project',
      dataIndex: 'project',
      render: (text, record) => {
        // _id is project
        return <p>{record?.project?.title}</p>
      },
    },
    {
      title: 'Number of Checkins',
      dataIndex: 'checkins',
      render: (text, record) => {
        return <p>{record?.checkins?.length}</p>
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (text, record) => {
        const now = new Date() // Current date and time

        let sorted = record?.checkins
          ?.slice()
          ?.sort((val1, val2) => new Date(val1?.start) - new Date(val2?.start))

        let totalHours = 0

        for (const shift of sorted) {
          const startTime = new Date(shift.start)
          const endTime = shift?.end === undefined ? now : new Date(shift?.end)

          const duration = (endTime - startTime) / (1000 * 60 * 60) // Convert milliseconds to hours
          totalHours += duration
        }

        return <p>{`${getTimeInHoursMins(totalHours)}`}</p>
      },
    },
  ]
  return (
    <>
      <div className='screenshot-section'>
        <div
          style={{
            width: '100%',
            border: '1px solid var(--borderLight',
            padding: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              width: '100%',
              margin: '0px 0px 10px 0px',
            }}
            onClick={() => btnShow(checkin?.checkIn?.start)}
          >
            <span
              style={{
                color: 'var(--text)',
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {moment(checkin?.checkIn?.start).tz('Asia/Karachi').format('dddd, DD-MMM-YYYY')}
            </span>
            {show ? (
              <IoIosArrowDropup
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            ) : (
              <IoIosArrowDropdown
                style={{
                  color: 'var(--text)',
                  fontSize: '2rem',
                  marginRight: '10px',
                }}
              />
            )}
          </div>
          {show && (
            <>
              <Table
                showHeader={true}
                sticky
                expandable={{
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <Row
                      gutter={[30, 15]}
                      style={{
                        border: '1px solid var(--borderLight)',
                        borderRadius: '5px',
                        padding: '1rem',
                        margin: '1rem',
                        boxShadow: 'var(--shadow)',
                      }}
                    >
                      <Col span={24}>
                        <h2
                          style={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            color: 'var(--text)',
                            margin: '0',
                          }}
                        >
                          {t('checkin-details')}
                        </h2>
                        <Divider
                          style={{
                            margin: '0',
                            borderColor: 'var(--text)',
                          }}
                        />
                      </Col>

                      <Col span={24}>
                        <Row gutter={[15, 15]} justify='space-around'>
                          {checkin?.projectCheckIns
                            ?.find((val) => val?.project?._id === record?.project?._id)
                            ?.checkins?.map((projectCheckIn, index) => {
                              return (
                                <Col xs={24} md={10} lg={6} key={index}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      flexDirection: 'column',
                                      padding: '0.5rem',
                                      border: '1px solid var(--borderLight)',
                                      borderRadius: '5px',
                                      boxShadow: 'var(--shadow)',
                                      minWidth: '170px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {index + 1}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {t('start')} :{' '}
                                      {moment(projectCheckIn?.start)
                                        .tz('Asia/Karachi')
                                        .format('hh:mm a')}{' '}
                                      (local)
                                    </span>
                                    <span
                                      style={{
                                        fontSize: '0.8rem',
                                        color: 'var(--text)',
                                      }}
                                    >
                                      {t('end')} :{' '}
                                      {projectCheckIn?.end
                                        ? moment(projectCheckIn?.end)
                                            .tz('Asia/Karachi')
                                            .format('hh:mm a')
                                        : 'Ongoing'}
                                      (local)
                                    </span>
                                  </div>
                                </Col>
                              )
                            })}
                        </Row>
                      </Col>
                    </Row>
                  ),
                }}
                dataSource={checkin?.projectCheckIns?.map((value, index) => ({
                  ...value,
                  key: index,
                }))}
                columns={columns}
                // scroll={{ x: 700 }}
                pagination={false}
              />
              {/* <Row
                gutter={[30, 15]}
                style={{
                  border: '1px solid var(--borderLight)',
                  borderRadius: '5px',
                  padding: '1rem',
                  margin: '1rem',
                  boxShadow: 'var(--shadow)',
                }}
              >
                <Col span={24}>
                  <h2
                    style={{
                      fontSize: '1.2rem',
                      fontWeight: 'bold',
                      color: 'var(--text)',
                      margin: '0',
                    }}
                  >
                    {t('checkin-details')}
                  </h2>
                  <Divider
                    style={{
                      margin: '0',
                      borderColor: 'var(--text)',
                    }}
                  />
                </Col>
                <Col
                  xs={24}
                  md={24}
                  style={
                    {
                      // marginTop: "0.5rem",
                    }
                  }
                >
                  <Row>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: 'flex',
                        // justifyContent: "center",
                        alignItems: 'center',
                      }}
                    >
                      <AiOutlineLogin
                        style={{
                          fontSize: '2rem',
                          color: 'var(--text)',
                        }}
                      />
                      <span
                        style={{
                          fontSize: '1rem',
                          color: 'var(--text)',
                          marginRight: '0.3rem',
                        }}
                      >
                        {t('no-of-checkins')} : {data?.length || 0}
                      </span>
                    </Col>
                    <Col
                      md={11}
                      xs={24}
                      style={{
                        display: 'flex',
                        // justifyContent: "center",
                        alignItems: 'center',
                      }}
                    >
                      <AiOutlineFieldTime
                        style={{
                          fontSize: '2rem',
                          color: 'var(--text)',
                        }}
                      />
                      <span
                        style={{
                          fontSize: '1rem',
                          color: 'var(--text)',
                          marginRight: '0.3rem',
                        }}
                      >
                        {t('time')} :
                      </span>
                      <span
                        style={{
                          fontSize: '1rem',
                          color: 'var(--text)',
                        }}
                      >
                        {totalHours}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]} justify='space-around'>
                    {data?.map((checkin, index) => {
                      return (
                        <Col xs={24} md={10} lg={6} key={index}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'column',
                              padding: '0.5rem',
                              border: '1px solid var(--borderLight)',
                              borderRadius: '5px',
                              boxShadow: 'var(--shadow)',
                              minWidth: '170px',
                            }}
                          >
                            <span
                              style={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: 'var(--text)',
                              }}
                            >
                              {index + 1}
                            </span>
                            <span
                              style={{
                                fontSize: '0.8rem',
                                color: 'var(--text)',
                              }}
                            >
                              {t('start')} :{' '}
                              {moment(checkin.time.start).tz('Asia/Karachi').format('hh:mm a')}{' '}
                              (local)
                            </span>
                            <span
                              style={{
                                fontSize: '0.8rem',
                                color: 'var(--text)',
                              }}
                            >
                              {t('end')} :{' '}
                              {checkin.time.end == 'Ongoing'
                                ? checkin.time.end
                                : moment(checkin.time.end)
                                    .tz('Asia/Karachi')
                                    .format('hh:mm a')}{' '}
                              (local)
                            </span>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </Row> */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Checkins
