import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import Layout from '../../../layout/Layout'
import { getOrganizationHierarchy } from '../../../redux'
import AddAccessories from '../../../components/modal/AddAccessories'
import Accessories from './components/accessories'
import OrganizationChart from '../../../components/Charts/OrganizationChart'
import InfinityLoader from '../../../components/InfinityLoader'
import Documents from './components/documents'
import AddDocumentModal from '../../../components/modal/AddDocumentModal'
import EmployeeHistory from './components/history'
import AdminTicket from './components/Ticket'

const AdminHR = () => {
  const { TabPane } = Tabs
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [hierarchy, setHierarchy] = useState(null)
  const [activeTab, setActiveTab] = useState('organization-hierarchy')
  const [showDocModal, setShowDocModal] = useState(false)
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
  const [employeeDocs, setEmployeeDocs] = useState([])
  const userType = localStorage.getItem('userType')
  const moderator = JSON.parse(localStorage.getItem('moderator'))
  const roles = moderator?.roles?.roles

  const checkPermissions = (key) => {
    if (userType === 'admin') {
      return true
    } else {
      if (userType === 'moderator') {
        // setActiveTab('tickets')
        return roles.includes(key)
      }
    }
  }

  const getHierarchyData = async () => {
    try {
      const result = await dispatch(getOrganizationHierarchy())
      if (result) {
        setHierarchy(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    getHierarchyData()
  }, [])

  useEffect(() => {
    setShowDocModal(false)
  }, [activeTab])

  useEffect(() => {
    if (userType === 'moderator') {
      let exists = ''
      const tabsKey = [
        'hr-organization-hierarchy',
        'hr-accessories',
        'hr-documents',
        'hr-employee-history',
        'hr-tickets',
      ]
      for (var i = 0; i < tabsKey.length; i++) {
        if (roles.includes(tabsKey[i])) {
          exists = tabsKey[i]
          break
        }
      }
      setActiveTab(exists?.slice(exists?.indexOf('-')) + 1)
    }
  }, [])

  return (
    <Layout active={'admin-hr'}>
      {hierarchy === null ? (
        <InfinityLoader />
      ) : (
        <div className='hr-container'>
          <Tabs
            defaultActiveKey={activeTab}
            animated
            onChange={(e) => setActiveTab(e)}
            tabBarExtraContent={
              activeTab === 'accessories' ? (
                <AddAccessories />
              ) : activeTab === 'documents' ? (
                showDocModal && (
                  <AddDocumentModal
                    selectedEmployeeId={selectedEmployeeId}
                    setActiveTab={setActiveTab}
                    setEmployeeDocs={setEmployeeDocs}
                  />
                )
              ) : (
                <></>
              )
            }
          >
            {checkPermissions('hr-organization-hierarchy') && (
              <TabPane
                tab={t('organization-hierarchy')}
                key='organization-hierarchy'
                style={{ padding: '20px 0' }}
              >
                {hierarchy && <OrganizationChart data={hierarchy} />}
              </TabPane>
            )}
            {checkPermissions('hr-accessories') && (
              <TabPane tab={t('accessories')} key='accessories' style={{ padding: '20px 0' }}>
                <Accessories activeTab={activeTab} />
              </TabPane>
            )}
            {checkPermissions('hr-documents') && (
              <TabPane tab={t('documents')} key='documents' style={{ padding: '20px 0' }}>
                <Documents
                  activeTab={activeTab}
                  setShowDocModal={setShowDocModal}
                  setSelectedEmployeeId={setSelectedEmployeeId}
                  selectedEmployeeId={selectedEmployeeId}
                  employeeDocs={employeeDocs}
                  setEmployeeDocs={setEmployeeDocs}
                />
              </TabPane>
            )}
            {checkPermissions('hr-employee-history') && (
              <TabPane tab={t('employee-history')} key='history' style={{ padding: '20px 0' }}>
                <EmployeeHistory
                  activeTab={activeTab}
                  setSelectedEmployeeId={setSelectedEmployeeId}
                  selectedEmployeeId={selectedEmployeeId}
                />
              </TabPane>
            )}
            {checkPermissions('hr-tickets') && (
              <TabPane tab={t('tickets')} key='tickets' style={{ padding: '20px 0' }}>
                <AdminTicket activeTab={activeTab} />
              </TabPane>
            )}
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default AdminHR
