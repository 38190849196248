import { GET_LOGS } from '../types/generalTypes'

const initialState = {
  logs: [],
}

const logReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_LOGS:
      return {
        ...state,
        logs: payload,
        loading: false,
      }

    default:
      return state
  }
}

export default logReducer
