import swal from 'sweetalert'
import { notification } from 'antd'

import privateAPI, { attachToken } from '../../config/constants'
import * as types from '../types/generalTypes'

export const getModerators = () => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/admin/get-moderators`)
      if (res) {
        dispatch({
          type: types.GET_ALL_MODERATORS,
          payload: res.data,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const createModerator = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/admin/create-moderator`, payload)
      if (res) {
        dispatch(getModerators())
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateModerator = (id, payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/admin/update-moderator/${id}`, payload)
      if (res) {
        dispatch(getModerators())
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

// export const updateEmployee = (payload) => {
//   return async (dispatch) => {
//     try {
//       attachToken();
//       const res = await privateAPI.post(`/employee/update`, payload);
//       if (res) {
//         dispatch(getEmployees());

//         notification.success({
//           description: res.data.message,
//           duration: 2,
//         });
//       }
//     } catch (err) {
//       //console.log(err);
//       swal("", err?.response?.data?.message || "Server Error", "error");
//     }
//   };
// };
