import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, Table, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { getAllTickets, updateTicket } from '../../../../redux'
import TicketRemarksModal from '../../../../components/modal/TicketRemarks'

const AdminTicket = ({ activeTab }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tickets, setTickets] = useState(null)
  const [data, setData] = useState(null)
  const [openTicketRemarks, setOpenTicketRemarks] = useState(null)

  const getEmployeesTickets = async () => {
    try {
      const result = await dispatch(getAllTickets())
      if (result) {
        setTickets(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    if (activeTab === 'tickets') {
      getEmployeesTickets()
    }
  }, [activeTab])

  useEffect(() => {
    let tempArr = []
    tickets?.map((employee) => {
      tempArr.push({
        _id: employee?._id,
        ticketIssueTo: employee?.ticketIssueTo?.name,
        employee: employee?.ticketIssueTo?.name,
        remarks: employee.remarks ? employee.remarks : '-',
        date: moment(employee?.createdAt).format('DD/MM/YYYY'),
        description:
          employee?.description?.length > 20 ? (
            <Tooltip placement='top' title={employee?.description}>
              {employee?.description?.slice(0, 20)}...
            </Tooltip>
          ) : (
            employee?.description
          ),
        status: employee?.status || '-',
        // employee?.status === 'Active' ? (
        //   <span className='greentag'>{employee?.status}</span>
        // ) : (
        //   <span className='redtag'>{employee?.status}</span>
        // ),
      })
    })
    setData(tempArr)
  }, [tickets])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Ticket Issue To',
      dataIndex: 'ticketIssueTo',
    },
    {
      title: 'Ticket Issued From',
      dataIndex: 'employee',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        return (
          <div>
            <Select
              value={record?.status}
              style={{ width: 120, color: record?.status === 'Active' ? 'green' : 'red' }}
              onChange={async (value) => {
                if (value === 'Closed') {
                  setOpenTicketRemarks(record?._id)
                } else {
                  const res = await dispatch(updateTicket({ ticketId: record?._id, status: value }))
                  if (res) {
                    getEmployeesTickets()
                  }
                }
              }}
            >
              <Select.Option value='Active'>Active</Select.Option>
              <Select.Option value='Closed'>Closed</Select.Option>
            </Select>
          </div>
        )
      },
    },
  ]

  return (
    <div className='ticket-container'>
      <Table sticky dataSource={data} columns={columns} loading={data ? false : true} />
      <TicketRemarksModal
        open={openTicketRemarks}
        setOpen={setOpenTicketRemarks}
        getEmployeesTickets={getEmployeesTickets}
      />
    </div>
  )
}

export default AdminTicket
