import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
// import { Modal } from 'antd'
import { t } from 'i18next'
import swal from 'sweetalert'
const AlertModal = () => {
  //   const [isModalVisible, setIsModalVisible] = useState(false)
  const { socket } = useSelector((state) => state.socket)
  useEffect(() => {
    if (socket) {
      socket.on('checkinAlert', (type, message) => {
        // console.log(type, message)
        setTimeout(() => {
          //   setIsModalVisible(true)
          if ('Notification' in window && Notification.permission === 'granted') {
            new Notification('Alert', {
              body: 'You have not checked in any project yet.',
            })
            const audio = new Audio('/alert.mp3')
            audio.play()
            swal({
              title: t('Alert'),
              text: t('You have not checked in any project yet.'),
              icon: 'warning',
            })
          } else if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                new Notification('Alert', {
                  body: 'You have not checked in any project  yet.',
                })
                const audio = new Audio('/alert.mp3')
                audio.play()
                swal({
                  title: t('Alert'),
                  text: t('You have not checked in any project yet.'),
                  icon: 'warning',
                })
              }
            })
          }
        }, 2000)
      })
    }
  }, [socket])
  return <div></div>
}

export default AlertModal
