import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'antd'

import { getAccessories } from '../../../../redux'
import AccessoriesCard from '../../../../components/AccessoriesCard'

const Accessories = ({ activeTab }) => {
  const dispatch = useDispatch()
  const accessories = useSelector((state) => state.accessories.allAccessories)

  const getdata = async () => {
    await dispatch(getAccessories())
  }
  useEffect(() => {
    if (activeTab === 'accessories') {
      getdata()
    }
  }, [activeTab])

  return (
    // <Layout active={'accessories'}>

    <div className='accessories-container'>
      {/* <div className='header'>
        <AddAccessories />
      </div> */}
      <Row className='accessories'>
        {accessories?.map((item, i) => (
          <Col
            xs={24}
            md={10}
            lg={11}
            xl={6}
            key={item?._id}
            style={{
              minHeight: '220px',
              maxHeight: '220px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <AccessoriesCard data={item} />
          </Col>
        ))}
      </Row>
    </div>

    // </Layout>
  )
}

export default Accessories
