// import { Button } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import swal from 'sweetalert'
import {
  _projectCheckIn,
  addOfflineScreenshot,
  addScreenshot,
  projectCheckOut,
  startTask,
  getCheckins,
  setShiftCheckins,
} from '../redux'
import dataURItoBlob from '../service/dataURItoBlob'
import { Button, Col, Form, Input, Modal, Row, Spin, notification } from 'antd'
import { useTranslation } from 'react-i18next'
let intRun = false
const intRunChange = (val) => {
  intRun = val
  if (val == false) {
    // clearinterval
  }
}

const ProjectCheckIn = ({ videoEL, isShiftOn, setVideoEl, intervalState, selectedProject,  projects,TrackState }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [timeString, setTimeString] = useState('')

  const [currentCheckIn, setCurrentCheckIn] = useState(() => {
    // Retrieve currentCheckIn from localStorage if available
    const storedCheckInStart = localStorage.getItem('currentCheckInStartTime')

    return storedCheckInStart ? new Date(storedCheckInStart) : null
  })
  const [runningProject, setRunningProject] = useState(null);
  const [projectId, setProjectId] = useState(localStorage.getItem('project'))
  const [timer, setTimer] = useState(null)
  const [projectCheckInId, setProjectCheckInId] = useState(localStorage.getItem('projectCheckInId'))
  const [remarksModal, setRemarksModal] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const userId = localStorage.getItem('userId')
  const [startProjectLoading, setStartProjectLoading] = useState(false)
  const [shareScreenInProgress, setShareScreenInProgress] = useState(false)
  const [date, setDate] = useState({
    start: null,
    end: null,
  })
  const [form] = Form.useForm() // Initialize form

  dispatch(setShiftCheckins(localStorage.getItem('projectCheckInId')))

  //   functions

  function stopStreamedVideo() {
    if (videoEL) {
      const streamVar = videoEL.srcObject
      if (streamVar) {
        const tracks = streamVar.getTracks()

        tracks.forEach((track) => {
          track.stop()
        })
      }
      clearInterval(intervalState)
    }
  }

  useEffect(() => {
    if (intervalState && TrackState && localStorage.getItem('checkinId')) {
      TrackState.onended = async () => {
        clearInterval(intervalState)
        checkOut()
      }
    }
  }, [intervalState, TrackState])

  const handleStopCapturing = () => {
    if (window?.electronAPI?.stopCapturing) window.electronAPI.stopCapturing()
  }

  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('projectCheckInId')
  })

  // abhi isko dekhna he
  useEffect(() => {
    if (currentCheckIn && localStorage.getItem('projectCheckInId')) {
      var startDate = new Date(currentCheckIn).getTime()
      var newTimer = setInterval(() => timerFunction(startDate), 1000)
      setTimer(newTimer) // Save the new timer
      return () => {
        clearInterval(newTimer) // Clear the new timer
      }
    }
    if (!localStorage.getItem('projectCheckInId')) {
      setCurrentCheckIn(null)
      localStorage.removeItem('currentCheckInStartTime')
    }
  }, [currentCheckIn])

  // useEffect(() => {
  //   // Retrieve currentCheckIn from localStorage if available
  //   const storedCheckInStart = localStorage.getItem('currentCheckInStartTime');

  //   if (storedCheckInStart && localStorage.getItem('projectCheckInId')) {
  //     var startDate = new Date(storedCheckInStart).getTime();
  //     var newTimer = setInterval(() => timerFunction(startDate), 1000);
  //     setTimer(newTimer); // Save the new timer

  //     // Clear the timer when the component unmounts or when projectCheckInId is removed
  //     return () => {
  //       clearInterval(newTimer);
  //       setTimer(null);
  //     };
  //   } else {
  //     // Clear the timer if there's no projectCheckInId or storedCheckInStart
  //     clearInterval(timer);
  //     setTimer(null);
  //   }
  // }, [localStorage.getItem('projectCheckInId'), localStorage.getItem('currentCheckInStartTime')]);

  // useEffect(()=>{
  //   if (storedCheckInStart) {
  //     const storedCheckInStart = localStorage.getItem('currentCheckInStartTime');
  //     const startDate = new Date(storedCheckInStart);
  //     setCurrentCheckIn(startDate); // Set the currentCheckIn state

  //     dispatch({
  //       type: SET_START_TIME,
  //       payload: startDate,
  //     });
  //   }
  // },[])

  useEffect(() => {
    // Store currentCheckIn in localStorage whenever it changes
    if (currentCheckIn) {
      localStorage.setItem('currentCheckIn', currentCheckIn.getTime())
    } else {
      localStorage.removeItem('currentCheckIn')
    }
  }, [currentCheckIn])

  useEffect(() => {
    // Store currentCheckIn in localStorage whenever it changes
    localStorage.setItem('currentCheckIn', currentCheckIn)
  }, [currentCheckIn])

  const openRemarksModal = () => {
    console.log('clicked on openremark model')
    setRemarksModal(true)
  }
  const closeRemarksModal = async () => {
    await handleRemarks()
    stopStreamedVideo()
    setRemarksModal(false)
  }
  const handleRemarks = async (value) => {
    // add remarks and checkout
    setBtnLoading(true)
    await checkOut({ remarks: value?.remarks || '' })
    setRemarksModal(false)
    setBtnLoading(false)
  }

  const checkOut = async (data) => {
    localStorage.removeItem('project')
    // localStorage.removeItem('projectCheckInId');
    // console.log('checkOut')
    await sendOfflineScreenshots()
    const allDetails = await dispatch(
      projectCheckOut({
        checkIn: projectCheckInId,
        remarks: data?.remarks,
      }),
    )
    if (allDetails) {
      setProjectCheckInId('')
      setProjectId('')
    }
    stopStreamedVideo()
    setCurrentCheckIn(null)
    handleStopCapturing()
    clearInterval(timer) // Clear the timer interval
    setTimer(null) // Reset the timer state
    setVideoEl(null) // Reset the video element state
  }

  const fetchCheckins = async () => {
    if (localStorage.getItem('userType')?.toLowerCase() !== 'moderator') {
      let allDetails = null
      if (date.start && date.end) {
        allDetails = await dispatch(
          getCheckins({
            start: moment(date.start).format('YYYY-MM-DD'),
            end: moment(date.end).format('YYYY-MM-DD'),
            userType: 'employee',
          }),
        )
      } else {
        allDetails = await dispatch(getCheckins({ userType: 'employee' }))
      }
      intRunChange(false)
    }
  }

  // start shift function
  const startShiftCheckin = async () => {
    // modification required
    setTimer(null)
    try {
      setStartProjectLoading(true)
      const zone = moment.tz.guess(true)
      const allDetails = await dispatch(
        startTask(
          {
            latitude: 0,
            longitude: 0,
          },
          zone,
          userId,
        ),
      )
      fetchCheckins()
      setStartProjectLoading(false)
    } catch ({ message }) {
      console.log(message)
    }
  }

  const timerFunction = (startDate) => {
    // Get the current date and time
    // Check if project is selected
    if (!projectId) {
      return // If project is not selected, exit the function
    }
    if (!startDate) {
      startDate = new Date().getTime()
    }
    var now = new Date().getTime()

    // Find the time elapsed since the start date
    var elapsedTime = now - startDate

    // Calculate the elapsed time components
    var hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    var minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60))
    var seconds = Math.floor((elapsedTime % (1000 * 60)) / 1000)
    hours = hours.toString().padStart(2, '0')
    minutes = minutes.toString().padStart(2, '0')
    seconds = seconds.toString().padStart(2, '0')
    setTimeString(`${hours}:${minutes}:${seconds}`)
    // console.log(`${hours}:${minutes}:${seconds}`)
    // console.log(timeString)
  }

  const setCapturetoLocal = (payload) => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    // console.log("offlineScreenshots", offlineScreenshots);

    localStorage.setItem('offlineScreenshots', JSON.stringify([...offlineScreenshots, payload]))
  }

  const sendOfflineScreenshots = () => {
    let offlineScreenshots = localStorage.getItem('offlineScreenshots')
      ? JSON.parse(localStorage.getItem('offlineScreenshots'))
      : []

    if (offlineScreenshots.length > 0) {
      // .format("YYYY-MM-DD--HH-MM")

      let formData = new FormData()
      offlineScreenshots?.map((data) => {
        const blob = dataURItoBlob(data.file)

        var file = new File([blob], `${data.date}.jpg`, {
          type: 'application/octet-stream',
        })
        formData.append('screenshot', file)
      })
      formData.append('checkinId', localStorage.getItem('checkinId'))
      addOfflineScreenshot(formData)
    }
  }

  const captureImage = async ({ videoELReference, width, height, image = null }) => {
    if (!image) {
      let c = document.createElement('canvas'), // create a temp. canvas
        ctx = c.getContext('2d')
      c.width = width // set size = image, draw
      c.height = height
      ctx.drawImage(videoELReference, 0, 0)

      var imgAsDataURL = c.toDataURL('application/octet-stream')
      const blob = dataURItoBlob(imgAsDataURL)

      let payload = {
        file: imgAsDataURL,
        date: Date.now(),
      }
      fetch('https://backend.trackkrr.com', {
        method: 'GET',
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          var file = new File(
            [blob],
            `${Date.now()}.jpg`,

            {
              type: 'application/octet-stream',
            },
          )
          sendOfflineScreenshots()

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        })
        .catch(() => {
          setCapturetoLocal(payload)
        })
    } else {
      const blob = dataURItoBlob(image)
      console.log('image', image)
      let payload = {
        file: image,
        date: Date.now(),
      }

      console.log('payload.file', payload?.file?.slice(0, 10))
      fetch('https://backend.trackkrr.com', {
        method: 'GET',
        timeout: 1000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          var file = new File(
            [blob],
            `${Date.now()}.jpg`,

            {
              type: 'application/octet-stream',
            },
          )
          sendOfflineScreenshots()

          let formData = new FormData()
          formData.append('screenshot', file)
          formData.append('checkinId', localStorage.getItem('checkinId'))
          formData.append('projectCheckIn', localStorage.getItem('projectCheckInId'))
          formData.append('project', localStorage.getItem('project'))

          if (localStorage.getItem('checkinId')) {
            addScreenshot(formData, payload, setCapturetoLocal)
          }
        })
        .catch(() => {
          setCapturetoLocal(payload)
        })
    }
  }
  const displayMediaOptions = {
    video: {
      cursor: 'always',
    },
    audio: false,
  }

  console.log('current: ', currentCheckIn)

  const checkInStart = async () => {
    console.log('Start button clicked...')
    const checkinId = localStorage.getItem('checkinId')
    const selectedProject = localStorage.getItem('project')
    if (!checkinId) {
      swal('Error!', 'Please start shift checkin first!', 'error')
      return
    }
    try {
      if (!window?.electronAPI?.stopCapturing) {
        setShareScreenInProgress(true)
        let mediaStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
        let track = mediaStream.getVideoTracks()[0]
        let { width, height } = track.getSettings()
        if (track.label.includes('screen')) {
          dispatch(_projectCheckIn({ project: selectedProject, shiftCheckIn: checkinId }))
          let videoELReference = document.createElement('video')
          videoELReference.srcObject = mediaStream
          videoELReference.play()
          setTimer(null) // Reset timer

          setVideoEl(videoELReference)

          clearInterval(timer) // Stop the timer
          let newTimer = setInterval(() => {
            captureImage({
              videoELReference,
              width,
              height,
            })
            setTimer(newTimer) // Set interval as timer
            timerFunction(new Date().getTime())
          }, 180000)

          dispatch({
            type: 'SET_SCREEN_INTERVAL',
            payload: newTimer,
          })

          setCurrentCheckIn(new Date())
          localStorage.setItem('currentCheckInStartTime', new Date())
          setRunningProject(selectedProject); 
        }
      } else {
        dispatch(_projectCheckIn({ project: selectedProject, shiftCheckIn: checkinId }))
        setProjectCheckInId(checkinId)
        setRunningProject(selectedProject); 
        setProjectId(selectedProject)
        let obj = {
          checkinId: localStorage.getItem('checkinId'),
          projectCheckIn: localStorage.getItem('projectCheckInId'),
          project: localStorage.getItem('project'),
        }
        window.electronAPI.startCapturing(obj)
      }
    } catch (err) {
      console.error(`Error: ${err}`)

      clearInterval(timer)
      setTimer(null)
    } finally {
      setShareScreenInProgress(false)
    }
  }
  // useEffect(() => {
  //   if(currentCheckIn){

  //       var startDate = new Date(currentCheckIn).getTime()
  //       var timer = setInterval(() => timerFunction(startDate), 1000)
  //       console.log(timer)
  //       return () => {
  //           clearInterval(timer)
  //       }
  //   }
  // }, [currentCheckIn])

  return (
    <div>
      <div>
        {!shareScreenInProgress && (!timeString || timeString === '00:00:00') ? (
          <Button
            className='ant-btn css-dev-only-do-not-override-1bw12bw ant-btn-primary'
            onClick={async () => {
              await startShiftCheckin()
              await checkInStart()
            }}
            disabled={startProjectLoading}
          >
            {startProjectLoading ? 'Starting...' : 'Start Project'}
          </Button>
        ) : (
          // <Button onClick={openRemarksModal} style={{ fontSize:'20px'}}> {timeString}</Button>
          <Button
            className='ant-btn css-dev-only-do-not-override-1bw12bw ant-btn-primary'
            onClick={openRemarksModal}
            style={{ fontSize: '20px' }}
          >
            {' '}
            {selectedProject}
            {' '}
            {timeString}
          </Button>
        )}
      </div>

      <div>
        <Modal
          title={t('add-remarks')}
          open={remarksModal}
          footer={false}
          onCancel={closeRemarksModal}
          width={'600px'}
        >
          <Form
            requiredMark={false}
            layout='vertical'
            form={form}
            onFinish={handleRemarks}
            // fields={[
            //   {
            //     name: 'remarks',
            //     value: '',
            //   },
            // ]}
          >
            <Row gutter={[20, 0]}>
              <Col xs={24}>
                <Form.Item
                  label={t('remarks')}
                  name={'remarks'}
                  rules={[{ required: true, message: 'Please input remarks!' }]}
                >
                  <Input.TextArea rows={4} style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button htmlType='submit' loading={btnLoading} type='primary'>
                {t('add-remarks')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  )
}

export default ProjectCheckIn
