import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { trackrr } from './trackkrrLogoBase64'
import { hoursToString } from '../../functions/timeFormatter'
import { extraCostCalculate, extraTimeCalculate } from '../../functions/extraTimeCalculate'

const EmployeeSalaryReportPdf = (employee, month, pdfname) => {
  // calculations

  //     employeeData?.timeline,
  // range,
  // employeeData?.employee?.name,
  // employeeData?.totalHours,
  // Number(employeeData?.totalCost)?.toFixed(0),
  // employeeData?.employee?.name + '.pdf',
  // employeeData?.expense,

  var doc = new jsPDF()

  var footerY = doc.internal.pageSize.getHeight() - 8

  doc.addImage(trackrr, 'PNG', 120, 15, 80, 10)

  //   doc.setTextColor('#F55139')
  doc.setFontSize(16)

  doc.text(`Detailed Report`, 10, 20)

  doc.setFontSize(11)

  doc.setTextColor('#535c68')
  // DURATION ROW 1
  doc.text(
    10,
    28,
    `${moment(new Date(month[0]))?.format('DD/MM/yyyy')} - ${moment(new Date(month[1]))?.format(
      'DD/MM/yyyy',
    )}`,
  )

  doc.setTextColor('#000000')

  // COMPANY ROW 2
  // let width = doc.getTextWidth(`${localStorage.getItem('company')} `) + 10 + 20
  // doc.text(10, 35, `${localStorage.getItem('company')} `)

  // TOTAL HOURS ROW 3

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(10, 35, 'Employee:')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(40, 35, `${employee?.employee?.name}`)

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(10, 45, 'Salary : ')

  doc.setTextColor('#000000')
  doc.setFontSize(13)
  doc.text(25, 45, `${employee?.employee?.salary} PKR `)

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(55, 45, 'Total : ')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(70, 45, `${hoursToString(employee?.totalHours)}`)

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(100, 45, 'Cost: ')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(
    120,
    45,
    `${Number((employee?.perHourExpenseRate * employee?.totalHours)?.toFixed(0))} PKR`,
  )

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(10, 55, 'Overtime : ')

  doc.setTextColor('#000000')
  doc.setFontSize(13)
  doc.text(
    30,
    55,
    `${Number(
      extraTimeCalculate(
        employee?.totalHours,
        employee?.employee?.daysPerMonth * employee.employee?.noOfHours,
      ),
    ).toFixed(2)} PKR `,
  )

  doc.setFontSize(11)
  doc.setTextColor('#535c68')
  doc.text(55, 55, 'Extra Cost : ')

  doc.setFontSize(13)
  doc.setTextColor('#000000')
  doc.text(
    85,
    55,
    `${Number(
      extraCostCalculate(
        employee?.totalHours,
        employee?.employee?.daysPerMonth * employee.employee?.noOfHours,
        employee?.employee?.hourlySalary,
      ),
    ).toFixed(2)} PKR`,
  )
  doc.setFontSize(11)

  // var tHeaders = ['Date', 'Description', 'Duration', 'User']
  // var tData = []

  //   doc.setDrawColor(128)
  //   doc.setTextColor('#535c68')
  //   doc.text(10, 65, 'Date')
  //   doc.text(40, 65, 'Description')
  //   doc.text(120, 65, 'Duration')
  //   doc.text(160, 65, 'User')
  //   doc.line(10, 70, 200, 70) // horizontal line

  //   doc.setFontSize(11)
  //   let height = 78
  //   projectData.map((data) => {
  //     checkPageBreak(height)
  //     var maxWidth = 70
  //     doc.setTextColor('#000000')
  //     let remarks = `${data?.remarks}`?.replace(/\n/g, ' ')
  //     const lines = doc.splitTextToSize(remarks, maxWidth)
  //     const totalHeight = lines.length * 11

  //     let heightToAdd = lines.length > 1 ? totalHeight - 10 : 6

  //     doc.text(10, height, moment(data?.CreatedAt).local().format('DD-MM-YYYY'))
  //     doc.text(40, height, lines) // .replace(/\n/g, ' ')
  //     doc.text(120, height, hoursToString(data?.hours))
  //     doc.text(160, height, `${data?.user?.name}`)

  //     doc.setTextColor('#535c68')
  //     doc.text(40, height + heightToAdd, `${data?.project?.title}`)
  //     // doc.text(120, height + 6, '00:12:00')
  //     doc.text(
  //       160,
  //       height + heightToAdd,
  //       `${Number(data?.user?.hourlySalary * data?.hours).toFixed(2)} PKR`,
  //     )
  //     doc.line(10, height + heightToAdd + 3, 200, height + heightToAdd + 3) // horizontal line
  //     height += heightToAdd + 3 + 8
  //   })

  //   function checkPageBreak(yPosition) {
  //     const pageHeight = doc.internal.pageSize.height
  //     if (yPosition > pageHeight - 20) {
  //       doc.addPage() // Add a new page
  //       height = 20 // Reset the Y-coordinate to start from the top
  //     }
  //   }

  doc.text(120, footerY, `${localStorage.getItem('company')} - Created with TRACKKRR`)

  return doc.save(`${pdfname}`)
}

export default EmployeeSalaryReportPdf
