import React from 'react'

import { Button, Input, Modal, Table } from 'antd'

import { t } from 'i18next'

import { BsDownload } from 'react-icons/bs'

import Papa from 'papaparse'

import { addTimelineCsv } from '../../redux'

const AddRemarksCsv = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [timeline, setTimeline] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  const handleCancel = () => setIsModalVisible(false)

  const handleAddRemarks = async () => {
    if (!timeline) return
    setLoading(true)
    Papa.parse(timeline, {
      header: true,
      complete: async function (results) {
        const data = []
        results?.data?.forEach((v) => {
          if (v?.Date) {
            data.push({
              ...v,
              Date: new Date(v?.Date),
            })
          }
        })
        const payload = {
          data: data,
          userId: localStorage.getItem('userId'),
        }
        const res = await addTimelineCsv(payload)
        setLoading(false)
        if (res) {
          setIsModalVisible(false)
          setTimeline(null)
        }
      },
      error: function (err) {
        // console.log(err)
      },
    })
  }
  return (
    <div>
      <Button
        type='primary'
        onClick={() => {
          setIsModalVisible(true)
        }}
      >
        Add Timeline from csv
      </Button>
      <Modal
        title={t('add-remarks')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <div className='remarksCSVModal'>
          <div className='remarksCSVModalSample'>
            <p>
              Note: <span>The CSV should be according to the format provided!</span>
            </p>
            <a download='sample.csv' href='/sample.csv' target='_blank' rel='noopener noreferrer'>
              {' '}
              <BsDownload size={25} />
              Download Sample CSV
            </a>
          </div>

          <div>
            <Table
              columns={[
                {
                  title: 'S.No',
                  dataIndex: 'sno',
                  key: 'sno',
                },
                {
                  title: 'Date',
                  dataIndex: 'date',
                  key: 'date',
                },
                {
                  title: 'Project',
                  dataIndex: 'project',
                  key: 'project',
                },
                {
                  title: 'Hours',
                  dataIndex: 'hours',
                  key: 'hours',
                },
                {
                  title: 'Remarks',
                  dataIndex: 'remarks',
                  key: 'remarks',
                },
              ]}
              dataSource={[
                {
                  sno: 1,
                  date: '01-02-2023',
                  project: 'Project 1',
                  hours: 8,
                  remarks: 'Remarks 1',
                },
                {
                  sno: 2,
                  date: '02-02-2023',
                  project: 'Project 2',
                  hours: 8,
                  remarks: 'Remarks 2',
                },
                {
                  sno: 3,
                  date: '03-02-2023',
                  project: 'Project 3',
                  hours: 8,
                  remarks: 'Remarks 3',
                },
              ]}
              pagination={false}
            />
          </div>

          <div className='remarksCSVModalSubmit'>
            <p>Upload CSV</p>
            {/* <label htmlFor='file-input' className='custom-button'>
              Choose file
            </label> */}
            <Input
              type='file'
              accept='.csv'
              onChange={(e) => setTimeline(e.target.files[0])}
              //   className='ant-col-24'
            />
            <Button type='primary' onClick={handleAddRemarks} loading={loading}>
              Add Timeline
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AddRemarksCsv
