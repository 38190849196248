import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Input, Row, Col, Button, notification } from 'antd'
import { useTranslation } from 'react-i18next'

import InfinityLoader from '../components/InfinityLoader'
import TodoComp from '../components/TodoComp'
import Layout from '../layout/Layout'
import { addTodo, getUserTodos } from '../redux'

const { TextArea } = Input

const Todos = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [title, setTitle] = useState('')
  const [desc, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const allTodos = useSelector((state) => state.todo.userTodos)

  useEffect(() => {
    // (async () => {
    dispatch(getUserTodos())
    // })()
  }, [])

  const addTodoItem = async () => {
    if (title.trim() && desc.trim()) {
      setLoading(true)
      let payload = {
        title,
        description: desc,
      }
      await dispatch(addTodo(payload))
      setTitle('')
      setDescription('')
      setLoading(false)
    } else {
      notification.warning({
        message: 'Please add title and description both',
        duration: 5,
      })
    }
  }

  return (
    <Layout active={'Todos'}>
      {allTodos === null ? (
        <InfinityLoader />
      ) : (
        <div className='todo-main'>
          <Typography.Title level={4}>{t('employee-todos')}</Typography.Title>

          <div className='todo-input'>
            <Row gutter={[20, 20]}>
              <Col xs={24} md={14}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                  <Input
                    value={title}
                    placeholder={t('add-title')}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                  />
                  <Button
                    style={{ height: '38px' }}
                    type='primary'
                    loading={loading}
                    onClick={addTodoItem}
                  >
                    {t('add-todo')}
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={14}>
                <TextArea
                  value={desc}
                  rows={4}
                  placeholder={t('write-note')}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                />
                {/* <Input
                  placeholder={t('write-note')}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                /> */}
              </Col>
            </Row>
          </div>
          <TodoComp allTodos={allTodos} loading={loading} />
        </div>
      )}
    </Layout>
  )
}

export default Todos
