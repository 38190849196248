import { notification } from 'antd'
import swal from 'sweetalert'
import privateAPI, { attachToken } from '../../config/constants'

export const getAllBirthdays = (userType) => {
  return async () => {
    try {
      attachToken()
      let res
      if (userType === 'employee') {
        res = await privateAPI.get(`/annoucement/get-all-birthdays-employee`)
      } else {
        res = await privateAPI.get(`/annoucement/get-all-birthdays`)
      }
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const createNotice = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/annoucement/create-notice`, payload)
      if (res) {
        notification.success({
          description: res?.data?.message,
          duration: 3,
        })
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllNotice = (userType) => {
  return async () => {
    try {
      attachToken()
      let res
      if (userType === 'employee') {
        res = await privateAPI.get(`/annoucement/get-all-notice-employee`)
      } else {
        res = await privateAPI.get(`/annoucement/get-all-notice`)
      }
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const deleteNotice = (id) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.delete(`/annoucement/delete-notice/${id}`)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}
