import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const AdminRoutes = ({ component: Component, ...rest }) => {
  var id = localStorage.getItem('token')
  var userType = localStorage.getItem('userType')
  return (
    <Route
      {...rest}
      render={(props) =>
        id && localStorage.getItem('token') && userType === 'admin' ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  )
}

export default AdminRoutes
