import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BiArrowBack } from 'react-icons/bi'
import { BsCircleFill } from 'react-icons/bs'

import { getEmployeeHistory } from '../../../../redux'
import moment from 'moment'
import InfinityLoader from '../../../../components/InfinityLoader'

const HistoryView = ({ selectedEmployeeId, setActiveScreen, setSelectedEmployeeId }) => {
  const dispatch = useDispatch()
  const [history, setHistory] = useState(null)

  const getAllDocs = async () => {
    try {
      const result = await dispatch(getEmployeeHistory(selectedEmployeeId))
      if (result) {
        setHistory(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }
  //   console.log('docs', docs)

  useEffect(() => {
    getAllDocs()
  }, [selectedEmployeeId])

  return (
    <div className='history-container'>
      <BiArrowBack
        onClick={() => {
          setActiveScreen('table')
          setSelectedEmployeeId(null)
        }}
        className='backArrow'
      />
      {history ? (
        <div className='main'>
          {history?.employeeJoiningDate && (
            <div className='timeline'>
              <div className='content'>
                <p>
                  {' '}
                  Employee Joined on {moment(history?.employeeJoiningDate).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
          )}
          {history?.history?.map((his, ind) => (
            <div key={ind} className='timeline'>
              <div className='tail'></div>
              <div className='date'>{moment(his?.createdAt).format('DD-MM-YYYY')}</div>
              <div className='content'>
                {his?.history?.map((h, i) => (
                  <p key={i}>
                    {' '}
                    <BsCircleFill className='icon' /> {h}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ height: '60vh' }}>
          <InfinityLoader />
        </div>
      )}
    </div>
  )
}

export default HistoryView
