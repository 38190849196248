import { Table, Button, Typography } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import EmployeeCostingPdf from './PdfTemplate/EmployeeCostingPdf'
import { hoursToString } from '../functions/timeFormatter'
import EmployeeProjectReportPdf from './PdfTemplate/EmployeeProjectReportPdf'

const ReportTableAllProjects = ({ Data, range }) => {
  const [laoding, setLoading] = useState(false)

  // console.log('Data',Data)
  const ExpandedReportRender = (Record) => {
    let DatatoMap = Data[Record?.projectName]?.timeline
    let EmployeeTimeline = DatatoMap?.filter((timeline) => {
      return timeline?.user?._id === Record?.employeeId
    })

    const columns = [
      {
        title: 'Date',
        dataIndex: 'Pdate',
        key: 'Pdate',
      },
      {
        title: 'Hours',
        dataIndex: 'Phours',
        key: 'Phours',
        // render: (text) => {
        //   console.log('text', text)
        //   return text
        // },
      },
      {
        title: 'Cost',
        dataIndex: 'Pcost',
        key: 'Pcost',
      },
    ]

    return (
      <Table
        columns={columns}
        dataSource={EmployeeTimeline?.map((data) => ({
          key: data?._id,
          Pdate: moment(data?.CreatedAt).format('DD/MMM/YYYY'),
          Phours: hoursToString(data?.hours),
          Pcost: 'Rs. ' + Number(data?.cost)?.toFixed(0)?.toLocaleString(),
        }))}
        pagination={false}
      />
    )
  }

  const expandedRowRender = (Record) => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
    ]

    return (
      <Table
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: ExpandedReportRender,
          // onExpand: (expand, record) => {
          // setSummaryType({ employee: record?._id, project: Record.name });
          // },
          // rowExpandable: (record) => {
          //   return !summaryType || record.key === summaryType.name
          //     ? true
          //     : false;
          // },
        }}
        dataSource={Record?.employees?.map((data, ind) => ({
          key: ind,
          name: data?.name,
          email: data?.email,
          _id: data?._id,
          employeeId: data?._id,
          projectName: Record.name,
        }))}
        pagination={false}
      />
    )
  }

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Resource Cost',
      dataIndex: 'projectCost',
      key: 'projectCost',
    },
    {
      title: 'Total Cost',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'Total Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: '',
      dataIndex: 'hours',
      key: 'hours',
      render: (text, record) => {
        return (
          <Button
            onClick={() => downloadSingleProjectPdf(record?.project)}
            loading={laoding}
            type='primary'
            size='small'
            style={
              {
                // display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
                // margin: '0px 0 40px auto',
              }
            }
          >
            Download PDF
          </Button>
        )
      },
    },
  ]

  const downloadPdf = async () => {
    setLoading(true)
    await Object.entries(Data)?.map(([name, data]) => {
      EmployeeProjectReportPdf(data?.timeline, range, data?.totalHours, name + '.pdf')
      // EmployeeCostingPdf(
      //   data?.timeline,
      //   range,
      //   data?.timeline?.[0]?.user || data.employee,
      //   data?.totalHours,
      //   Number(data?.totalCost)?.toFixed(0),
      //   name + '.pdf',
      //   // result.expense,
      //   data?.expensePerEmployee,
      //   data?.projectWiseData,
      //   data.perHourExpenseRate,
      // )
    })
    setLoading(false)
  }

  const downloadSingleProjectPdf = async (id) => {
    let project
    await Object.entries(Data)?.map(([name, data]) => {
      if (data?.timeline[0]?.project?._id === id) return (project = data)
    })

    EmployeeProjectReportPdf(
      project?.timeline,
      range,
      project?.totalHours,
      project?.timeline[0]?.project?.title + '.pdf',
    )
  }

  return (
    <>
      <Typography.Title
        level={3}
        style={{ color: 'var(--text)', textAlign: 'center', marginTop: '40px' }}
      >
        All Project Report
      </Typography.Title>
      <Typography.Title
        level={6}
        style={{ color: 'var(--text)', textAlign: 'center', marginTop: '5px' }}
      >
        {moment(new Date(range[0])).format('DD/MM/YYYY')}-
        {moment(new Date(range[1])).format('DD/MM/YYYY')}
      </Typography.Title>
      <Button
        onClick={downloadPdf}
        loading={laoding}
        type='primary'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0px 0 40px auto',
        }}
      >
        Download PDF
      </Button>
      <Table
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender,
          //   defaultExpandedRowKeys: ["0"],
        }}
        dataSource={Object.entries(Data)?.map(([name, data], ind) => ({
          key: ind,
          name,
          project: data?.timeline[0]?.project?._id,
          projectCost: 'Rs. ' + Number(data?.projectCost)?.toLocaleString(),
          cost: 'Rs. ' + Number(data.totalCost?.toFixed(0))?.toLocaleString(),
          hours: hoursToString(data.totalHours),
          employees: data?.employees,
        }))}
        pagination={false}
      />
    </>
  )
}

export default ReportTableAllProjects
