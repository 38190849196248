import swal from 'sweetalert'
import privateAPI, { attachToken } from '../../config/constants'

export const getAttendanceData = async (deptFilter, desgFilter, page) => {
  try {
    attachToken()
    let res = await privateAPI.post(`/attendance/get-attendance?page=${page}`, {
      deptFilter,
      desgFilter,
    })
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getCustomAttendanceData = async (employee, dateRange) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/attendance/get-custom-attendance`, {
      employee,
      dateRange,
    })
    if (res) {
      return res.data
    }
  } catch (err) {
    // console.log(err);
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
}

export const createLeaveRequest = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/attendance/leave-request`, payload)
      // console.log('res',res.data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllEmployeeLeaves = () => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/attendance/get-employee-leaves`)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllLeaveRequests = () => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/attendance/get-all-leave-requests`)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateLeaveRequestStatus = (id, decision) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/attendance/update-leave-request-status/${id}`, {
        status: decision,
      })
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllLeaves = () => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/attendance/get-employee-leaves`)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getCalendarLeavesData = () => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/attendance/get-calendar-leave-requests`)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}
