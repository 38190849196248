import { useState } from 'react'
import { Modal, Button, Form, Input, Row, Col, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { createExpenses, updateExpenses } from '../../redux'
import { TbEdit } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const AddModerator = ({ newexpense, data }) => {
  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async (values) => {
    setLoading(true)
    if (newexpense) {
      await dispatch(createExpenses(values))
    } else {
      await dispatch(
        updateExpenses({
          ...values,
          id: data._id,
        }),
      )
    }
    setLoading(false)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {newexpense ? (
        <Button type='primary' className='addModeratorBtn' onClick={showModal}>
          {t('add')}
        </Button>
      ) : (
        <TbEdit className='editBtn' onClick={showModal} />
      )}
      <Modal
        title={newexpense ? t('new-expense') : t('update-expense')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form
          layout='vertical'
          fields={
            data
              ? [
                  {
                    name: 'amount',
                    value: data.amount,
                  },
                  {
                    name: 'description',
                    value: data.description,
                  },
                ]
              : null
          }
          onFinish={onFinish}
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label={t('date')}
                name={'date'}
                // rules={[
                //   {
                //     required: true,
                //     message: t("amount-required"),
                //   },
                // ]}
                // requiredMark={"optional"}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={t('select-date')}
                  // defaultValue={moment(data?.joiningDate).local()}
                  format={'DD/MM/YYYY'}
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                  // onChange={onDateFilter}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('amount')}
                name={'amount'}
                rules={[
                  {
                    required: true,
                    message: t('amount-required'),
                  },
                ]}
                requiredMark={'optional'}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={t('expense-description')} name={'description'}>
                <Input.TextArea rows={2} style={{ resize: 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {newexpense ? t('add-expense') : t('update-expense')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddModerator
