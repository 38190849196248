import { useState } from 'react'
import { Modal, Button, Form, Input, Row, Col, DatePicker, Typography } from 'antd'
import { useDispatch } from 'react-redux'
import { createRemarks, getUserProject, updateRemarks } from '../../redux'
import { MdOutlineFileUpload, MdEdit } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import dayjs from 'dayjs'

const AddRemarks = ({ id, state, getData }) => {
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dateError, setDateError] = useState(false)
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async (values) => {
    setLoading(true)
    // Check Date is already exist or not
    // need api to check only date isExist or not
    // let isDateExist = undefined
    // if (state?.CreatedAt != form.getFieldValue('CreatedAt').toISOString()) {
    //   const data = await dispatch(
    //     getUserProject({
    //       id: state ? state?.project : id,
    //       users: null,
    //       date: null,
    //       keyword: null,
    //     }),
    //   )
    //   isDateExist = data?.timeline?.find(
    //     (v) =>
    //       moment(v?.CreatedAt).format('MMM Do YY') ==
    //       moment(values?.CreatedAt?.toISOString()).format('MMM Do YY'),
    //   )
    // }
    // --------------------------------------------------------------
    // if (isDateExist) {
    //   setDateError(true)
    //   setLoading(false)
    //   return
    // } else {
    let payload = {
      ...values,
      hours: Number(values.hours),
      project: id,
      CreatedAt: values.CreatedAt ? values.CreatedAt.toISOString() : moment().toISOString(),
    }
    let updatePayload = {
      ...payload,
      timelineId: state?._id,
      hourlySalary: state?.user?.hourlySalary,
    }
    const res = state
      ? await dispatch(updateRemarks(updatePayload))
      : await dispatch(createRemarks(payload))
    if (res) {
      setLoading(false)
      setIsModalVisible(false)
      form.resetFields()
      state && getData()
    }
    // }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setDateError(false)
    form.resetFields()
  }

  const onDateFilter = () => {
    setDateError(false)
  }

  const validateNumber = (_, value) => {
    if (value > 24) {
      return Promise.reject(new Error('Value must not exceed 24'))
    } else if (!value) {
      return Promise.reject(new Error('Please input hours!'))
    }
    return Promise.resolve()
  }

  return (
    <>
      {!state ? (
        <h4 onClick={showModal}>
          <MdOutlineFileUpload className='icon' /> {t('add-timeline')}
        </h4>
      ) : (
        <MdEdit className='edit icon' onClick={showModal} />
      )}
      <Modal
        title={t('add-remarks')}
        open={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form
          requiredMark={false}
          layout='vertical'
          form={form}
          onFinish={onFinish}
          fields={
            state
              ? [
                  {
                    name: 'CreatedAt',
                    value: form.getFieldValue('CreatedAt') || dayjs(state.CreatedAt),
                  },
                  {
                    name: 'hours',
                    value: state?.hours,
                  },
                  {
                    name: 'remarks',
                    value: state?.remarks,
                  },
                ]
              : []
          }
        >
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label={t('date')}
                name={'CreatedAt'}
                rules={[{ required: true, message: 'Please select date!' }]}
                requiredMark={false}
              >
                {state ? (
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={t('select-date')}
                    onChange={onDateFilter}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                    }}
                  />
                ) : (
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={t('select-date')}
                    onChange={onDateFilter}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                    }}
                  />
                )}
              </Form.Item>
              {dateError && (
                <Typography
                  style={{
                    color: '#ff4d4f',
                    fontSize: '14px',
                    marginTop: '-20px',
                  }}
                >
                  {t('date-already-exists')}
                </Typography>
              )}
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('hours')}
                name={'hours'}
                rules={[{ required: true, validator: validateNumber }]}
                // requiredMark={false}
              >
                <Input type={'number'} maxLength={2} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('remarks')}
                name={'remarks'}
                rules={[{ required: true, message: 'Please input remarks!' }]}
              >
                <Input.TextArea rows={4} style={{ resize: 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {state ? t('update-remarks') : t('add-remarks')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AddRemarks
