import { Button, Col, DatePicker, Divider, Form, Row, Select, Typography, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import EmployeeCostingPdf from '../components/PdfTemplate/EmployeeCostingPdf'
import ProjectResourceTable from '../components/ProjectResourceTable'
import ReportTableAllEmployees from '../components/ReportTableAllEmployees'
import ReportTableAllProjects from '../components/ReportTableAllProjects'
import Layout from '../layout/Layout'
import {
  getAllProjects,
  getByResource,
  getEmployeesName,
  getReportsAllEmployees,
  getReportsAllProjects,
} from '../redux'
import { getEmployeesByProject, getProjectsByEmployee } from '../redux/actions/report'
import moment from 'moment'
import EmployeeProjectReportPdf from '../components/PdfTemplate/EmployeeProjectReportPdf'

const ProjectDetail = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const employees = useSelector((state) => state.employees.employeesName)
  const projects = useSelector((state) => state.project.projects)
  const projEmployees = useSelector((state) => state.report.employees)
  const empProjects = useSelector((state) => state.report.projects)

  const [loader1, setLoader1] = useState(false)
  const [loader2, setLoader2] = useState(false)
  const [loader3, setLoader3] = useState(false)
  const [selected, setSelected] = useState('')
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [firstTable, setFirstTable] = useState(null)
  // const [secondTable, setSecondTable] = useState(null)
  // const [thirdTable, setThirdTable] = useState(null)
  // const [firstTableCondition, setFirstTableCondition] = useState()
  // const [selectedProjectName, setSelectedProjectName] = useState('')
  // const [firstStartDate, setFirstStartDate] = useState(null)
  // const [firstEndDate, setFirstEndDate] = useState(null)
  // const [pdfLink, setPDFLink] = useState()
  const [projectTable, setprojectTable] = useState(null)
  const [AllProjectReportTable, setAllProjectReportTable] = useState(null)

  useEffect(() => {
    dispatch(getEmployeesName())
    dispatch(getAllProjects())
  }, [])

  const onFinish = async (values) => {
    if (values.project === 'default' && values.user === 'default') {
      return notification.error({
        message: 'Select atleast one resource...',
        duration: 5,
      })
    }
    if (values.project || values.user) {
      setLoader1(true)
      let payload

      if (values.date) {
        //   // start = moment(values.date[0]?._d).format("YYYY-MM-DD");
        //   // end = moment(values.date[1]?._d).format("YYYY-MM-DD");
        //   if (values.date === 'currentMonth') {
        //     range = 'Current Month'
        //   } else if (values.date === 'lastMonth') {
        //     range = 'Last Month'
        //   } else if (values.date === 'last3Months') {
        //     range = 'Last 3 Months'
        //   } else if (values.date === 'last6Months') {
        //     range = 'Last 6 Months'
        //   }
      }
      // console.log(range)
      payload = {
        project: values.project,
        user: values.user,
      }
      if (values?.date) payload['date'] = values?.date

      const result = await getByResource(payload)
      if (values.user || values.project) {
        if (result?.data?.timeline?.length > 0 || result?.data?.empCount > 0) {
          let timeline = []
          setprojectTable({ payload, result, selectedProject, selectedEmployee })

          if (values?.user)
            timeline = result.data.timeline?.filter((value) => value?.user?._id === values?.user)
          else timeline = result.data.timeline

          console.log({
            timeline,
            date: values?.date,
            timelineHours: result.data.timelineHours,
          })
          EmployeeProjectReportPdf(
            timeline,
            values?.date,
            result?.data?.timelineHours,
            `${t('project-report')}.pdf`,
          )
        } else {
          notification.error({
            message: t('no-record-found'),
            duration: 5,
          })
        }
      }
      setLoader1(false)
    } else {
      notification.warning({
        message: 'Select atleast Resource or Project',
        duration: 5,
      })
    }
  }

  const onFinish2 = async (values) => {
    setLoader2(true)
    // let start;
    // let end;
    let range
    let payload = {}
    if (values.date) {
      payload['date'] = values?.date
      // start = moment(values.date[0]?._d).format("YYYY-MM-DD");
      // end = moment(values.date[1]?._d).format("YYYY-MM-DD");
      // if (values.date === 'currentMonth') {
      //   range = 'Current Month'
      // } else if (values.date === 'lastMonth') {
      //   range = 'Last Month'
      // } else if (values.date === 'last3Months') {
      //   range = 'Last 3 Months'
      // } else if (values.date === 'last6Months') {
      //   range = 'Last 6 Months'
      // }
    }

    const result = await getReportsAllProjects(payload)
    // console.log("project result", result);
    // setSecondTable(result);
    setAllProjectReportTable({ data: result?.result, range: values.date })
    // Object.entries(result?.result)?.map(([name, data]) => {
    //   ProjectCostingPdf(
    //     data?.timeline,
    //     name,
    //     name,
    //     data?.totalHours,
    //     Number(data?.totalCost)?.toFixed(0),
    //     name + ".pdf",
    //     result.expense
    //   );
    // });
    setLoader2(false)
    form2.resetFields()
  }

  const onFinish3 = async (values) => {
    // getReportsAllEmployees

    setLoader3(true)
    let payload = {}
    if (values?.date) payload.date = values.date
    const result = await getReportsAllEmployees(payload)
    setFirstTable({ data: result, range: values.date })

    // await Object.entries(result)?.map(([name, data]) => {
    //   setTimeout(() => {
    //     EmployeeCostingPdf(
    //       data?.timeline,
    //       data?.timeline?.[0]?.user || data.employee,
    //       data?.totalHours,
    //       Number(data?.totalCost)?.toFixed(0),
    //       name + ".pdf",
    //       // result.expense,
    //       data?.expensePerEmployee,
    //       data?.projectWiseData,
    //       data.perHourExpenseRate
    //     );
    //   }, 2000);
    // });

    setLoader3(false)
    form3.resetFields()
  }

  const onProjectChange = (e) => {
    if (e === 'default' || !e) {
      setSelected('')
      setSelectedProject(null)
    } else {
      setSelectedProject(e)
      dispatch(getEmployeesByProject(e))
      setSelected('project')
    }
  }
  const onEmployeeChange = (e) => {
    if (e === 'default' || !e) {
      setSelected('')
      setSelectedEmployee(null)
    } else {
      setSelectedEmployee(e)
      dispatch(getProjectsByEmployee(e))
      setSelected('employee')
    }
  }

  return (
    <Layout active={'admin-report'}>
      {/* <InfinityLoader /> */}
      <div className='reports-main'>
        {/* <PdfModal pdfModalOpen={pdfModalOpen} setPDFModal={setPDFModal} /> */}
        <Typography.Title level={1}>{t(`generate-report-of-project-by-resource`)}</Typography.Title>
        {/* <Divider /> */}
        <Form
          layout='vertical'
          onFinish={onFinish}
          form={form}
          fields={[
            {
              name: 'project',
              value: selectedProject,
            },
            {
              name: 'user',
              value: selectedEmployee,
            },
          ]}
        >
          <Row gutter={[20, 20]} align='bottom'>
            <Col xs={24} md={12} lg={6}>
              <Form.Item name='project' label={t('project')}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  placeholder={t('select-project')}
                  onChange={onProjectChange}
                  value={selectedProject}
                >
                  {selected === 'project' && (
                    <Select.Option value={'default'}>{t('default')}</Select.Option>
                  )}

                  {selected === ''
                    ? projects?.map((project, ind) => (
                        <Select key={ind} value={project._id}>
                          {project.title}
                        </Select>
                      ))
                    : selected === 'employee'
                    ? empProjects?.map((project, ind) => (
                        <Select.Option key={ind} value={project._id}>
                          {project.title}
                        </Select.Option>
                      ))
                    : selected === 'project'
                    ? projects?.map((project, ind) => (
                        <Select.Option key={ind} value={project._id}>
                          {project.title}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item name='user' label={t('resource')}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp='children'
                  placeholder={t('select-resource')}
                  value={selectedEmployee}
                  onChange={onEmployeeChange}
                >
                  {selected === 'employee' && (
                    <Select.Option value={'default'}>{t('default')}</Select.Option>
                  )}
                  {selected === ''
                    ? employees?.employee?.map((employee, ind) => (
                        <Select.Option key={ind} value={employee._id}>
                          {employee.name}
                        </Select.Option>
                      ))
                    : selected === 'project'
                    ? projEmployees?.assignedTo?.map((employee, ind) => (
                        <Select.Option key={ind} value={employee._id}>
                          {employee.name}
                        </Select.Option>
                      ))
                    : selected === 'employee'
                    ? employees?.employee?.map((employee, ind) => (
                        <Select.Option key={ind} value={employee._id}>
                          {employee.name}
                        </Select.Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name='date'
                label={'Duration'}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker.RangePicker
                  allowClear
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
                {/* <Select placeholder={'Select Range'}>
                  <Select.Option value='currentMonth'>Current Month</Select.Option>
                  <Select.Option value='lastMonth'>Last Month</Select.Option>
                  <Select.Option value='previous3Months'>Last 3 Months</Select.Option>
                  <Select.Option value='previous6Months'>Last 6 Months</Select.Option>
                </Select> */}
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={loader1}>
                  {t('generate-report')}
                </Button>
              </Form.Item>
            </Col>
          </Row>

          {projectTable && <ProjectResourceTable projectTable={projectTable} />}
          <Divider />
        </Form>

        {/* one end */}

        <Typography.Title level={1} className='mt-7'>
          {t('generate-report-of-all-projects-between-dates')}
        </Typography.Title>
        {/* <Divider /> */}

        <Form layout='vertical' onFinish={onFinish2} form={form2}>
          <Row gutter={[20, 20]} align='bottom'>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name='date'
                label={'Range'}
                rules={[{ required: true, message: 'Please select range' }]}
              >
                <DatePicker.RangePicker
                  allowClear
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
                {/* <Select placeholder={'Select Range'}>
                  <Select.Option value='currentMonth'>Current Month</Select.Option>
                  <Select.Option value='lastMonth'>Last Month</Select.Option>
                  <Select.Option value='previous3Months'>Last 3 Months</Select.Option>
                  <Select.Option value='previous6Months'>Last 6 Months</Select.Option>
                </Select> */}
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={loader2}>
                  {t('generate-report')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
        </Form>
        {AllProjectReportTable && (
          <ReportTableAllProjects
            Data={AllProjectReportTable?.data}
            range={AllProjectReportTable?.range}
          />
        )}

        {/* second end */}

        <Typography.Title level={1} className='mt-7'>
          {t('generate-report-of-all-resources-between-dates')}
        </Typography.Title>
        {/* <Divider /> */}

        <Form layout='vertical' onFinish={onFinish3} form={form3}>
          <Row gutter={[20, 20]} align='bottom'>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                name='date'
                label={'Range'}
                rules={[{ required: true, message: 'Please select range' }]}
              >
                <DatePicker.RangePicker
                  allowClear
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
                {/* <Select placeholder={'Select Range'}>
                  <Select.Option value='currentMonth'>Current Month</Select.Option>
                  <Select.Option value='lastMonth'>Last Month</Select.Option>
                  <Select.Option value='previous3Months'>Last 3 Months</Select.Option>
                  <Select.Option value='previous6Months'>Last 6 Months</Select.Option>
                </Select> */}
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={loader3}>
                  {t('generate-report')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider /> */}
        </Form>

        {firstTable && (
          <ReportTableAllEmployees Data={firstTable?.data} range={firstTable?.range} />
        )}
      </div>
    </Layout>
  )
}

export default ProjectDetail
