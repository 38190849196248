import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Calendar, Table, Tabs, Tooltip } from 'antd'
import moment from 'moment'

import Layout from '../../layout/Layout'
import InfinityLoader from '../../components/InfinityLoader'
import { useDispatch } from 'react-redux'
import { getAllLeaveRequests, getCalendarLeavesData, updateLeaveRequestStatus } from '../../redux'
import CalendarLeaveModal from '../../components/modal/CalendarLeaveModal'
import { AiOutlineEdit } from 'react-icons/ai'

const AdminLeave = () => {
  const { TabPane } = Tabs
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userType = localStorage.getItem('userType')
  const [data, setData] = useState(null)
  const [requestsData, setRequestsData] = useState(null)
  const [calendarData, setCalendarData] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [dateSelect, setDateSelect] = useState(null)

  const getAllLeavesRequests = async () => {
    try {
      const result = await dispatch(getAllLeaveRequests())
      if (result) {
        // console.log('result', result)
        setRequestsData(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  const getCalendarLeaves = async () => {
    try {
      const result = await dispatch(getCalendarLeavesData())
      if (result) {
        setCalendarData(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  const onDecisionClick = async (id, decision) => {
    try {
      const result = await dispatch(updateLeaveRequestStatus(id, decision))
      if (result) {
        getAllLeavesRequests()
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    getAllLeavesRequests()
    getCalendarLeaves()
  }, [])

  useEffect(() => {
    let tempArr = []
    requestsData?.map((employee) => {
      tempArr.push({
        employee: employee?.employee?.name,
        date: `${moment(employee?.dates[0]).format('DD/MM/YYYY')} - ${moment(
          employee?.dates[1],
        ).format('DD/MM/YYYY')}`,
        reason:
          employee?.reason?.length > 20 ? (
            <Tooltip placement='top' title={employee?.reason}>
              {employee?.reason?.slice(0, 20)}...
            </Tooltip>
          ) : (
            employee?.reason
          ),
        status: (
          <span style={{ fontWeight: '600', textTransform: 'capitalize' }}>
            {employee?.status}{' '}
            {employee?.status !== 'Pending' && `by ${employee?.approvedBy?.adminType}`}
          </span>
        ),
        action:
          employee?.status === 'Pending' ? (
            <div className='decision-div'>
              <span className='greentag' onClick={() => onDecisionClick(employee?._id, 'Approved')}>
                Approve
              </span>
              <span className='redtag' onClick={() => onDecisionClick(employee?._id, 'Rejected')}>
                Reject
              </span>
            </div>
          ) : employee?.approvedBy?.adminType === 'moderator' && userType === 'admin' ? (
            <div className='decision-div'>
              <span className='greentag' onClick={() => onDecisionClick(employee?._id, 'Approved')}>
                Approve
              </span>
              <span className='redtag' onClick={() => onDecisionClick(employee?._id, 'Rejected')}>
                Reject
              </span>
            </div>
          ) : employee?.status === 'Approved' ? (
            <span className='greentag'>{employee?.status}</span>
          ) : (
            <span className='redtag'>{employee?.status}</span>
          ),
      })
    })
    setData(tempArr)
  }, [requestsData])

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employee',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '220px',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },
  ]

  //   Calendar Function
  const dateCellRender = (value) => {
    const listData = getListData(moment(new Date(value)).format('YYYY-MM-DD'))
    return (
      <ul className='leave'>
        {listData?.data?.length > 0 &&
          listData?.data?.map((item, ind) => (
            <Tooltip
              key={ind}
              placement='bottom'
              title={<p style={{ color: 'black' }}>Reason: {item?.reason}</p>}
              color='white'
            >
              <li
                title=''
                className={`${item?.status == 'Pending' ? 'pending' : ''}${
                  item?.status == 'Approved' ? 'active' : ''
                } ${item?.status == 'Rejected' ? 'rejected' : ''}`}
                style={{ width: '100%', paddingInline: '10px' }}
                onClick={() => setIsModalVisible(true)}
              >
                <Badge status='default' text={item?.employee} />
              </li>
            </Tooltip>
          ))}
      </ul>
    )
  }

  //   Calendar Function
  const getListData = (value) => {
    let listData = {
      data: [],
    }
    calendarData?.map((leave) => {
      if (moment(leave?.date).format('YYYY-MM-DD') === value) {
        listData?.data?.push({
          employee: leave?.employee?.name,
          status: leave?.status,
          reason: leave?.reason,
        })
      }
    })
    return listData || {}
  }

  return (
    <Layout active={'admin-leave'}>
      {requestsData === null ? (
        <InfinityLoader />
      ) : (
        <div className='leave-container'>
          <Tabs defaultActiveKey='1' animated>
            <TabPane tab={t('leave-requests')} key='1' style={{ padding: '20px 0' }}>
              <Table sticky dataSource={data} columns={columns} />
            </TabPane>
            <TabPane tab={t('calendar-summary')} key='2' style={{ padding: '20px 0' }}>
              <Calendar
                mode='month'
                dateCellRender={dateCellRender}
                onSelect={(e) => {
                  setDateSelect(moment(new Date(e)).format('YYYY-MM-DD'))
                }}
              />
              <CalendarLeaveModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                setDateSelect={setDateSelect}
                dateSelect={dateSelect}
                data={calendarData}
              />
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default AdminLeave
