import React from 'react'
import AttendancePdf from '../components/PdfTemplate/AttendancePdf'

const Calendar = () => {
  return (
    <div className='calendar'>
      {/* {[
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ].map((data) => (
        <div className="card">
          <h1>{data}</h1>
          <p>August</p>
        </div>
      ))} */}
      <button onClick={() => AttendancePdf('August', 'Afaque Ahmed')}>Create PDF</button>
    </div>
  )
}

export default Calendar
