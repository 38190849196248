import { Table, Button, Typography } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { extraCostCalculate, extraTimeCalculate } from '../functions/extraTimeCalculate'
import { timeFormatter } from '../functions/timeFormatter'
import ProjectCostingPdf from './PdfTemplate/ProjectCostingPdf'
import EmployeeSalaryReportPdf from './PdfTemplate/EmployeeSalaryReportPdf'

const ReportTableAllEmployees = ({ Data, range }) => {
  const [summaryType, setSummaryType] = useState('')
  const [laoding, setLoading] = useState(false)

  const ExpandedReportRender = (Record) => {
    let DatatoMap = Data[summaryType.employee]
    // console.log('DatatoMap', DatatoMap)
    DatatoMap = Record?.key === 'daily' ? DatatoMap?.timeline : DatatoMap?.projectWiseData

    const columns =
      Record?.key === 'daily'
        ? [
            {
              title: 'Date',
              dataIndex: 'Pdate',
              key: 'Pdate',
            },
            {
              title: 'Project Name',
              dataIndex: 'Pname',
              key: 'Pname',
            },
            {
              title: 'Hours',
              dataIndex: 'Phours',
              key: 'Phours',
            },
            {
              title: 'Cost',
              dataIndex: 'Pcost',
              key: 'Pcost',
            },
            {
              title: 'Over Time',
              dataIndex: 'overtime',
              key: 'overtime',
            },
            {
              title: 'Extra Cost',
              dataIndex: 'extracost',
              key: 'extracost',
            },
          ]
        : [
            {
              title: 'Date',
              dataIndex: 'Pdate',
              key: 'Pdate',
            },
            {
              title: 'Project Name',
              dataIndex: 'Pname',
              key: 'Pname',
            },
            {
              title: 'Hours',
              dataIndex: 'Phours',
              key: 'Phours',
            },
            {
              title: 'Cost',
              dataIndex: 'Pcost',
              key: 'Pcost',
            },
          ]
    // const data = [];
    // for (let i = 0; i < 3; ++i) {
    //   data.push({
    //     key: i.toString(),
    //     pName: "2014-12-24 23:12:00",
    //     Phours: "This is production name",
    //     Pcost: "Upgraded: 56",
    //   });
    // }
    return (
      <Table
        columns={columns}
        // expandable={{
        //   expandedReportRender,
        // }}
        // dataSource={data}
        dataSource={DatatoMap?.map((data) => ({
          key: data?._id,
          Pdate: moment(data?.CreatedAt).format('DD/MMM/YYYY'),
          Pname: Record?.key === 'daily' ? data?.project?.title : data?.data?.[0]?.title,
          Phours: Number(data?.hours)?.toFixed(0)?.toLocaleString(),
          Pcost: 'Rs. ' + Number(data?.cost)?.toFixed(0)?.toLocaleString(),
          overtime: extraTimeCalculate(data?.hours, data.user?.noOfHours),
          extracost: extraCostCalculate(
            data?.hours,
            data.user?.noOfHours,
            data?.user?.hourlySalary,
          ),
        }))}
        pagination={false}
      />
    )
  }

  const expandedRowRender = (Record) => {
    const columns = [
      {
        title: 'Summary Type',
        dataIndex: 'type',
        key: 'type',
      },
    ]
    const data = [
      {
        key: 'daily',
        type: 'Daily Report',
      },
      {
        key: 'report',
        type: 'Project Wise Report',
      },
    ]
    return (
      <Table
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: ExpandedReportRender,
          onExpand: (expand, record) => {
            setSummaryType({ type: record.key, employee: Record.name })
          },
          //   rowExpandable: (record) => {
          //     return !summaryType || record.key === summaryType.type
          //       ? true
          //       : false;
          //   },
        }}
        dataSource={data}
        pagination={false}
      />
    )
  }

  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Salary',
      dataIndex: 'salary',
      key: 'salary',
    },
    {
      title: 'Total Cost',
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: 'Total Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Over Time',
      dataIndex: 'overtime',
      key: 'overtime',
    },
    {
      title: 'Extra Cost',
      dataIndex: 'extracost',
      key: 'extracost',
    },
    {
      title: '',
      dataIndex: 'extracost',
      key: 'extracost',
      render: (text, record) => {
        return (
          <Button
            onClick={() => downloadSinglePdf(record?.key)}
            loading={laoding}
            type='primary'
            size='small'
          >
            Download PDF
          </Button>
        )
      },
    },
  ]

  const downloadPdf = async () => {
    setLoading(true)
    let dateRange = `${moment(new Date(range[0])).format('DD-MM-YYYY')} - ${moment(
      new Date(range[1]),
    ).format('DD-MM-YYYY')}`
    await Object.entries(Data)?.map(([name, data]) => {
      EmployeeSalaryReportPdf(data, range, data?.employee?.name + ` (${dateRange}) ` + '.pdf')
      // ProjectCostingPdf(
      //   data?.timeline,
      //   range,
      //   name,
      //   data?.totalHours,
      //   Number(data?.totalCost)?.toFixed(0),
      //   name + '.pdf',
      //   data?.expense,
      // )
    })
    setLoading(false)
  }

  const downloadSinglePdf = async (employeeId) => {
    let employeeData
    let dateRange = `${moment(new Date(range[0])).format('DD-MM-YYYY')} - ${moment(
      new Date(range[1]),
    ).format('DD-MM-YYYY')}`

    await Object.entries(Data)?.map(([name, data]) => {
      if (data?.employee?._id === employeeId) return (employeeData = data)
    })

    EmployeeSalaryReportPdf(
      employeeData,
      range,
      employeeData?.employee?.name + ` (${dateRange}) ` + '.pdf',
    )
    // ProjectCostingPdf(
    //   employeeData?.timeline,
    //   range,
    //   employeeData?.employee?.name,
    //   employeeData?.totalHours,
    //   Number(employeeData?.totalCost)?.toFixed(0),
    //   employeeData?.employee?.name + '.pdf',
    //   employeeData?.expense,
    // )
  }

  return (
    <>
      <Typography.Title
        level={3}
        style={{ color: 'var(--text)', textAlign: 'center', marginTop: '40px' }}
      >
        All Resources Report
      </Typography.Title>
      <Typography.Title
        level={6}
        style={{ color: 'var(--text)', textAlign: 'center', marginTop: '5px' }}
      >
        {moment(new Date(range[0])).format('DD/MM/YYYY')}-
        {moment(new Date(range[1])).format('DD/MM/YYYY')}
      </Typography.Title>
      <Button
        onClick={downloadPdf}
        loading={laoding}
        type='primary'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0px 0 40px auto',
        }}
      >
        Download PDF
      </Button>
      <Table
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender,
          //   defaultExpandedRowKeys: ["0"],
        }}
        dataSource={Object.entries(Data)?.map(([name, data]) => {
          console.log('data', data?.totalHours, data?.perHourExpenseRate)
          return {
            key: data.employee?._id,
            name,
            salary: 'Rs. ' + data.employee?.salary?.toLocaleString(),
            cost:
              'Rs. ' +
              Number((data?.perHourExpenseRate * data?.totalHours)?.toFixed(0))?.toLocaleString(),
            // (Number(
            //   (data.perHourExpenseRate * data.totalHours)?.toFixed(0)
            // ) + data.employee?.salary)?.toLocaleString(),
            hours: timeFormatter(data?.totalHours),
            overtime: extraTimeCalculate(
              data?.totalHours,
              data?.employee?.daysPerMonth * data.employee?.noOfHours,
            ),
            extracost: extraCostCalculate(
              data?.totalHours,
              data?.employee?.daysPerMonth * data.employee?.noOfHours,
              data?.employee?.hourlySalary,
            ),
          }
        })}
        pagination={false}
      />
    </>
  )
}

export default ReportTableAllEmployees
