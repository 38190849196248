import * as types from '../types/generalTypes'

const initialState = {
  count: 0,
  notifications: {
    notifications: [],
    gNotifications: [],
  },
  allNotifications: {
    notifications: [],
    gNotifications: [],
  },
}

const notificationReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      }
    case types.GET_NOTICOUNT: {
      return {
        ...state,
        count: payload,
      }
    }
    case types.GET_ALLNOTIFICATIONS:
      return {
        ...state,
        allNotifications: payload,
      }

    default:
      return state
  }
}

export default notificationReducer
