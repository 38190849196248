import Board from '@asseinfo/react-kanban'
import { useEffect, useState } from 'react'
import { updateTodoStatus } from '../redux'
import DeleteTodo from './modal/DeleteTodo'
import { useTranslation } from 'react-i18next'

const Todos = ({ allTodos, loading }) => {
  const { t } = useTranslation()
  const [newBoard, setBoard] = useState(null)
  const [force, setForce] = useState(false)
  const [inLoading, setInLoading] = useState(false)
  const filter = (data) => {
    // setCards(Cards.filter((card) => card.id !== id))
    setInLoading(true)
    let filtered = allTodos[data.status].filter((item) => item._id !== data._id)
    allTodos[data.status] = filtered
    setForce(!force)
  }
  useEffect(() => {
    if (allTodos) {
      let todos = Object.entries(allTodos)
      let columns = []
      todos?.map(([status, todo], ind) => {
        if (todo.length > 0) {
          let cards = []
          todo?.map((item) => {
            cards.push({
              title: item.title,
              id: item._id,
              description: (
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <p>
                    {t('description')} : {item.description}
                  </p>
                  {item.project && (
                    <>
                      <p>{t('assigned-to')} : </p>
                      {item.assignedTo?.map((val, ind) => {
                        return (
                          <p
                            key={ind}
                            style={
                              {
                                // marginLeft: 80,
                              }
                            }
                          >
                            {val.name}
                          </p>
                        )
                      })}
                    </>
                  )}
                  <DeleteTodo data={item} filter={filter} />
                </div>
              ),
            })
          })
          // setCards(cards);
          columns.push({
            id: ind,
            title: status,
            cards,
          })
        } else {
          columns.push({
            id: ind,
            title: status,
            cards: [],
          })
        }
      })

      // columns are updating here but board is not updating. Issue: deleted card is still showing in board
      setBoard({ columns })
      // board state is also updating but board is not updating. Issue: deleted card is still showing in board
      setInLoading(false)
    }
  }, [allTodos, force])

  useEffect(() => {
    if (loading) setBoard(null)
  }, [loading])

  return (
    <>
      {loading ? (
        <div style={{ minHeight: 500 }} />
      ) : inLoading ? (
        <div style={{ minHeight: 500 }} />
      ) : (
        newBoard && (
          <Board
            disableColumnDrag
            onCardDragEnd={(board, Card, source, destination) => {
              let droppedTo = newBoard.columns.find((obj) => {
                return obj.id === destination.toColumnId
              })
              updateTodoStatus({
                id: Card.id,
                status: droppedTo.title,
              })
            }}
            initialBoard={newBoard}
          />
        )
      )}
    </>
  )
}

export default Todos
