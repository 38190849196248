import * as types from '../types/generalTypes'

const initialState = {
  employees: null,
  projects: null,
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_EMPLOYEES_BY_PROJECT:
      return {
        ...state,
        employees: action.payload,
      }
    case types.GET_ALL_PROJECTS_BY_EMPLOYEE:
      return {
        ...state,
        projects: action.payload,
      }
    default:
      return state
  }
}

export default reportReducer
