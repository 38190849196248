import { notification } from 'antd'
import privateAPI, { attachToken } from '../../config/constants'

export const getCategories = async () => {
  try {
    attachToken()
    const res = await privateAPI.get(`/category/get`)
    if (res) {
      return res.data
    }
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message || 'Server Error',
      duration: 3,
    })
  }
}

export const createCategories = async (payload, navigate) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/category/create`, payload)
    if (res) {
      notification.success({
        message: res.data.message,
        duration: 3,
      })
      navigate('/member')
    }
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message || 'Server Error',
      duration: 3,
    })
  }
}
export const updateCategory = async (payload, navigate) => {
  // return async (dispatch) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/category/update`, payload)
    if (res) {
      notification.success({
        message: res.data.message,
        duration: 3,
      })
      navigate('/member')
    }
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message || 'Server Error',
      duration: 3,
    })
  }
}
