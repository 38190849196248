import { GET_DEPARTMENTS } from '../types/generalTypes'

const initialState = {
  departments: null,
}

const departmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      }

    default:
      return state
  }
}

export default departmentsReducer
