import swal from 'sweetalert'
// import { notification } from 'antd'
import privateAPI, { attachToken } from '../../config/constants'

// import { GET_SUBSCRIPTION_DETAILS } from '../types/generalTypes'

export const getSubscriptionDetails = async () => {
  // return async (dispatch) => {
  try {
    attachToken()
    const res = await privateAPI.get(`/admin/subscriptionDetails`)
    if (res) {
      // dispatch({
      //     type: GET_SUBSCRIPTION_DETAILS,
      //     payload: res.data,
      // })
      return res.data
    }
  } catch (err) {
    swal('', err?.response?.data?.message || 'Server Error', 'error')
  }
  // }
}
