import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Divider, Typography, DatePicker, Row, Col, Empty } from 'antd'

import LogsCard from '../components/LogsCard'
import InfinityLoader from '../components/InfinityLoader'
import { getLogs } from '../redux/actions/logs'
import Layout from '../layout/Layout'

const Logs = () => {
  const { t } = useTranslation()
  const { logs } = useSelector((state) => state.logs)
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date(Date.now()))
  const [loading, setLoading] = useState(false)

  const onMonthSelect = (value) => {
    setDate(new Date(value))
  }

  const fetchData = async (date) => {
    setLoading(true)
    await dispatch(getLogs(date))
    setLoading(false)
  }

  useEffect(() => {
    fetchData(date)
  }, [date])

  return (
    <Layout active='logs'>
      <div className='logs-container'>
        <div className='logs-head'>
          <Typography.Title level={2}>{t('logs')}</Typography.Title>
          <DatePicker
            placeholder={t('select-date')}
            onChange={onMonthSelect}
            style={{ width: '150px', marginLeft: '20px' }}
            disabledDate={(d) => {
              return d?.isAfter(moment())
              // return moment(d).day() === 0 || d?.isAfter(moment());
            }}
            // picker={"mon"}
          />
        </div>
        <Divider></Divider>
        <Row justify='space-around' className='logs-box' gutter={[16, 16]}>
          {loading ? (
            <div
              style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <InfinityLoader />
            </div>
          ) : logs.length > 0 ? (
            logs?.logs?.map((log, ind) => {
              return (
                <Col md={11} xs={24} className='log-col' key={ind}>
                  <LogsCard key={ind} log={log} />
                </Col>
              )
            })
          ) : (
            <div
              style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Empty description={t('no-logs-found')} />
            </div>
          )}
        </Row>
      </div>
    </Layout>
  )
}

export default Logs
