import { useState } from 'react'
import { Modal, Button, Form, Input, Row, Col, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { createTicket, getAllEmployeesName } from '../../redux'

const CreateTicketModal = ({ apiCall, getEmployeeTickets }) => {
  const { Option } = Select
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const employees = useSelector((state) => state?.employees?.employeesName)

  const showModal = async () => {
    setIsModalVisible(true)
    await dispatch(getAllEmployeesName())
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      let res = await dispatch(createTicket(values))
      if (res?.message) {
        setLoading(false)
        form.resetFields()
        // apiCall()
        getEmployeeTickets()
        setIsModalVisible(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button type='primary' className='addModeratorBtn' onClick={showModal}>
        {t('create-ticket')}
      </Button>

      <Modal
        title={t('create-ticket')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form layout='vertical' onFinish={onFinish} form={form}>
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label={t('ticket-issue-to-employee')}
                name={'ticketIssueTo'}
                rules={[
                  {
                    required: true,
                    message: t('ticket-issue-to-employee-required'),
                  },
                ]}
                requiredMark={'optional'}
              >
                <Select placeholder={t('select-employee')}>
                  {employees?.employee?.map(
                    (emp, ind) =>
                      emp?._id !== localStorage.getItem('userId') && (
                        <Option key={ind} value={emp?._id}>
                          {emp?.name}
                        </Option>
                      ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('description')}
                name={'description'}
                rules={[
                  {
                    required: true,
                    message: t('description-required'),
                  },
                ]}
                requiredMark={'optional'}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {t('request')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default CreateTicketModal
