import { useState } from 'react'
import { Modal, Button, Form, Input, Row, Col, DatePicker, Select, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { createEmployee, updateEmployee, updateEmpPassword } from '../../redux'
import { TbEdit } from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const Project = ({ newEmployee, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showReason, setShowReason] = useState(false)
  const [updatePassword, setUpdatePassword] = useState(false)
  const dispatch = useDispatch()
  const departments = useSelector((state) => state.departments.departments)
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async (values) => {
    setLoading(true)
    if (newEmployee) {
      await dispatch(createEmployee(values))
      form.resetFields()
      setLoading(false)
      setIsModalVisible(false)
    } else {
      await dispatch(
        updateEmployee({
          ...values,
          previousSalary: data?.salary,
          id: data._id,
        }),
      )
      setShowReason(false)
      form.resetFields()
      setLoading(false)
      setIsModalVisible(false)
    }
  }
  const onFinishPassword = async (values) => {
    setLoading(true)
    if (values.newPassword != values.confirmPassword) {
      setLoading(false)
      return alert('Passwords do not match')
    }
    await dispatch(
      updateEmpPassword({
        newPass: values.newPassword,
        user: data._id,
      }),
    )
    setLoading(false)
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleSalaryChange = (e) => {
    if (data?.salary != e.target.value) {
      setShowReason(true)
    } else {
      setShowReason(false)
    }
  }

  return (
    <>
      {newEmployee ? (
        <Button type='primary' className='addEmployeeBtn' onClick={showModal}>
          {t('add-employee')}
        </Button>
      ) : (
        <TbEdit className='editBtn' onClick={showModal} />
      )}
      <Modal
        title={newEmployee ? t('new-employee') : t('update-employee')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'800px'}
        style={{ top: '20px' }}
      >
        {!updatePassword ? (
          <Form
            layout='vertical'
            form={form}
            fields={
              data
                ? [
                    {
                      name: 'name',
                      value: data?.name,
                    },
                    {
                      name: 'phone',
                      value: data?.phone,
                    },
                    {
                      name: 'designation',
                      value: data?.designation,
                    },
                    {
                      name: 'salary',
                      value: form.getFieldValue('salary') || data?.salary,
                    },
                    {
                      name: 'email',
                      value: data?.email,
                    },
                    {
                      name: 'address',
                      value: data?.address,
                    },
                    {
                      name: 'noOfHours',
                      value: data?.noOfHours,
                    },
                    {
                      name: 'offDays',
                      value: data?.offDays,
                    },
                    {
                      name: 'joiningDate',
                      value: moment(data?.joiningDate),
                    },
                  ]
                : []
            }
            onFinish={onFinish}
          >
            <Row gutter={[20, 0]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('employee-name')}
                  name={'name'}
                  rules={[
                    {
                      required: true,
                      message: t('employee-name-required'),
                      pattern: /^[^0-9]+$/i,
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                {' '}
                <Form.Item
                  label={t('phone')}
                  name={'phone'}
                  rules={[
                    {
                      required: true,
                      pattern: /^[0-9]+$/,
                      message: t('phone-required'),
                    },
                    {
                      max: 15,
                      message: 'Enter a valid phone number',
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type={'number'} maxLength={15} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('designation')}
                  name={'designation'}
                  rules={[
                    {
                      required: true,
                      pattern: /^[^0-9]+$/,
                      message: t('designation-required'),
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('department')}
                  name={'department'}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Department is Required",
                  //   },
                  // ]}
                >
                  <Select placeholder={t('select-department')}>
                    {departments?.departments?.map((val, ind) => {
                      return (
                        <Select.Option key={ind} value={val._id}>
                          {val.name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('salary')}
                  name={'salary'}
                  rules={[
                    {
                      required: true,
                      message: t('salary-required'),
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type={'number'} onChange={(e) => handleSalaryChange(e)} />
                </Form.Item>
              </Col>

              {showReason && !newEmployee && (
                <Col xs={24}>
                  <Form.Item label={t('reason-for-salary-update')} name={'reason'}>
                    <Input />
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('email')}
                  name={'email'}
                  rules={[
                    {
                      type: 'email',
                      message: t('email-invalid'),
                    },
                    {
                      required: true,
                      message: t('email-required'),
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('off-days')}
                  name={'offDays'}
                  rules={[
                    {
                      required: true,
                      message: t('off-days-required'),
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Select
                    placeholder={t('select-off-days')}
                    maxTagPlaceholder={() => {
                      const getDay = [
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                      ]
                      const days = form.getFieldValue('offDays')
                      let temp = []
                      for (let i = 3; i <= days?.length; i++) {
                        temp.push(getDay[days[i]])
                      }
                      return (
                        <Tooltip
                          placement='bottom'
                          title={
                            <p style={{ color: 'black', fontSize: '11px' }}>
                              {temp?.slice(0, -1)?.join(',')}
                            </p>
                          }
                          color='white'
                        >
                          {`+${days?.length - 3}...`}
                        </Tooltip>
                      )
                    }}
                    mode='multiple'
                    maxTagCount={3}
                  >
                    <Select.Option value={0}>{t('sunday')}</Select.Option>
                    <Select.Option value={1}>{t('monday')}</Select.Option>
                    <Select.Option value={2}>{t('tuesday')}</Select.Option>
                    <Select.Option value={3}>{t('wednesday')}</Select.Option>
                    <Select.Option value={4}>{t('thursday')}</Select.Option>
                    <Select.Option value={5}>{t('friday')}</Select.Option>
                    <Select.Option value={6}>{t('saturday')}</Select.Option>
                  </Select>
                  {/* <Input type={"number"} /> */}
                </Form.Item>
              </Col>
              {newEmployee && (
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t('password')}
                    name={'password'}
                    rules={[
                      {
                        required: true,
                        message: t('password-required'),
                      },
                    ]}
                    requiredMark={'optional'}
                  >
                    <Input.Password
                      style={{
                        backgroundColor: 'transparent',
                        border: '1px solid var(--borderLight)',
                      }}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} lg={12}>
                <Form.Item
                  label='No of Hours'
                  name={'noOfHours'}
                  rules={[
                    {
                      required: true,
                      message: 'Valid Hours Required',
                      validator: (_, value) => {
                        if (value > 24) {
                          return Promise.reject(new Error(t('no-of-hours-invalid')))
                        } else {
                          return Promise.resolve()
                        }
                      },
                    },
                  ]}
                  requiredMark={'optional'}
                >
                  <Input type={'number'} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('joining-date')}
                  name={'joiningDate'}
                  rules={[
                    // newEmployee && {
                    //   required: true,
                    //   message: t('joining-date-required'),
                    // },
                    {
                      required: newEmployee ? true : false,
                      message: t('joining-date-required'),
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={t('select-date')}
                    // defaultValue={data?.joiningDate ? moment(data?.joiningDate).local() : null}
                    format={'DD/MM/YYYY'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                    // onChange={onDateFilter}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('date-of-birth')}
                  name={'dateOfBirth'}
                  // rules={[
                  //   newEmployee && {
                  //     required: true,
                  //     message: t('date-of-birth-required'),
                  //   },
                  // ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={t('select-date')}
                    defaultValue={data?.DateOfBirth ? moment(data?.DateOfBirth).local() : null}
                    format={'DD/MM/YYYY'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                    // onChange={onDateFilter}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label={t('address')} name={'address'}>
              <Input.TextArea rows={2} style={{ resize: 'none' }} />
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' loading={loading} type='primary'>
                {newEmployee ? t('add-employee') : t('update-employee')}
              </Button>
              {!newEmployee && !updatePassword && (
                <span
                  style={{
                    marginLeft: 10,
                    color: 'var(--primary)',
                    cursor: 'pointer',
                    padding: 5,
                    borderBottom: '1px solid var(--text)',
                  }}
                  onClick={() => {
                    setUpdatePassword(true)
                  }}
                >
                  {t('update-password')}
                </span>
              )}
            </Form.Item>
          </Form>
        ) : (
          <Form form={form} layout='vertical' onFinish={onFinishPassword}>
            <Form.Item
              label={t('new-password')}
              name={'newPassword'}
              rules={[
                {
                  required: true,
                  message: t('new-password-required'),
                },
              ]}
              requiredMark={'optional'}
            >
              <Input.Password
                style={{
                  backgroundColor: 'transparent',
                  border: '1px solid var(--borderLight)',
                }}
              />
            </Form.Item>
            <Form.Item
              label={t('confirm-password')}
              name={'confirmPassword'}
              rules={[
                {
                  required: true,
                  message: t('confirm-password-required'),
                },
              ]}
              requiredMark={'optional'}
            >
              <Input.Password
                style={{
                  backgroundColor: 'transparent',
                  border: '1px solid var(--borderLight)',
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType='submit' loading={loading} type='primary'>
                {t('update-password')}
              </Button>
              <span
                style={{
                  marginLeft: 10,
                  color: 'var(--primary)',
                  cursor: 'pointer',
                  padding: 5,
                  borderBottom: '1px solid var(--text)',
                }}
                onClick={() => {
                  setUpdatePassword(false)
                }}
              >
                {t('back')}
              </span>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
}

export default Project
