import { notification } from 'antd'
import swal from 'sweetalert'
import privateAPI, { attachToken } from '../../config/constants'

export const getOrganizationHierarchy = () => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/hr/employee-hierarchy`)
      if (res) {
        return res?.data?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

// Document Actions

export const getAllEmployees = () => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/hr/all-employees`)
      if (res) {
        return res?.data?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const createDocument = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/create-document`, payload)
      if (res?.data?.message) {
        notification.success({
          description: res?.data?.message,
          duration: 3,
        })
        return res?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeDocs = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/employee-documents`, { employee: payload })
      if (res?.data?.data) {
        return res?.data?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const deleteDocument = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/delete-document`, payload)
      if (res?.data?.message) {
        notification.success({
          description: res?.data?.message,
          duration: 3,
        })
        return res?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeHistory = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/employee-history`, { employee: payload })
      if (res?.data) {
        return res?.data || []
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

// Ticket Actions

export const createTicket = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/create-ticket`, payload)
      if (res?.data?.message) {
        notification.success({
          description: res?.data?.message,
          duration: 3,
        })
        return res?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeAllTickets = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/employee-tickets`, { tab: payload })
      if (res?.data) {
        return res?.data?.tickets || []
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllTickets = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.get(`/hr/all-tickets`)
      if (res?.data) {
        return res?.data?.tickets || []
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateTicket = (payload) => {
  return async () => {
    try {
      attachToken()
      let res = await privateAPI.post(`/hr/update-ticket`, payload)
      if (res?.data?.message) {
        notification.success({
          description: res?.data?.message,
          duration: 3,
        })
        return res?.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}
