import { Table } from 'antd'
import React from 'react'

const ProjectResourceTable = ({ projectTable }) => {
  console.log('Table', projectTable)
  // console.log("first table" , projectTable);

  const expandedRowRenderThirdLevel = (Record) => {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Hours',
        dataIndex: 'hours',
        key: 'hours',
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
      },
    ]

    let dataSource
    if (!projectTable.payload.user) {
      // dataSource = projectTable?.result?.data?.employeeWiseData?.map((data) => (
      //   {
      //           key : data?._id,
      //           employeeName : data?.data?.[0]?.name,
      //              totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
      //           (data.hours - Math.trunc(data.hours)) * 60,
      //           0
      //         )} mins`,
      //         totalAmount: `PKR ${Math.round(data.cost)}`,
      //         }
      //       ));
      dataSource = projectTable.result.data.timeline
        .filter((x) => x.user._id === Record.key)
        .map((x) => ({
          date: x.CreatedAt.substring(0, 10),
          hours: `${Math.trunc(x.hours)} hrs ${Math.round(
            (x.hours - Math.trunc(x.hours)) * 60,
            0,
          )} mins`,
          cost: `PKR ${Math.round(
            projectTable?.result?.data?.perHourExpense * x?.hours + x.cost,
          )?.toLocaleString()}`,
        }))
    } else if (projectTable.payload.user && projectTable.payload.project) {
      // dataSource = projectTable?.result?.data?.employeeWiseData?.map((data) => (
      //   {
      //           key : data?._id,
      //           employeeName : data?.data?.[0]?.name,
      //              totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
      //           (data.hours - Math.trunc(data.hours)) * 60,
      //           0
      //         )} mins`,
      //         totalAmount: `PKR ${Math.round(data.cost)}`,
      //         }
      //       ));
      dataSource = projectTable.result.data.timeline
        .filter((x) => x.user._id === Record.key)
        .map((x) => ({
          date: x.CreatedAt.substring(0, 10),
          hours: `${Math.trunc(x.hours)} hrs ${Math.round(
            (x.hours - Math.trunc(x.hours)) * 60,
            0,
          )} mins`,
          cost: `PKR ${Math.round(
            projectTable?.result?.data?.perHourExpense * x?.hours + x.cost,
          )?.toLocaleString()}`,
        }))
      // data = projectTable.result.data.timeline.map((x) => ({
      //   date: x.CreatedAt.substring(0, 10),
      //   hours: `${Math.trunc(x.hours)} hrs ${Math.round(
      //     (x.hours - Math.trunc(x.hours)) * 60,
      //     0
      //   )} mins`,
      //   cost: Math.round(x.cost),
      // }));
    } else if (!projectTable.payload.project) {
      dataSource = projectTable.result.data.timeline
        .filter((obj) => {
          return obj.project._id === Record.key
        })
        .map((x) => ({
          date: x.CreatedAt.substring(0, 10),
          hours: `${Math.trunc(x.hours)} hrs ${Math.round(
            (x.hours - Math.trunc(x.hours)) * 60,
            0,
          )} mins`,
          cost: `PKR ${Math.round(
            projectTable?.result?.data?.perHourExpense * x?.hours + x.cost,
          )?.toLocaleString()}`,
        }))
    }
    // console.log(Record);
    // console.log(data);
    return <Table columns={columns} dataSource={dataSource} pagination={false} />
  }

  const expandedRowRender = () => {
    let columns

    let dataSource
    if (!projectTable.payload.user) {
      columns = [
        {
          title: 'Employee Name',
          dataIndex: 'employeeName',
          key: 'employeeName',
        },
        {
          title: 'Total Hours',
          dataIndex: 'totalHours',
          key: 'totalHours',
        },
        {
          title: 'Total Amount',
          dataIndex: 'totalAmount',
          key: 'totalAmount',
        },
      ]

      dataSource = projectTable?.result?.data?.employeeWiseData?.map((data) => ({
        key: data?._id,
        employeeName: data?.data?.[0]?.name,
        totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
          (data.hours - Math.trunc(data.hours)) * 60,
          0,
        )} mins`,
        totalAmount: `PKR ${Math.round(
          projectTable?.result?.data?.perHourExpense * data?.hours + data.cost,
        )?.toLocaleString()}`,
      }))
      // data = projectTable.result.data.timeline
      //   .filter((x) => x.user._id === Record.key)
      //   .map((x) => ({
      //     date: x.CreatedAt.substring(0, 10),
      //     hours: `${Math.trunc(x.hours)} hrs ${Math.round(
      //       (x.hours - Math.trunc(x.hours)) * 60,
      //       0
      //     )} mins`,
      //     cost: `PKR ${Math.round(x.cost)}`,
      //   }));
    } else if (projectTable.payload.user && projectTable.payload.project) {
      columns = [
        {
          title: 'Employee Name',
          dataIndex: 'employeeName',
          key: 'employeeName',
        },
        {
          title: 'Total Hours',
          dataIndex: 'totalHours',
          key: 'totalHours',
        },
        {
          title: 'Total Amount',
          dataIndex: 'totalAmount',
          key: 'totalAmount',
        },
      ]

      dataSource = projectTable?.result?.data?.employeeWiseData?.map((data) => ({
        key: data?._id,
        employeeName: data?.data?.[0]?.name,
        totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
          (data.hours - Math.trunc(data.hours)) * 60,
          0,
        )} mins`,
        totalAmount: `PKR ${Math.round(
          projectTable?.result?.data?.perHourExpense * data?.hours + data.cost,
        )?.toLocaleString()}`,
      }))
      // data = projectTable.result.data.timeline.map((x) => ({
      //   date: x.CreatedAt.substring(0, 10),
      //   hours: `${Math.trunc(x.hours)} hrs ${Math.round(
      //     (x.hours - Math.trunc(x.hours)) * 60,
      //     0
      //   )} mins`,
      //   cost: Math.round(x.cost),
      // }));
    } else if (!projectTable.payload.project) {
      columns = [
        {
          title: 'Project Name',
          dataIndex: 'projectName',
          key: 'projectName',
        },
        {
          title: 'Total Hours',
          dataIndex: 'totalHours',
          key: 'totalHours',
        },
        {
          title: 'Total Amount',
          dataIndex: 'totalAmount',
          key: 'totalAmount',
        },
      ]

      dataSource = projectTable?.result?.data?.projectWiseData?.map((data) => ({
        key: data?._id,
        projectName: data?.data?.[0]?.title,
        totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
          (data.hours - Math.trunc(data.hours)) * 60,
          0,
        )} mins`,
        totalAmount: `PKR ${Math.round(
          projectTable?.result?.data?.perHourExpense * data?.hours + data.cost,
        )?.toLocaleString()}`,
        // totalHours : data?.hours,
        // totalAmount : `PKR ${Math.round(data?.cost)?.toLocaleString()}`
      }))
      //   {
      //   key: x._id,
      //   employeeName: x.data[0].name,
      //   totalHours: `${Math.trunc(x.hours)} hrs ${Math.round(
      //     (x.hours - Math.trunc(x.hours)) * 60,
      //     0
      //   )} mins`,
      //   totalAmount: `PKR ${Math.round(x.cost)}`,
      // }
      // data = projectTable.result.data.timeline
      //   .filter((obj) => {
      //     return obj.project._id === Record.key;
      //   })
      //   .map((x) => ({
      //     date: x.CreatedAt.substring(0, 10),
      //     hours: `${Math.trunc(x.hours)} hrs ${Math.round(
      //       (x.hours - Math.trunc(x.hours)) * 60,
      //       0
      //     )} mins`,
      //     cost: `PKR ${Math.round(x.cost)}`,
      //   }));
    }
    // console.log(Record);
    // console.log(data);
    return (
      <Table
        columns={columns}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: expandedRowRenderThirdLevel,
        }}
        dataSource={dataSource}
        pagination={false}
      />
    )
  }

  let columns
  let dataSource = []

  if (projectTable.payload.user && projectTable.payload.project) {
    columns = [
      {
        title: 'Project Name',
        dataIndex: 'projectName',
        key: 'projectName',
      },
      {
        title: 'Total Hours',
        dataIndex: 'totalHours',
        key: 'totalHours',
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
      },
    ]

    dataSource = projectTable?.result?.data?.projectWiseData?.map((data) => ({
      key: data?._id,
      projectName: data?.data?.[0]?.title,
      totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
        (data.hours - Math.trunc(data.hours)) * 60,
        0,
      )} mins`,
      totalAmount: `PKR ${Math.round(
        projectTable?.result?.data?.perHourExpense * data?.hours + data.cost,
      )?.toLocaleString()}`,
      // totalHours : data?.hours,
      // totalAmount : `PKR ${Math.round(data?.cost)?.toLocaleString()}`
    }))
    //   {
    //   key: x._id,
    //   employeeName: x.data[0].name,
    //   totalHours: `${Math.trunc(x.hours)} hrs ${Math.round(
    //     (x.hours - Math.trunc(x.hours)) * 60,
    //     0
    //   )} mins`,
    //   totalAmount: `PKR ${Math.round(x.cost)}`,
    // }
    // console.log("DATA => ", projectTable.result.data.employeeWiseData[0].hours);
    // dataSource = [];

    // if (
    //   !projectTable.result.data.employeeWiseData ||
    //   !projectTable.result.data.projectWiseData
    // ) {
    //   dataSource = [];
    // } else {
    //   dataSource = [
    //     {
    //       key: projectTable.result.data.employeeWiseData?.[0]?._id,
    //       employeeName:
    //         projectTable.result.data.employeeWiseData?.[0]?.data?.[0]?.name,
    //       projectName:
    //         projectTable.result.data.projectWiseData?.[0]?.data?.[0]?.title,

    //       //      Math.round(
    //       //   (x.hours - Math.trunc(x.hours)) * 60,
    //       //   0
    //       // )
    //       totalHours: `${Math.trunc(
    //         projectTable.result?.data?.timelineHours
    //       )} hours ${Math.round(
    //         (projectTable.result?.data?.timelineHours -
    //           Math.trunc(
    //             projectTable.result?.data?.timelineHours
    //           )) *
    //           60,
    //         0
    //       )} mins`,
    //       totalCost: `PKR ${Math.round(
    //         projectTable.result.data?.totalCost
    //       ).toLocaleString()}`,
    //     },
    //   ];
    // }
  } else if (!projectTable.payload.user) {
    columns = [
      {
        title: 'Project Name',
        dataIndex: 'projectName',
        key: 'projectName',
      },
      {
        title: 'Total Hours',
        dataIndex: 'totalHours',
        key: 'totalHours',
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
      },
    ]

    dataSource = projectTable?.result?.data?.projectWiseData?.map((data) => ({
      key: data?._id,
      projectName: data?.data?.[0]?.title,
      totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
        (data.hours - Math.trunc(data.hours)) * 60,
        0,
      )} mins`,
      totalAmount: `PKR ${Math.round(
        projectTable?.result?.data?.perHourExpense * data.hours + data.cost,
      )?.toLocaleString()}`,
      // totalHours : data?.hours,
      // totalAmount : `PKR ${Math.round(data?.cost)?.toLocaleString()}`
    }))
    //   {
    //   key: x._id,
    //   employeeName: x.data[0].name,
    //   totalHours: `${Math.trunc(x.hours)} hrs ${Math.round(
    //     (x.hours - Math.trunc(x.hours)) * 60,
    //     0
    //   )} mins`,
    //   totalAmount: `PKR ${Math.round(x.cost)}`,
    // }
  } else if (!projectTable.payload.project) {
    columns = [
      {
        title: 'Employee Name',
        dataIndex: 'employeeName',
        key: 'employeeName',
      },
      {
        title: 'Total Hours',
        dataIndex: 'totalHours',
        key: 'totalHours',
      },
      {
        title: 'Total Amount',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
      },
    ]

    dataSource = projectTable?.result?.data?.employeeWiseData?.map((data) => ({
      key: data?._id,
      employeeName: data?.data?.[0]?.name,
      totalHours: `${Math.trunc(data.hours)} hrs ${Math.round(
        (data.hours - Math.trunc(data.hours)) * 60,
        0,
      )} mins`,
      totalAmount: `PKR ${Math.round(
        projectTable?.result?.data?.perHourExpense * data?.hours + data?.cost,
      )?.toLocaleString()}`,
    }))
    // dataSource = projectTable.result.data.projectWiseData.map((x) => ({
    //   key: x.data[0]._id,
    //   projectName: x.data[0].title,
    //   totalHours: `${Math.trunc(x.hours)} hrs ${Math.round(
    //     (x.hours - Math.trunc(x.hours)) * 60,
    //     0
    //   )} mins`,
    //   totalAmount: `PKR ${Math.round(x.cost)}`,
    // }));
  }
  //   console.log(dataSource);
  return (
    // projectTable?.result?.data?.projectWiseData?.map

    <Table
      columns={columns}
      expandable={{
        expandRowByClick: true,
        expandedRowRender,
        //   defaultExpandedRowKeys: ["0"],
      }}
      dataSource={dataSource}
      pagination={false}
    />

    // <Table
    //   columns={columns}
    //   expandable={{
    //     expandRowByClick: true,
    //     expandedRowRender,
    //     //   defaultExpandedRowKeys: ["0"],
    //   }}
    //   dataSource={dataSource}
    //   pagination={false}
    // />
  )
}

export default ProjectResourceTable
