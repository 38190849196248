import { useState, useEffect } from 'react'
import { DatePicker, Typography, Row, Col, Table, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
// import { useHistory, useParams } from 'react-router-dom'
// import DatePicker from "antd";

import Layout from '../layout/Layout'
import { deleteExpenses, getExpenses } from '../redux'
import InfinityLoader from '../components/InfinityLoader'
import AddExpense from '../components/modal/AddExpense'
import { AiOutlineDelete } from 'react-icons/ai'

const ProjectDetail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [month, setMonth] = useState(moment().format('MMM, YYYY'))
  const [date, setDate] = useState(null)

  const { allExpenses, expenseTotal } = useSelector((state) => state.admin)
  const onMonthSelect = (value, datestring) => {
    let monthYear = datestring?.split('-')
    setMonth(
      moment()
        .set('month', monthYear[1] - 1)
        .set('year', monthYear[0])
        .format('MMM, YYYY'),
    )
    setDate(datestring)
  }
  useEffect(() => {
    dispatch(getExpenses(date))
  }, [date])

  return (
    <Layout active={'admin-expense'}>
      {allExpenses === null ? (
        <InfinityLoader />
      ) : (
        <div className='expense-main'>
          <Row gutter={[0, 40]}>
            <Col xs={24} md={12} lg={8}>
              <div className='expense-card'>
                <h2>{t('total-expenses-this-month')}</h2>
                <Typography.Title level={3} style={{ margin: '20px 0' }}>
                  {t('rs')} {expenseTotal.toLocaleString()}
                </Typography.Title>
              </div>
            </Col>
            <Col xs={24}>
              <div className='flex-between'>
                <Typography.Title
                  level={3}
                  style={{
                    marginBottom: '20px',
                  }}
                >
                  {month} {t('company-monthly-expenses')}
                </Typography.Title>
                <div>
                  <AddExpense newexpense={true} />
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={onMonthSelect}
                    style={{ width: '150px', marginLeft: '20px' }}
                    picker={'month'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>
              <Table
                size='middle'
                bordered={false}
                pagination={false}
                columns={[
                  {
                    title: t('date'),
                    dataIndex: 'date',
                  },
                  {
                    title: t('amount'),
                    dataIndex: 'amount',
                  },
                  {
                    title: t('description'),
                    dataIndex: 'description',
                    width: '50%',
                  },
                  {
                    title: '',
                    dataIndex: 'action',
                  },
                ]}
                dataSource={allExpenses?.map((expense) => ({
                  date: moment(expense.CreatedAt).format('DD-MMM-YYYY'),
                  amount: `${t('rs')} ` + expense.amount,
                  description: expense.description,
                  action: (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '50px',
                      }}
                    >
                      <AddExpense newexpense={false} data={expense} />
                      <Button
                        style={{
                          color: 'red',
                          border: 'none',
                          background: 'none',
                          padding: '6px 0',
                          marginLeft: '10px',
                        }}
                        onClick={() => {
                          dispatch(deleteExpenses(expense._id))
                        }}
                      >
                        <AiOutlineDelete
                          style={{
                            fontSize: '20px',
                          }}
                        />
                      </Button>
                    </div>
                  ),
                }))}
              />
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  )
}

export default ProjectDetail
