import { useState, useEffect } from 'react'
// import { Table } from 'antd'
// import moment from 'moment'
// import { useTranslation } from 'react-i18next'
// import { timeFormatter } from '../functions/timeFormatter'
import { Space, Table } from 'antd'
import moment from 'moment'
// import TableCard from './view/TableCard'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Designation',
    dataIndex: 'designation',
    key: 'designation',
    // width: '12%',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    // width: '30%',
    key: 'phone',
  },
  {
    title: 'Checkin Time Today',
    dataIndex: 'checkin_time',
    // width: '30%',
    key: 'checkin_time',
  },
]

const subColumns = [
  {
    title: 'Date',
    dataIndex: 'date',
    // width: '30%',
    key: 'date',
  },
  {
    title: 'Checkin',
    dataIndex: 'checkin',
    // width: '30%',
    key: 'checkin',
    render: (val) => {
      let momentVariable = moment(val).format('h:mm:ss a')
      return <>{momentVariable !== 'Invalid date' ? momentVariable : '-'}</>
    },
  },
  {
    title: 'Checkout',
    dataIndex: 'checkout',
    // width: '30%',
    key: 'checkout',
    render: (val) => {
      let momentVariable = moment(val).format('h:mm:ss a')
      return <>{momentVariable !== 'Invalid date' ? momentVariable : '-'}</>
    },
  },
  {
    title: 'Hours',
    dataIndex: 'hours',
    // width: '30%',
    key: 'hours',
  },
  {
    title: 'Time',
    dataIndex: 'time',
    // width: '30%',
    key: 'time',
  },
]
// const data = (tableData) => {
//   // console.log("tableData:", tableData.response)
//   tableData.response.map((data) => {
//     console.log(data.employee._id)
//   })
//   return [
//     {
//       key: 1,
//       name: 'John Brown sr.',
//       designation: 60,
//       phone: 'New York No. 1 Lake Park',
//       ceckin_time: 'New York No. 1 Lake Park',
//       children: [
//         {
//           key: 11,
//           name: 'John Brown',
//           age: 42,
//           address: 'New York No. 2 Lake Park',
//         },
//         {
//           key: 12,
//           name: 'John Brown jr.',
//           age: 30,
//           address: 'New York No. 3 Lake Park',
//           children: [
//             {
//               key: 121,
//               name: 'Jimmy Brown',
//               age: 16,
//               address: 'New York No. 3 Lake Park',
//             },
//           ],
//         },
//       ],
//     },
//   ]
// }

// const rowSelection = {
//   onChange: (selectedRowKeys, selectedRows) => {
//     console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
//   },
//   onSelect: (record, selected, selectedRows) => {
//     console.log(record, selected, selectedRows);
//   },
//   onSelectAll: (selected, selectedRows, changeRows) => {
//     console.log(selected, selectedRows, changeRows);
//   },
// };
const AttendanceCard = ({ tableData }) => {
  const [data, setData] = useState()
  useEffect(() => {
    let tempDataArr = tableData?.response?.map((item, index) => {
      return {
        key: index,
        name: item?.employee?.name,
        phone: item?.employee?.phone,
        designation: item?.employee?.designation,
        checkin_time: item?.todayAttendance?.start
          ? moment(item?.todayAttendance?.start).format('h:mm:ss a')
          : item?.todayAttendance?.message,
        last7Day: item?.last7Day,
      }
    })
    setData(tempDataArr)
    // setData(tableData.response)
  }, [])
  // const { t } = useTranslation()
  // const [detail, setDetail] = useState(false)
  // let colors = ['orange', 'red', 'cyan', 'green', 'purple', 'blue', 'primary', 'yellow']

  return (
    <>
      <Space
        align='center'
        style={{
          marginBottom: 16,
        }}
      ></Space>
      <Table
        columns={columns}
        // rowSelection={{
        //   ...rowSelection,
        // }}
        expandedRowRender={(record) => {
          // console.log(record)
          // console.log('click row for expand')
          return <Table columns={subColumns} dataSource={record?.last7Day} />
        }}
        rowExpandable={() => true}
        dataSource={data}
      />
    </>
  )
  // return (
  // <div className={`${colors[Math.floor(Math.random() * colors.length)]} attendance-card`}>
  //   <div
  //     style={{
  //       display: 'flex',
  //       alignItems: 'center',
  //     }}
  //   >
  //     <span className='circle'>{Data.employee.name[0]}</span>
  //     <div>
  //       <h3
  //         style={{
  //           margin: '0',
  //         }}
  //       >
  //         {Data.employee.name} ({Data.employee.designation})
  //       </h3>
  //       <h4
  //         style={{
  //           margin: '0',
  //         }}
  //       >
  //         {Data.employee.phone}
  //       </h4>
  //     </div>
  //   </div>

  //   <h4 style={{ fontWeight: 'bold', marginLeft: '59px' }}>
  //     {t('checkin-time-today')} :{' '}
  //     {Data.todayAttendance.message ? (
  //       <span>{Data.todayAttendance.message}</span>
  //     ) : (
  //       <div
  //         style={{
  //           display: 'flex',
  //           // alignItems: "center",
  //           // justifyContent: "center",
  //           flexDirection: 'column',
  //           marginLeft: '20px',
  //         }}
  //       >
  //         <span>
  //           {t('local')}: {moment(Data.todayAttendance.start).local().format('hh:mm a')}{' '}
  //         </span>
  //         {Data.todayAttendance.zone && moment.tz.guess(true) !== Data.todayAttendance.zone && (
  //           <span>
  //             {t('employee')} ({Data.todayAttendance.zone}):{' '}
  //             {moment(Data.todayAttendance.start).tz(Data.todayAttendance.zone).format('hh:mm a')}{' '}
  //           </span>
  //         )}
  //       </div>
  //     )}
  //   </h4>

  //   <div
  //     style={{
  //       display: 'flex',
  //       justifyContent: 'flex-end',
  //       marginBottom: 10,
  //       marginRight: 10,
  //     }}
  //   >
  //     <a
  //       // type="primary"
  //       onClick={() => {
  //         setDetail(!detail)
  //       }}
  //       style={{
  //         width: 'max-content',
  //         height: 'max-content',
  //         border: 'none',
  //         fontDecoration: 'underline',
  //       }}
  //     >
  //       {detail ? 'Hide' : 'Show details'}
  //     </a>
  //   </div>
  //   {detail && (
  //     <div>
  //       <h3>{t('last-7-days-checkin-and-hours')}</h3>
  //       <Table
  //         columns={[
  //           {
  //             title: t('date'),
  //             dataIndex: 'date',
  //           },
  //           {
  //             title: 'Check In',
  //             dataIndex: 'checkin',
  //           },
  //           {
  //             title: 'Check Out',
  //             dataIndex: 'checkout',
  //           },
  //           {
  //             title: t('hours'),
  //             dataIndex: 'hours',
  //           },
  //         ]}
  //         pagination={false}
  //         size='small'
  //         scroll={{ x: 'auto' }}
  //         dataSource={Data?.last7Day?.map((data) => ({
  //           date: (
  //             <div
  //               style={{
  //                 display: 'flex',
  //                 alignItems: 'center',
  //               }}
  //             >
  //               <span
  //                 style={{
  //                   width: '30px',
  //                   height: '30px',
  //                   borderRadius: '50%',
  //                   backgroundColor: data.hours <= 8 ? 'lightgreen' : 'var(--primary)',
  //                   display: 'flex',
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   color: 'black',
  //                 }}
  //               >
  //                 {moment(data.date).local().format('dd')}
  //               </span>
  //               <div
  //                 style={{
  //                   display: 'flex',
  //                   flexDirection: 'column',
  //                 }}
  //               >
  //                 <span
  //                   style={{
  //                     marginLeft: '5px',
  //                     fontWeight: 'bold',
  //                     fontSize: '10px',
  //                   }}
  //                 >
  //                   {moment(data.date).local().format('DD/MM/YYYY')}
  //                 </span>
  //                 {/* <span
  //                   style={{
  //                     marginLeft: '5px',
  //                     fontWeight: 'bold',
  //                     fontSize: '10px',
  //                   }}
  //                 >
  //                   {data.time === '-'
  //                     ? '---'
  //                     : `Local: ${moment(data.time.start).local().format('hh:mm a')}`}
  //                 </span> */}
  //               </div>
  //             </div>
  //           ),
  //           checkin: data?.checkin ? moment(data?.checkin).format('hh:mm a') : '--',
  //           checkout: data?.checkout ? moment(data?.checkout).format('hh:mm a') : '--',
  //           hours: timeFormatter(data.hours),
  //         }))}
  //       />
  //     </div>
  //   )}
  // </div>
  // )
}

export default AttendanceCard
