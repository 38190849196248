import { Tooltip } from 'antd'
import { RiDeleteBin3Line } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import moment from 'moment'

import { deleteNotice } from '../../redux'
// import EmptySvg from '../../assets/svg/empty/emptySvg'

const NoticeBoard = ({ allNotice, apiCall }) => {
  const userType = localStorage.getItem('userType')
  const dispatch = useDispatch()

  const callDeleteNotice = async (id) => {
    try {
      const result = await dispatch(deleteNotice(id))
      if (result) {
        apiCall()
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  return (
    <div className='notice-board'>
      {allNotice?.length > 0 ? (
        allNotice?.map((notice, ind) => (
          <div className='item' key={ind}>
            <span className='notice'>{notice?.notice}</span>
            <span className='date'>{moment(notice?.createdAt).format('DD-MM-YYYY')}</span>
            <Tooltip placement='top' title={'Delete'}>
              {userType === 'admin' && (
                <RiDeleteBin3Line
                  className='icon'
                  onClick={() => {
                    callDeleteNotice(notice?._id)
                  }}
                />
              )}
            </Tooltip>
          </div>
        ))
      ) : (
        // <EmptySvg height={'60vh'} iconHeight={'200px'} />
        <></>
      )}
    </div>
  )
}

export default NoticeBoard
