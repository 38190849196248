import { useState } from 'react'
import { Modal, Button, Form, Input, Row, Col, DatePicker, notification } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { createLeaveRequest } from '../../redux'

const LeaveRequestModal = ({ apiCall, data }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      let res = await dispatch(createLeaveRequest(values))
      if (res?.message) {
        notification.success({
          message: res?.message,
          duration: 3,
        })
        setLoading(false)
        form.resetFields()
        apiCall()
        setIsModalVisible(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const chechActiveDate = (current) => {
    let activeCheck = ''
    data?.map((dates) => {
      if (
        moment(dates?.date).format('YYYY-MM-DD') === moment(new Date(current)).format('YYYY-MM-DD')
      ) {
        activeCheck = true
      }
    })
    return activeCheck ? true : false
  }

  return (
    <>
      <Button type='primary' className='addModeratorBtn' onClick={showModal}>
        {t('leave-request')}
      </Button>

      <Modal
        title={t('leave-request')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Form layout='vertical' onFinish={onFinish} form={form}>
          <Row gutter={[20, 0]}>
            <Col xs={24}>
              <Form.Item
                label={t('date')}
                name={'dates'}
                rules={[
                  {
                    required: true,
                    message: t('date-required'),
                  },
                ]}
                requiredMark={'optional'}
              >
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={(d) => {
                    return d?.isBefore(moment().subtract(1, 'days'))
                  }}
                  dateRender={(current) => {
                    const style = {}
                    if (chechActiveDate(current)) {
                      style.backgroundColor = '#EC7063'
                    }
                    return (
                      <div className='ant-calendar-date' style={style}>
                        {current.date()}
                      </div>
                    )
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label={t('reason')}
                name={'reason'}
                rules={[
                  {
                    required: true,
                    message: t('reason-required'),
                  },
                ]}
                requiredMark={'optional'}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {t('request')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default LeaveRequestModal
