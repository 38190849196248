import { Button, Divider, Input, Typography } from 'antd'
import React from 'react'
import swal from 'sweetalert'
import Layout from '../layout/Layout'
import '../styles/pages/CreateNoti.css'
import { useDispatch } from 'react-redux'
import { createNotification } from '../redux/actions/notifications'
import { useTranslation } from 'react-i18next'
const CreateNotifications = () => {
  const { t } = useTranslation()
  const [noti, setNoti] = React.useState('')
  const dispatch = useDispatch()
  const createNoti = () => {
    if (noti === '') {
      return swal('Error', 'Please enter a notification', 'error')
    }
    dispatch(createNotification(noti))
    setNoti('')
  }
  return (
    <Layout>
      <div
        className='noti-add-cont'
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Typography.Title level={2}>{t('create-notification')}</Typography.Title>
        <Divider></Divider>
        <Input
          value={noti}
          placeholder={t('enter-notification')}
          onChange={(e) => setNoti(e.target.value)}
        />
        <Button type='primary' onClick={createNoti}>
          {t('create')}
        </Button>
      </div>
    </Layout>
  )
}

export default CreateNotifications
