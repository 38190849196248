import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { hoursToString } from '../../functions/timeFormatter'

const EmployeeCostingPdf = (
  projectData,
  month,
  employee,
  totalHours,
  salary,
  pdfname,
  expense,
  projectWiseData,
  perHourExpenseRate,
) => {
  var doc = new jsPDF()
  // doc.addImage(blueLogo, "PNG", 60, 10, 80, 10);

  doc.setTextColor('#F55139')
  doc.setFontSize(20)
  let textX =
    (doc.internal.pageSize.getWidth() - doc.getTextWidth(`${month} Day Wise Employee Summary`)) / 2
  doc.text(`${month} Day Wise Employee Summary`, textX, 30)

  doc.setTextColor('#000000')
  doc.setFontSize(14)
  doc.text(10, 45, `Company : `)
  doc.text(35, 45, `${localStorage.getItem('company')} `)
  doc.text(10, 55, 'Employee Name : ')
  doc.text(52, 55, `${employee?.name}`)

  doc.text(138, 55, 'Total Hours : ')
  doc.text(168, 55, `${Number(totalHours || 0)?.toFixed(2)}`)

  doc.text(10, 65, 'Email : ')
  doc.text(28, 65, `${employee?.email}`)

  doc.text(138, 65, 'Salary : ')
  doc.text(158, 65, `Rs. ${salary}`)

  doc.text(10, 75, 'Phone : ')
  doc.text(29, 75, `${employee?.phone}`)
  if (expense) {
    doc.text(138, 75, 'Total Cost : ')
    doc.text(168, 75, `Rs. ${Math.round(Number(perHourExpenseRate) * Number(totalHours))}`)
  }

  var tHeaders = ['Day', 'Project', 'Hours', 'Cost']
  var tData = []
  projectData.map((data) => {
    tData.push([
      moment(data?.CreatedAt).local().format('DD-MM-YYYY'),
      data?.project,
      hoursToString(Number(data?.hours)),
      Number(perHourExpenseRate * data?.hours).toFixed(0),
    ])
  })
  doc.autoTable({
    head: [tHeaders],
    headStyles: {
      fillColor: [255, 0, 0],
    },
    body: tData,
    startY: 80,
    // startX: 40,
    theme: 'grid',
    // margins: { top: 70 },
    styles: {
      // marginTop: "200px",
      fontSize: 14,
      overflow: 'linebreak',
      columnWidth: 'wrap',
    },
    columnStyles: {
      0: { columnWidth: 40 },
      1: { columnWidth: 55 },
      2: { columnWidth: 40 },
      3: { columnWidth: 40 },
    },
  })
  // Header
  // doc.line(10, 60, 190, 60);

  // doc.line(10, 60, 10, 75);
  // doc.line(50, 60, 50, 70);
  // doc.line(160, 60, 160, 70);
  // doc.line(190, 60, 190, 75);

  // doc.line(10, 70, 190, 70);

  // // // Coloumns Break

  // doc.setFontSize(14);
  // doc.text(17, 67, "Day");
  // doc.text(53, 67, "Project");
  // doc.text(140, 67, "Hours");
  // doc.text(167, 67, "Cost");

  // doc.setFontSize(13);
  // var newXAxis = 71;

  // projectData?.map((data) => {
  //   if (newXAxis >= 280) {
  //     doc.addPage();
  //     doc.line(10, 20, 190, 20);
  //     doc.line(10, 20, 10, 45);
  //     // doc.line(120, 20, 120, 45);

  //     //right sides
  //     doc.line(85, 20, 85, 45);
  //     doc.line(190, 20, 190, 45);

  //     newXAxis = 20;
  //   }
  //   newXAxis += 10;

  //   //   // bottom Line
  //   doc.line(10, newXAxis, 190, newXAxis);
  //   // Left Side Line
  //   doc.line(10, newXAxis, 10, 60);

  //   // Right Side Lines
  //   doc.line(50, 60, 50, newXAxis);
  //   doc.line(135, 60, 135, newXAxis);
  //   doc.line(160, 60, 160, newXAxis);
  //   doc.line(190, 60, 190, newXAxis);

  //   doc.text(
  //     17,
  //     newXAxis - 3,
  //     `${moment(data?.CreatedAt).format("DD-MM-YYYY")}`
  //   );
  //   doc.text(
  //     53,
  //     newXAxis - 3,
  //     `${
  //       data?.project?.title?.length > 35
  //         ? `${data?.project?.title?.slice(0, 35)}...`
  //         : data?.project?.title
  //     } `
  //   );
  //   doc.text(140, newXAxis - 3, `${data?.hours}`);
  //   doc.text(165, newXAxis - 3, "Rs. " + `${data?.cost?.toFixed(0)}`);
  // });

  if (projectWiseData) {
    doc.addPage()
    doc.setTextColor('#F55139')
    doc.setFontSize(20)
    let textx =
      (doc.internal.pageSize.getWidth() -
        doc.getTextWidth(`${month} Project Wise Employee Summary`)) /
      2
    doc.text(`${month} Project Wise Employee Summary`, textx, 16)
    doc.setFontSize(14)
    doc.setTextColor('#000000')
    var headers = ['Project', 'Hours', 'Cost']
    var rows = []
    projectWiseData?.map((data) => {
      // var rows = [
      // ];
      rows.push([
        data.data[0]?.title,
        hoursToString(data?.hours),
        'Rs. ' + `${Number(perHourExpenseRate * data?.hours).toFixed(0)}`,
      ])

      // doc.addPage();
    })
    doc.autoTable({
      head: [headers],
      headStyles: {
        fillColor: [255, 0, 0],
      },
      body: rows,
      startY: 30,
      theme: 'grid',
      styles: {
        fontSize: 14,
        overflow: 'linebreak',
        columnWidth: 'wrap',
      },
      columnStyles: {
        0: { columnWidth: 90 },
        1: { columnWidth: 40 },
        2: { columnWidth: 40 },
      },
    })
  }

  return doc.save(`${pdfname}`)
}

export default EmployeeCostingPdf
