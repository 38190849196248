import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Calendar, Tooltip } from 'antd'

import Layout from '../../layout/Layout'
import InfinityLoader from '../../components/InfinityLoader'
import LeaveRequestModal from '../../components/modal/LeaveRequestModal'
import { useDispatch } from 'react-redux'
import { getAllEmployeeLeaves } from '../../redux'
import moment from 'moment'

const Leave = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  const getAllLeaves = async () => {
    try {
      const result = await dispatch(getAllEmployeeLeaves())
      if (result) {
        setData(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    getAllLeaves()
  }, [])

  const dateCellRender = (value) => {
    const listData = getListData(moment(new Date(value)).format('YYYY-MM-DD'))

    return (
      <ul className='leave'>
        {listData.map((item, ind) => (
          <div
            key={ind}
            // className={item?.status === 'Pending' ? 'pending date-box' : 'active date-box'}
            className={`date-box ${item?.status == 'Pending' ? 'pending' : ''} ${
              item?.status == 'Approved' ? 'active' : ''
            } ${item?.status == 'Rejected' ? 'rejected' : ''}`}
          >
            <p>{item?.reason?.length > 20 ? `${item?.reason?.slice(0, 20)}...` : item?.reason}</p>
          </div>
        ))}
      </ul>
    )
  }

  const getListData = (value) => {
    let listData
    data?.map((leave) => {
      switch (value) {
        case moment(leave?.date).format('YYYY-MM-DD'):
          listData = [{ reason: leave?.reason, status: leave?.status }]
          break
        default:
      }
    })
    return listData || []
  }

  return (
    <Layout active={'employee-leave'}>
      {data === null ? (
        <InfinityLoader />
      ) : (
        <div className='leave-container'>
          <div className='header'>
            <Typography.Title level={4}>{t('leave')}</Typography.Title>
            <LeaveRequestModal apiCall={getAllLeaves} data={data} />
          </div>
          <div>
            <Calendar mode='month' dateCellRender={dateCellRender} />
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Leave
