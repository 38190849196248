import { OrganizationGraph } from '@ant-design/graphs'
import { useSelector } from 'react-redux'

const OrganizationChart = ({ data }) => {
  const theme = useSelector((state) => state.theme.theme)

  return (
    <OrganizationGraph
      data={data}
      style={{
        backgroundColor: 'var(--background)',
      }}
      animate={true}
      edgeCfg={{
        endArrow: () => {
          return {
            fill: '#F55139',
          }
        },
        edgeStateStyles: () => {
          return {
            hover: {
              stroke: '#ee3d21',
            },
          }
        },
        style: (node) => {
          return {
            active: {
              stroke: '#F55139',
            },
            highlight: {
              stroke: '#ee3d21',
            },
            hover: {
              stroke: '#ee3d21',
            },
            selected: {
              stroke: '#F55139',
            },
            inactive: {
              stroke: '#F55139',
            },
            // stroke: '#F55139',
          }
        },
      }}
      nodeCfg={{
        style: (node) => {
          return node?.value?.type === 'admin'
            ? {
                fill: '#F55139',
                stroke: '#F55139',
              }
            : {
                fill: theme === 'dark' ? '#212528' : '#fbfbfb',
                stroke: '#F55139',
              }
        },
        label: {
          style: (node, group, type) => {
            const styles = {
              name: {
                fill: theme === 'dark' ? '#EBE7D9' : '#1C2833',
              },
              title: {
                fill: theme === 'dark' ? '#EBE7D9' : '#1C2833',
              },
            }
            return node?.value?.type === 'admin' ? styles[type] : styles[type]
          },
        },
        nodeStateStyles: {
          hover: {
            stroke: theme === 'dark' ? '#fbfbfb' : '#212528',
          },
        },
      }}
    />
  )
}
export default OrganizationChart
