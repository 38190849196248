import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Tabs, Calendar, Badge } from 'antd'
import moment from 'moment'

import Layout from '../../layout/Layout'
import InfinityLoader from '../../components/InfinityLoader'
import { getAllBirthdays, getAllNotice } from '../../redux'
import NoticeBoard from '../../components/view/NoticeBoard'
import CreateNoticeModal from '../../components/modal/CreateNoticeModal'

const AdminAnnouncement = () => {
  const { TabPane } = Tabs
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userType = localStorage.getItem('userType')
  const [birthDayData, setBirthDayData] = useState(null)
  const [allNotice, setAllNotice] = useState(null)
  const [activeTab, setActiveTab] = useState('notice')

  const getAllBirthdaysData = async () => {
    try {
      const result = await dispatch(getAllBirthdays(userType))
      if (result) {
        setBirthDayData(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  const getAllNoticeData = async () => {
    try {
      const result = await dispatch(getAllNotice(userType))
      if (result) {
        setAllNotice(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    getAllBirthdaysData()
    getAllNoticeData()
  }, [])

  //   Calendar Function
  const dateCellRender = (value) => {
    const listData = getListData(moment(new Date(value)).format('MM-DD'))
    return (
      <ul className='birthday'>
        {listData?.map((item, ind) => (
          <li key={ind} style={{ width: '100%', paddingInline: '10px' }}>
            <Badge status='default' text={`${item?.name} ${t('birthday')}`} />
          </li>
        ))}
      </ul>
    )
  }

  //   Calendar Function
  const getListData = (value) => {
    let listData = []
    birthDayData?.map((leave) => {
      if (leave?.dateOfBirth && value === moment(leave?.dateOfBirth).format('MM-DD')) {
        listData.push({ name: leave?.name })
      }
    })
    return listData || []
  }

  return (
    <Layout active={'admin-annoucement'}>
      {birthDayData === null ? (
        <InfinityLoader />
      ) : (
        <div className='annoucement-container'>
          <Tabs
            defaultActiveKey='notice'
            animated
            tabBarExtraContent={
              activeTab === 'notice' &&
              userType === 'admin' && <CreateNoticeModal callApi={getAllNoticeData} />
            }
            onChange={(e) => setActiveTab(e)}
          >
            <TabPane tab={t('notice-board')} key='notice' style={{ padding: '20px 0' }}>
              <NoticeBoard allNotice={allNotice} apiCall={getAllNoticeData} />
            </TabPane>
            <TabPane tab={t('birthday')} key='birthday' style={{ padding: '20px 0' }}>
              <Calendar mode='month' dateCellRender={dateCellRender} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default AdminAnnouncement
