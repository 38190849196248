import { useEffect } from 'react'
import { Col, Divider, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Layout from '../layout/Layout'
import { getAllNotifications } from '../redux/actions/notifications'
import NotiCard from '../components/NotiCard'

const Notifications = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { allNotifications } = useSelector((state) => state.notifications)

  useEffect(() => {
    dispatch(getAllNotifications())
  }, [])

  return (
    <Layout active={'notification'}>
      <div className='noti-cont'>
        <Typography.Title level={2}>{t('notifications')}</Typography.Title>
        <Divider></Divider>
        <Row className='noti-box' justify='space-around' gutter={[16, 16]}>
          {allNotifications.gNotifications.map((noti, ind) => {
            return (
              <Col md={11} xs={24} className='noti-caed' key={ind}>
                <NotiCard data={noti} key={ind} />
              </Col>
            )
          })}
          {allNotifications.notifications.map((noti, ind) => {
            return (
              <Col md={11} xs={24} className='noti-caed' key={ind}>
                <NotiCard data={noti} key={ind} />
              </Col>
            )
          })}
        </Row>
      </div>
    </Layout>
  )
}

export default Notifications
