import Axios from 'axios'

// export const base_url = 'https://backend.trackkrr.com'
// export const base_url = "https://trackingbackend.herokuapp.com";
// export const base_url = 'https://tracker-backend2k.herokuapp.com/'
// export const base_url = 'https://stagingbackend.trackkrr.com'
export const base_url = 'https://backend.decotechs.xyz'
// export const base_url = 'http://localhost:5000'

export const publicAPI = Axios.create({ baseURL: base_url })

// publicAPI.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     if (error.response.status === 999) {
//       localStorage.removeItem('token')
//       window.location.href = '/login'
//     }
//     return Promise.reject(error)
//   }
// )
// export default publicAPI
export const privateAPI = Axios.create({ baseURL: base_url })

privateAPI.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 999) {
      localStorage.removeItem('token')
      window.location.href = '/'
    }
    return Promise.reject(error)
  },
)
export default privateAPI

export const attachToken = async () => {
  const jwt = localStorage.getItem('token')
  privateAPI.defaults.headers.common.Authorization = `Bearer ${jwt}`
}
