import { Button, Col, DatePicker, notification, Row, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import ColumnChart from '../components/Charts/ColumnChart'
import LineChart from '../components/Charts/LineChart'
import InfinityLoader from '../components/InfinityLoader'
import EmployeeCostingPdf from '../components/PdfTemplate/EmployeeCostingPdf'
import Layout from '../layout/Layout'
import {
  getEmployeeDailyCosting,
  getEmployeeCosting,
  getEmployeeDailyCostingOwnAdmin,
  getEmployeeSalaries,
  getTimelineComparison,
} from '../redux'
import { useTranslation } from 'react-i18next'
import ColumnChartCompare from '../components/Charts/ColumnChartCompare'

const EmployeeSummary = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [DailyData, setDailyData] = useState(null)
  const [costingData, setcostingData] = useState(null)
  const [salaries, setSalaries] = useState([])
  const [selectedYear, setselectedYear] = useState(moment().format('YYYY'))
  const [SelectedMonth, setSelectedMonth] = useState(moment().format('MMMM'))
  // const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-M'))
  const [pdfMonth, setpdfMonth] = useState(null)
  const [pdfLink, setPdfLink] = useState(null)
  const [tableData, settableData] = useState(null)
  const [pdfLoader, setpdfLoader] = useState(false)
  const [compareData, setcompareData] = useState([])
  const [sortedCheckIns, setsortedCheckIns] = useState()
  const [sortedTimelines, setsortedTimelines] = useState()
  const [compareTableData, setcompareTableData] = useState()
  const [waitingResponse, setwaitingResponse] = useState(false)

  const GetData = async () => {
    const dailyResponse = await dispatch(getEmployeeDailyCosting(id))
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      }
    })
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    })

    const costResponse = await dispatch(getEmployeeCosting(id))
    setcostingData(costResponse)

    const salary = await dispatch(getEmployeeSalaries(id))
    setSalaries(salary)
  }

  useEffect(() => {
    GetData()
    // OnCompareMonthChange(SelectedMonth);
  }, [])

  // useEffect(() => {
  //   OnCompareMonthChange()
  // }, [SelectedMonth])

  const getDatesOfTheCurrentMonth = () => {
    // console.log(
    //   "getDatesOfTheCurrentMonth",
    //   moment().month(SelectedMonth).format("M")
    // );
    // console.log("Selected Year", selectedYear);
    const days = moment(
      `${selectedYear}-${moment().month(SelectedMonth).format('MM')}`,
      'YYYY-MM',
    ).daysInMonth()
    // const days = moment().daysInMonth("January");
    let datesArr = []
    for (let i = 1; i <= days; i++) {
      datesArr.push(
        `${selectedYear}-${moment().month(SelectedMonth).format('MM')}-${i <= 9 ? `0${i}` : i}`,
      )
    }
    // console.log('datesArr :>> ', datesArr)
    return datesArr
  }

  if (SelectedMonth === 'Invalid date') {
    // console.log('Check me')
    setSelectedMonth(moment().format('MMMM'))
  }

  const OnCompareMonthChange = async (date, month) => {
    // console.log("Compare Month Change Called");
    // setSelectedMonth(moment(value).format("MMMM"));
    // setpdfMonth(moment(value).format("MM"));
    // console.log('month', month)
    setwaitingResponse(true)
    const compareResponse = await getTimelineComparison({
      id: id,
      month: Number(moment().month(month).format('M')),
      date: date,
    })
    setwaitingResponse(false)
    const { checkins, timelines } = compareResponse

    // console.log("checkins", checkins, "timelines", timelines);
    let tempdate = []
    let result = []

    compareResponse &&
      checkins?.map((data) => {
        let ind = tempdate.indexOf(data.start.slice(0, 10))
        // console.log(ind)
        if (ind === -1) {
          // console.log('in if')
          tempdate.push(data.start.slice(0, 10))
          result.push({
            name: 'Checkins',
            date: data.start.slice(0, 10),
            hours: Number(data?.hours?.toFixed(2)),
          })
        } else {
          result[ind].hours += Number(data?.hours?.toFixed(2))
        }
      })

    let tempdatetimeline = []
    let resulttimeline = []

    compareResponse &&
      timelines?.map((data) => {
        let ind = tempdatetimeline.indexOf(data.CreatedAt.slice(0, 10))
        // console.log(ind)
        if (ind === -1) {
          // console.log('in if')
          tempdatetimeline.push(data.CreatedAt.slice(0, 10))
          resulttimeline.push({
            name: 'Timelines',
            date: data.CreatedAt.slice(0, 10),
            hours: Number(data?.hours?.toFixed(2)),
          })
        } else {
          resulttimeline[ind].hours += Number(data?.hours?.toFixed(2))
        }
      })

    // give the dates array to this function it will give out the hours on those dates.
    // function findHoursOnDates(name, fullMonthArray, onProperty) {
    //   let finalArray = []
    //   let totalHoursofCurrDate = 0
    //   getDatesOfTheCurrentMonth(SelectedMonth).forEach((x) => {
    //     fullMonthArray
    //       .filter((y) => {
    //         // console.log(y[onProperty].substring(0, 10));
    //         return y[onProperty].substring(0, 10) === x
    //       })
    //       .forEach((f) => {
    //         // console.log(typeof f.hours);
    //         totalHoursofCurrDate += f.hours
    //       })
    //     console.log('totalHoursofCurrDate :>> ', totalHoursofCurrDate)
    //     const hours = `${Math.trunc(totalHoursofCurrDate)}.${Math.trunc(
    //       Number((totalHoursofCurrDate - Math.floor(totalHoursofCurrDate)).toFixed(2)) * 60,
    //     )}`
    //     totalHoursofCurrDate = 0
    //     finalArray.push({ name, date: x, hours: Number(hours) })
    //   })
    //   return finalArray
    // }

    const checkinsTemp = result
    const timelinesTemp = resulttimeline

    // const checkinsTemp = findHoursOnDates('Checkins', checkins, 'createdAt', checkinDates)
    // const timelinesTemp = findHoursOnDates('Timelines', timelines, 'CreatedAt', timelineDates)
    setsortedCheckIns(checkinsTemp)
    setsortedTimelines(timelinesTemp)

    // console.log('checkinsTemp', checkinsTemp)
    // console.log('timelinesTemp', timelinesTemp)

    if (checkinsTemp.length && timelinesTemp.length) {
      // console.log('compare response is working')
      setcompareData([...checkinsTemp, ...timelinesTemp])
    } else if (checkinsTemp.length) {
      setcompareData([...checkinsTemp])
    } else if (timelinesTemp.length) {
      setcompareData([...timelinesTemp])
    } else {
      setcompareData([])
    }
    //   let dailyResponse2 = dailyResponse?.costing?.map((data) => {
    //     return {
    //       ...data,
    //       day: String(data.day),
    //     };
    //   });
    //   setDailyData({
    //     ...dailyResponse,
    //     costing: dailyResponse2,
    //   });
  }

  const compareColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Checkins',
      dataIndex: 'checkins',
    },
    {
      title: 'Timeline',
      dataIndex: 'timelines',
    },
  ]

  const setTableData = () => {
    let tempCompareTable = []
    getDatesOfTheCurrentMonth(SelectedMonth).forEach((x, index) => {
      let obj = { key: index, date: x, checkins: '-', timelines: '-' }
      // if this date is avaialable in timeline set the timelineHours property to hours property.
      sortedCheckIns.length &&
        sortedCheckIns.forEach((i) => {
          if (i.date === x) {
            // this condition set the data only when it is there, otherwise the - goes in the table
            if (i.hours > 0) {
              const mins = `${i.hours - Math.floor(i.hours)}`
              if (mins !== '0') {
                obj.checkins = `${Math.trunc(i.hours)} hrs ${Math.round(Number(mins) * 60)} mins`
              } else {
                obj.timelines = `${Math.trunc(i.hours)} hrs`
              }
            }
          }
        })
      sortedTimelines.length &&
        sortedTimelines.forEach((i) => {
          if (i.date === x) {
            if (i.hours > 0) {
              const mins = `${i.hours - Math.floor(i.hours)}`
              if (mins !== '0') {
                obj.timelines = `${Math.trunc(i.hours)} hrs ${Math.round(Number(mins) * 60)} mins`
              } else {
                obj.timelines = `${Math.trunc(i.hours)} hrs`
              }
            }
          }
        })
      tempCompareTable.push(obj)
    })
    // console.log('tempCompareTable :>> ', tempCompareTable)
    setcompareTableData(tempCompareTable)
  }
  // console.log(tempCompareTable);
  // setcompareTableData(tempCompareTable);

  useEffect(() => {
    sortedCheckIns && sortedTimelines && setTableData()
  }, [sortedCheckIns, sortedTimelines])

  const OnDailyDateChange = async (value, datestring) => {
    setSelectedMonth(moment(value).format('MMMM'))
    // setSelectedDate(datestring)
    // setselectedYear(datestring?.split('-')[0])

    setpdfMonth(moment(value).format('MM'))
    const dailyResponse = await dispatch(
      getEmployeeDailyCosting(id, { month: moment(value).format('MM'), date: datestring }),
    )
    let dailyResponse2 = dailyResponse?.costing?.map((data) => {
      return {
        ...data,
        day: String(data.day),
      }
    })
    setDailyData({
      ...dailyResponse,
      costing: dailyResponse2,
    })
  }

  const OnCostingDateChange = async (value) => {
    setselectedYear(new Date(value).getFullYear().toString())
    const costResponse = await dispatch(
      getEmployeeCosting(id, { year: new Date(value).getFullYear().toString() }),
    )
    setcostingData(costResponse)
  }

  const handleDateChange = (value, datestring) => {
    // console.log('datestring :>> ', datestring)
    // setSelectedDate(datestring)
    setSelectedMonth(moment(value).format('MMMM'))
    setselectedYear(datestring?.split('-')[0])
    OnCompareMonthChange(datestring, moment(value).format('MMMM'))
  }

  useEffect(() => {
    let data = []
    costingData?.costing?.map((i) => {
      data.push({
        month: i.month,
        hours: i.hours,
        cost: 'Rs. ' + i.cost,
      })
    })
    settableData(data)
  }, [costingData])

  const columns = [
    {
      title: t('month'),
      dataIndex: 'month',
    },
    {
      title: t('hours'),
      dataIndex: 'hours',
    },
    {
      title: t('cost'),
      dataIndex: 'cost',
    },
  ]

  const getPdfGenerate = async () => {
    setpdfLoader(true)
    const dailyResponse = await dispatch(getEmployeeDailyCostingOwnAdmin(id, { month: pdfMonth }))
    if (dailyResponse.costing.length !== 0) {
      setPdfLink(
        EmployeeCostingPdf(
          dailyResponse?.costing,
          SelectedMonth,
          dailyResponse?.employee,
          dailyResponse?.totalHours,
          dailyResponse?.totalSalary,
          `${t('employee-costing-report')}.pdf`,
          dailyResponse?.expensePerEmployee,
          dailyResponse?.projectWiseData,
          dailyResponse?.employee?.hourlySalary,
        ),
      )
      setpdfLoader(false)
    } else {
      notification.error({
        description: t('no-entries-found'),
        duration: 2,
      })
      setpdfLoader(false)
    }
  }
  const moderator = JSON.parse(localStorage.getItem('moderator'))
  const type = localStorage.getItem('userType')
  const roles = moderator?.roles?.roles
  // useEffect(() => {}, [roles])

  // console.log('pdfLink', pdfLink)

  return (
    <Layout active={'admin-employees'}>
      {/* <Button
        onClick={() => {
          dispatch(
            getTimelineComparison({
              id: "633fd7582c150109fcc97d3a",
              month: 11,
            })
          );
        }}
      >
        API CALL
      </Button> */}
      {DailyData === null ? (
        <InfinityLoader />
      ) : type !== 'moderator' ? (
        <div className='summary-container'>
          <br />
          <Row gutter={[20, 40]} justify={'center'}>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('name')}: <span>{DailyData?.employee?.name}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('email')}: <span>{DailyData?.employee?.email}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('phone')}: <span>{DailyData?.employee?.phone}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('no-of-hours-per-day')}: <span>{DailyData?.employee?.noOfHours}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('days-per-month')} : <span>{DailyData?.employee?.daysPerMonth}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('designation')} : <span>{DailyData?.employee?.designation}</span>{' '}
              </h2>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            {/* Ali Edited Here */}

            <Col xs={24}>
              <div className='header'>
                <h3 className='summaryHeading'>
                  {SelectedMonth} {t('timeline to checkin comparison')}
                </h3>
                <div>
                  {DailyData && (
                    <Button
                      type='primary'
                      style={{ marginRight: '1rem' }}
                      onClick={() => getPdfGenerate()}
                      loading={pdfLoader}
                    >
                      {t('download-pdf')}
                    </Button>
                  )}
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={handleDateChange}
                    style={{ width: '200px' }}
                    picker={'month'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <div className='card' style={{ border: '1px solid white' }}>
                {waitingResponse && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%)',
                    }}
                  >
                    <InfinityLoader />
                  </div>
                )}
                <ColumnChartCompare data={compareData} yField='hours' xField='date' />

                <Table
                  style={{ marginTop: '30px' }}
                  columns={compareColumns}
                  dataSource={compareTableData}
                  size='small'
                  // pagination={{
                  //   position: ['topRight', ''],
                  // }}
                />

                {/* <LineChart
                  data={DailyData?.costing ? DailyData?.costing : []}
                  yField="dailyHours"
                  xField="day"
                /> */}
              </div>
            </Col>

            {/* Ali Edited Here */}
            <Col xs={24}>
              <div className='header'>
                <h3 className='summaryHeading'>
                  {SelectedMonth} {t('day-wise-daily-hours')}
                </h3>
                <div>
                  {DailyData && (
                    <Button
                      type='primary'
                      style={{ marginRight: '1rem' }}
                      onClick={() => getPdfGenerate()}
                      loading={pdfLoader}
                    >
                      {t('download-pdf')}
                    </Button>
                  )}
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={OnDailyDateChange}
                    style={{ width: '200px' }}
                    picker={'month'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <div className='card'>
                <LineChart
                  data={DailyData?.costing ? DailyData?.costing : []}
                  yField='dailyHours'
                  xField='day'
                />
              </div>
            </Col>
            <Col xs={24}>
              <div className='header header2'>
                <h3 className='summaryHeading '>
                  {t('year')} {selectedYear} {t('monthly-hours')}
                </h3>
                <DatePicker
                  placeholder={t('select-year')}
                  // onChange={(v) => {
                  //   setSelectedMonth(moment(v).format('MMMM'))
                  // }}
                  onChange={OnCostingDateChange}
                  picker='year'
                  style={{ width: '200px' }}
                  disabledDate={(d) => {
                    return d?.isAfter(moment())
                    // return moment(d).day() === 0 || d?.isAfter(moment());
                  }}
                />
              </div>
              <div className='card card2'>
                <ColumnChart
                  data={costingData?.costing ? costingData?.costing : []}
                  yField='hours'
                  xField='month'
                  alias
                />
              </div>
            </Col>
            <Col xs={24}>
              <br />
              <Table
                sticky
                dataSource={tableData}
                className='project-table'
                columns={columns}
                pagination={false}
              />
            </Col>
            <Col xs={24}>
              <div
                className='header'
                style={{
                  marginTop: '4rem',
                }}
              >
                <h3 className='summaryHeading'>{t('employee-salary-statistics')}</h3>
              </div>
              <div className='card'>
                <LineChart data={salaries ? salaries : []} yField='salary' xField='CreatedAt' />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        roles?.includes('employee-summary') !== false && (
          <div className='summary-container'>
            <br />
            <Row gutter={[20, 40]} justify={'center'}>
              <Col xs={24} md={12} xl={8}>
                <h2 className='label'>
                  {t('name')}: <span>{DailyData?.employee?.name}</span>{' '}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className='label'>
                  {t('email')}: <span>{DailyData?.employee?.email}</span>{' '}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className='label'>
                  {t('phone')}: <span>{DailyData?.employee?.phone}</span>{' '}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className='label'>
                  {t('no-of-hours-per-day')}: <span>{DailyData?.employee?.noOfHours}</span>{' '}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className='label'>
                  {t('days-per-month')} : <span>{DailyData?.employee?.daysPerMonth}</span>{' '}
                </h2>
              </Col>
              <Col xs={24} md={12} xl={8}>
                <h2 className='label'>
                  {t('designation')} : <span>{DailyData?.employee?.designation}</span>{' '}
                </h2>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={24}>
                <div className='header'>
                  <h3 className='summaryHeading'>
                    {SelectedMonth} {t('day-wise-daily-hours')}
                  </h3>
                  <div>
                    {DailyData && (
                      <Button
                        type='primary'
                        style={{ marginRight: '1rem' }}
                        onClick={() => getPdfGenerate()}
                        loading={pdfLoader}
                      >
                        {t('download-pdf')}
                      </Button>
                    )}
                    <DatePicker
                      placeholder={t('select-month')}
                      onChange={OnDailyDateChange}
                      style={{ width: '200px' }}
                      picker={'month'}
                      disabledDate={(d) => {
                        return d?.isAfter(moment())
                        // return moment(d).day() === 0 || d?.isAfter(moment());
                      }}
                    />
                  </div>
                </div>

                <div className='card'>
                  <LineChart
                    data={DailyData?.costing ? DailyData?.costing : []}
                    yField='dailyHours'
                    xField='day'
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className='header'>
                  <h3 className='summaryHeading'>
                    {SelectedMonth} {t('day-wise-daily-hours')}
                  </h3>
                  <div>
                    {DailyData && (
                      <Button
                        type='primary'
                        style={{ marginRight: '1rem' }}
                        onClick={() => getPdfGenerate()}
                        loading={pdfLoader}
                      >
                        {t('download-pdf')}
                      </Button>
                    )}
                    <DatePicker
                      placeholder={t('select-month')}
                      onChange={OnDailyDateChange}
                      style={{ width: '200px' }}
                      picker={'month'}
                      disabledDate={(d) => {
                        return d?.isAfter(moment())
                        // return moment(d).day() === 0 || d?.isAfter(moment());
                      }}
                    />
                  </div>
                </div>

                <div className='card'>
                  <LineChart
                    data={DailyData?.costing ? DailyData?.costing : []}
                    yField='dailyHours'
                    xField='day'
                  />
                </div>
              </Col>
              <Col xs={24}>
                <div className='header header2'>
                  <h3 className='summaryHeading '>
                    {t('year')} {selectedYear} {t('monhtly-hours')}
                  </h3>
                  <DatePicker
                    placeholder={t('select-year')}
                    onChange={OnCostingDateChange}
                    picker='year'
                    style={{ width: '200px' }}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
                <div className='card card2'>
                  <ColumnChart
                    data={costingData?.costing ? costingData?.costing : []}
                    yField='hours'
                    xField='month'
                    alias
                  />
                </div>
              </Col>
              <Col xs={24}>
                <br />
                <Table
                  sticky
                  dataSource={tableData}
                  className='project-table'
                  columns={columns}
                  pagination={false}
                />
              </Col>
              {roles?.includes('salary-statistics') !== false && (
                <Col xs={24}>
                  <div
                    className='header'
                    style={{
                      marginTop: '4rem',
                    }}
                  >
                    <h3 className='summaryHeading'>{t('employee-salary-statistics')}</h3>
                  </div>
                  <div className='card'>
                    <LineChart data={salaries ? salaries : []} yField='salary' xField='CreatedAt' />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        )
      )}
    </Layout>
  )
}

export default EmployeeSummary
