import swal from 'sweetalert'
import { notification } from 'antd'

import privateAPI, { attachToken } from '../../config/constants'
import * as types from '../types/generalTypes'

export const getEmployees = (deptFilter, desgFilter, search) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-all`, {
        deptFilter,
        desgFilter,
        search,
      })
      if (res) {
        dispatch({
          type: types.GET_ALL_EMPLOYEES,
          payload: res.data,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeesName = () => {
  return async (dispatch) => {
    try {
      // console.log('getEmployeesName')
      attachToken()
      const res = await privateAPI.get(`/employee/get-all-name`)
      if (res) {
        dispatch({
          type: types.GET_ALL_EMPLOYEES_NAME,
          payload: res.data,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getAllEmployeesName = () => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/employee/get-all-name-employee`)
      if (res) {
        dispatch({
          type: types.GET_ALL_EMPLOYEES_NAME,
          payload: res.data,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const createEmployee = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/create`, payload)
      if (res) {
        dispatch(getEmployees())
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateEmployee = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/update`, payload)
      if (res) {
        dispatch(getEmployees())

        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateEmpPassword = (payload) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/update-password`, payload)
      if (res) {
        // dispatch(getEmployees());
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeCosting = (userId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-costing/${userId}`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeDailyCosting = (userId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-daily-costing/${userId}`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeSalaries = (userId) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-user-salaries`, {
        userId,
      })
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

// -------------------------------------------------------------------------------

export const getEmployeeCostingOwn = (userId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-costing-own/${userId}`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeDailyCostingOwn = (userId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-daily-costing-own/${userId}`, data)
      if (res) {
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getEmployeeDailyCostingOwnAdmin = (userId, data) => {
  return async () => {
    try {
      attachToken()
      const res = await privateAPI.post(`/employee/get-daily-costing-own-admin/${userId}`, data)
      if (res) {
        // console.log(res.data)
        return res.data
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const blockEmployee = (id) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/employee/block/${id}`)
      if (res) {
        dispatch(getEmployees())
        swal('', res.data.message, 'success')
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const unblockEmployee = (id) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/employee/unblock/${id}`)
      if (res) {
        dispatch(getEmployees())
        swal('', res.data.message, 'success')
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getDesignations = () => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.get(`/employee/get-designations`)
      if (res) {
        dispatch({
          type: types.GET_DESIGNATIONS,
          payload: res.data,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const getDashboard = async () => {
  try {
    attachToken()
    const res = await privateAPI.get(`/admin/get-dashboard`)
    if (res) {
      return res.data
      // console.log(res.data);
    }
  } catch (error) {
    swal('', error?.response?.data?.message || 'Server Error', 'error')
  }
}

export const getTimelineComparison = async (data) => {
  try {
    attachToken()
    const res = await privateAPI.post(`/employee/checkin-timeline-comparrision/${data.id}`, {
      month: data.month,
      date: data.date,
    })
    if (res) {
      return res.data
    }
  } catch (error) {
    // console.log(error)
    // swal("", error?.response?.data?.message || "Server Error", "error");
  }
}

export const forceLogout = async (id) => {
  try {
    attachToken()
    const res = await privateAPI.get(`/employee/forceLogout/${id}`)
    if (res) {
      swal('', res.data.message, 'success')
    }
  } catch (error) {
    swal('', error?.response?.data?.message || 'Server Error', 'error')
  }
}
