import { timeFormatter } from './timeFormatter'

export const extraTimeCalculate = (workingHours, totalHours) => {
  return workingHours - totalHours > 0 ? timeFormatter(workingHours - totalHours) : 0
}

export const extraCostCalculate = (workingHours, totalHours, perHourCost) => {
  return workingHours - totalHours > 0
    ? `Rs. ${((workingHours - totalHours) * perHourCost).toFixed(0)}`
    : 0
}
