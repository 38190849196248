/* eslint-disable */
const resources = {
  en: {
    translation: {
      'sign-in': 'Sign In',
      login: 'Login',
      email: 'Email',
      password: 'Password',
      'email-required': 'Email is Required',
      'password-required': 'Password is Required',
      'email-invalid': 'The entered email is not valid!',
      'forgot-password': 'Forgot Password?',
      'find-account': 'Find your account',
      'reset-password': 'Reset Password',
      'reset-token': 'Reset Token',
      'token-required': 'Reset Token is Required',
      'confirm-password': 'Confirm Password',
      'confirm-password-required': 'Confirm Password is Required',
      reset: 'Reset',
      dashboard: 'Dashboard',
      projects: 'Projects',
      employees: 'Employees',
      expenses: 'Expenses',
      reports: 'Reports',
      moderators: 'Moderators',
      checkins: 'Checkins',
      attendance: 'Attendance',
      roles: 'Roles',
      logs: 'Logs',
      logout: 'Logout',
      checkin: 'Checkin',
      todos: 'Todos',
      summary: 'Summary',
      name: 'Name',
      phone: 'Phone',
      designation: 'Designation',
      edit: 'Edit',
      block: 'Block',
      all: 'All',
      'add-employee': 'Add Employee',
      'employee-name': 'Employee Name',
      'employee-name-required': 'Valid Employee Name is Required',
      'phone-required': 'Valid phone number required',
      'designation-required': 'Valid Designation is Required',
      department: 'Department',
      'department-required': 'Department is Required',
      'select-department': 'Select Department',
      'add-department': 'Add Department',
      salary: 'Salary',
      'salary-required': 'Salary is Required',
      'reason-for-salary-update': 'Reason for Salary Update',
      'off-days': 'Off Days',
      'off-days-required': 'Off Days is Required',
      'select-off-days': 'Select Off Days',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      'no-off-hours': 'No Off Hours (Per Day)',
      'no-off-hours-required': 'No Off Hours is Required',
      'joining-date': 'Joining Date',
      'select-date': 'Select Date',
      address: 'Address',
      'update-employee': 'Update Employee',
      'update-password': 'Update Password',
      'new-password': 'New Password',
      'new-password-required': 'New Password is Required',
      back: 'Back',
      'filter-by-department': 'Filter by Department',
      'filter-by-designation': 'Filter by Designation',
      'add-department': 'Add Department',
      'department-name': 'Department Name',
      'department-name-required': 'Department Name is Required',
      submit: 'Submit',
      'new-employee': 'New Employee',
      'update-emplyee': 'Update Employee',
      details: 'Details',
      'add-project': 'Add Project',
      'update-project': 'Update Project',
      'new-project': 'New Project',
      'select-employees': 'Select Employees',
      'select-team-lead': 'Select Team Lead',
      'assign-employees': 'Assign Employees',
      'project-name': 'Project Name',
      'project-name-required': 'Project Name is Required',
      'project-description': 'Project Description',
      'project-links': 'Project Links (Seperated by Comma)',
      'check-in': 'Check In',
      'check-out': 'Check Out',
      'checkin-history': 'Checkin History',
      'no-checkins-today': 'No Checkins Today',
      'no-checkins-found': 'No Checkins Found',
      'checkin-details': 'Checkin Details',
      'no-of-checkins': 'No of Checkins',
      time: 'Time',
      start: 'Start',
      end: 'End',
      'create-notification': 'Create Notification',
      'enter-notification': 'Enter Notification',
      create: 'Create',
      total: 'Total',
      today: 'Today',
      yesterday: 'Yesterday',
      'this-month': 'This Month',
      'last-month': 'Last Month',
      costing: 'Costing',
      description: 'Description',
      hours: 'Hours',
      'projects-progress': 'Projects Progress',
      'projects-costings': 'Projects Costings',
      'today-attendance': "Today's Attendance",
      'yesterday-attendance': "Yesterday's Attendance",
      'moderator-today-attendance': "Moderator's Today Attendance",
      'moderator-yesterday-attendance': "Moderator's Yesterday Attendance",
      present: 'Present',
      absent: 'Absent',
      'today-progress': "Today's Progress",
      'yesterday-progress': "Yesterday's Progress",
      'no-work-last-7-days': 'No Work Last 7 Days',
      'deleted-todos': 'Deleted Todos',
      title: 'Title',
      'deleted-reason': 'Deleted Reason',
      'deleted-by': 'Deleted By',
      restore: 'Restore',
      'checkin-time-today': 'Checkin Time Today',
      local: 'Local',
      employee: 'Employee',
      'last-7-days-checkin-and-hours': 'Last 7 Days Checkin and Hours',
      date: 'Date',
      'checkin-screenshots': 'Checkin Screenshots',
      'get-screenshots-by-range': 'Get Screenshots by Range',
      search: 'Search',
      'screenshots-for-checkin': 'Screenshots for Checkin',
      'select-resource': 'Select Resource',
      month: 'Month',
      'no-entries-found': 'No Entries Found',
      'emloyee-costing-report': 'Employee Costing Report',
      'no-of-hours-per-day': 'No of Hours Per Day',
      'days-per-month': 'Days Per Month',
      'day-wise-daily-hours': 'Day Wise Daily Hours',
      'download-pdf': 'Download PDF',
      'select-month': 'Select Month',
      year: 'Year',
      'monthly-hours': 'Monthly Hours',
      'select-year': 'Select Year',
      'employee-salary-statisctics': 'Employee Salary Statisctics',
      'select-month': 'Select Month',
      'total-expenses-this-month': 'Total Expenses This Month',
      rs: 'Rs.',
      'company-monthly-expenses': 'Company Monthly Expenses',
      amount: 'Amount',
      add: 'Add',
      'new-expense': 'New Expense',
      'update-expense': 'Update Expense',
      'amount-required': 'Amount is Required',
      'expense-description': 'Expense Description',
      'add-expense': 'Add Expense',
      logs: 'Logs',
      'select-date': 'Select Date',
      'no-logs-found': 'No Logs Found',
      message: 'Message',
      'created-date': 'Created Date',
      'add-moderator': 'Add Moderator',
      'update-moderator': 'Update Moderator',
      'new-moderator': 'New Moderator',
      role: 'Role',
      'select-role': 'Select Role',
      'role-required': 'Role is Required',
      notifications: 'Notifications',
      type: 'Type',
      project: 'Project',
      'total-cost': 'Total Cost',
      'total-hours': 'Total Hours',
      'project-todos': 'Project Todos',
      'track-todos': 'Track Todos',
      'add-title': 'Add Title',
      'write-note': 'Write Note',
      'assign-to': 'Assign To',
      'add-todo': 'Add Todo',
      'select-user': 'Select User',
      week: 'Week',
      'submit-time': 'Submit Time',
      remarks: 'Remarks',
      actions: 'Actions',
      'project-name': 'Project Name',
      'day-wise-project-costing': 'Day Wise Project Costing',
      'monthly-costing': 'Monthly Costing',
      till: 'Till',
      'assigned-projects': 'Assigned Projects',
      'employee-summary-report': 'Employee Summary Report',
      'project-report': 'Project Report',
      'summary-report': 'Summary Report',
      'no-record-found': 'No Record Found',
      'generate-report-of-project-by-resource': 'Generate Report of Project by Resource',
      'select-project': 'Select Project',
      'select-resource': 'Select Resource',
      resource: 'Resource',
      default: 'Default',
      'between-dates': 'Between Dates',
      'download-report': 'Download Report',
      'generate-report-of-all-projects-between-dates':
        'Generate Report of All Projects Between Dates',
      'generate-report-of-all-resources-between-dates':
        'Generate Report of All Resources Between Dates',
      roles: 'Roles',
      'create-a-new-role': 'Create a New Role',
      update: 'Update',
      'employee-todos': 'Employee Todos',
      'assigned-to': 'Assigned To',
      'delete-task': 'Delete Task',
      'are-you-sure-you-want-to-delete-this-task': 'Are you sure you want to delete this task?',
      'specify-the-reason': 'Specify the reason',
      reason: 'Reason',
      repeated: 'Repeated',
      incorrect: 'Incorrect',
      'not-in-scope': 'Not in Scope',
      delete: 'Delete',
      'add-timeline': 'Add Timeline',
      'add-remarks': 'Add Remarks',
      'hours-required': 'Hours is Required',
      remarks: 'Remarks',
      'update-remarks': 'Update Remarks',
      view: 'View',
      'no-new-notifications': 'No New Notifications',
      'show-all-notifications': 'Show All Notifications',
      'generate-report': 'Generate Report',
      'project-budget': 'Project Budget',
      budget: 'Budget',
      leave: 'Leave',
      'leave-request': 'Leave Request',
      'date-required': 'Date Required',
      reason: 'Reason',
      'reason-required': 'Reason Required',
      request: 'Request',
      'leave-requests': "Leave Request's",
      'calendar-summary': 'Calendar Summary',
      announcement: 'Announcement',
      'notice-board': 'Notice Board',
      birthday: 'Birthday',
      'date-of-birth': 'Date of Birth',
      'date-of-birth-required': 'Date of Birth Required',
      notice: 'Notice',
      'create-notice': 'Create Notice',
      hr: 'HR',
      'organization-hierarchy': 'Organization Hierarchy',
      accessories: 'Accessories',
      documents: 'Documents',
      'add-document': 'Add Document',
      'employee-history': 'Employee History',
      history: 'History',
      tickets: 'Tickets',
      ticket: 'Ticket',
      'ticket-issue': 'Ticket Issue',
      'create-ticket': 'Create Ticket',
      'ticket-issue-to-employee': 'Ticket Issue to Employee',
      'ticket-issue-to-employee-required': 'Ticket Issue to Employee Required',
      'select-employee': 'Select Employee',
      issue: 'Issue',
      'issue-against': 'Issue Against',
      'date-already-exists': 'Date Already Exists',
      'shift-start': 'Shift Start',
      'shift-end': 'Shift End',
    },
  },
  ar: {
    translation: {
      'sign-in': 'تسجيل الدخول',
      login: 'تسجيل الدخول',
      email: 'البريد الإلكتروني',
      password: 'كلمه السر',
      'email-required': 'البريد الإلكتروني مطلوب',
      'password-required': 'كلمة المرور مطلوبة',
      'email-invalid': 'البريد الإلكتروني المدخل غير صالح!',
      'forgot-password': 'هل نسيت كلمة المرور؟',
      'find-account': 'العثور على حسابك',
      'reset-password': 'إعادة تعيين كلمة المرور',
      'reset-token': 'رمز إعادة التعيين',
      'token-required': 'مطلوب رمز إعادة التعيين',
      'confirm-password': 'تأكيد كلمة المرور',
      'confirm-password-required': 'تأكيد كلمة المرور مطلوب',
      reset: 'إعادة تعيين',
      dashboard: 'لوحة القيادة',
      projects: 'مشاريع',
      employees: 'الموظفين',
      expenses: 'مصاريف',
      reports: 'تقارير',
      moderators: 'المشرفين',
      checkins: 'التحققات',
      attendance: 'الحضور',
      roles: 'الأدوار',
      logs: 'سجلات',
      logout: 'تسجيل خروج',
      checkin: 'تحقق',
      todos: 'الأشياء المطلوبة',
      summary: 'ملخص',
      name: 'اسم',
      phone: 'هاتف',
      designation: 'تعيين',
      edit: 'تصحيح',
      block: 'حظر',
      all: 'الكل',
      'add-employee': 'إضافة موظف',
      'employee-name': 'اسم الموظف',
      'employee-name-required': 'اسم الموظف مطلوب',
      'phone-required': 'الهاتف مطلوب',
      'designation-required': 'التعيين مطلوب',
      department: 'قسم',
      'department-required': 'القسم مطلوب',
      'select-department': 'اختر القسم',
      'add-department': 'إضافة قسم',
      salary: 'راتب',
      'salary-required': 'الراتب مطلوب',
      'reason-for-salary-update': 'سبب تحديث الراتب',
      'off-days': 'أيام عطلة',
      'off-days-required': 'أيام عطلة مطلوبة',
      'select-off-days': 'اختر أيام العطلة',
      sunday: 'الأحد',
      monday: 'الاثنين',
      tuesday: 'الثلاثاء',
      wednesday: 'الأربعاء',
      thursday: 'الخميس',
      friday: 'يوم الجمعة',
      saturday: 'يوم السبت',
      'no-off-hours': 'لا ساعات عطلة (لكل يوم)',
      'no-off-hours-required': 'لا ساعات عطلة مطلوبة',
      'joining-date': 'تاريخ الانضمام',
      'select-date': 'اختر التاريخ',
      address: 'عنوان',
      'update-employee': 'تحديث الموظف',
      'update-password': 'تحديث كلمة المرور',
      'new-password': 'كلمة السر الجديدة',
      'new-password-required': 'كلمة المرور الجديدة مطلوبة',
      back: 'الى الخلف',
      'filter-by-department': 'تصفية حسب القسم',
      'filter-by-designation': 'تصفية حسب التعيين',
      'add-department': 'إضافة قسم',
      'department-name': 'اسم القسم',
      'department-name-required': 'اسم القسم مطلوب',
      submit: 'خضع',
      'new-employee': 'موظف جديد',
      'update-employee': 'تحديث الموظف',
      details: 'تفاصيل',
      'update-project': 'تحديث المشروع',
      'new-project': 'مشروع جديد',
      'select-employees': 'اختر الموظفين',
      'select-team-lead': 'اختر رئيس الفريق',
      'assign-employees': 'تعيين الموظفين',
      'project-name': 'اسم المشروع',
      'project-name-required': 'اسم المشروع مطلوب',
      'project-description': 'وصف المشروع',
      'project-links': 'روابط المشروع',
      'check-in': 'تحقق',
      'check-out': 'الدفع',
      'checkin-history': 'تاريخ التحقق',
      'no-checkins-today': 'لا تحقق اليوم',
      'no-checkins-found': 'لم يتم العثور على تحققات',
      'checkin-details': 'تفاصيل التحقق',
      'no-of-checkins': 'عدد التحققات',
      time: 'زمن',
      start: 'بداية',
      end: 'نهاية',
      'create-notification': 'إنشاء إشعار',
      'enter-notification': 'أدخل الإشعار',
      create: 'خلق',
      total: 'مجموع',
      today: 'اليوم',
      yesterday: 'أمس',
      'this-month': 'هذا الشهر',
      'last-month': 'الشهر الماضي',
      costing: 'تكلفة',
      description: 'وصف',
      hours: 'ساعات',
      'projects-progress': 'تقدم المشاريع',
      'projects-costings': 'تكاليف المشاريع',
      'today-attendance': 'حضور اليوم',
      'moderator-today-attendance': 'حضور المنسق اليوم',
      'moderator-yesterday-attendance': 'حضور المنسق أمس',
      'yesterday-attendance': 'حضور أمس',
      present: 'حاضر',
      absent: 'غائب',
      'today-progress': 'تقدم اليوم',
      'yesterday-progress': 'تقدم أمس',
      'no-work-last-7-days': 'لا يوجد عمل في آخر 7 أيام',
      'deleted-todos': 'حذف المهام',
      title: 'عنوان',
      'deleted-reason': 'سبب الحذف',
      'deleted-by': 'حذف بواسطة',
      restore: 'استعادة',
      'checkin-time-today': 'تحقق من وقت اليوم',
      local: 'محلي',
      employee: 'موظف',
      'last-7-days-checkin-and-hours': 'آخر 7 أيام تحقق وساعات',
      date: 'تاريخ',
      'checkin-screenshots': 'تحقق من لقطات الشاشة',
      'get-screenshots-by-range': 'الحصول على لقطات الشاشة حسب النطاق',
      search: 'بحث',
      'screenshots-for-checkin': 'لقطات الشاشة للتحقق',
      'select-resource': 'حدد المورد',
      month: 'شهر',
      'no-entries-found': 'لا توجد مداخل',
      'emloyee-costing-report': 'تقرير تكلفة الموظف',
      'no-of-hours-per-day': 'عدد الساعات في اليوم',
      'days-per-month': 'أيام في الشهر',
      'day-wise-daily-hours': 'يوم حكيم ساعات يومية',
      'download-pdf': 'تحميل PDF',
      'select-month': 'اختر الشهر',
      year: 'عام',
      'monthly-hours': 'ساعات شهرية',
      'select-year': 'اختر السنة',
      'employee-salary-statisctics': 'إحصائيات راتب الموظف',
      'select-month': 'اختر الشهر',
      'total-expenses-this-month': 'مصاريف إجمالية هذا الشهر',
      rs: '.رس',
      'company-monthly-expenses': 'مصاريف شهرية الشركة',
      amount: 'كمية',
      add: 'إضافة',
      'new-expense': 'نفقة جديدة',
      'update-expense': 'تحديث النفقات',
      'amount-required': 'المبلغ مطلوب',
      'expense-description': 'وصف النفقات',
      'add-expense': 'إضافة نفقة',
      logs: 'سجلات',
      'select-date': 'اختر التاريخ',
      'no-logs-found': 'لم يتم العثور على سجلات',
      message: 'رسالة',
      'created-date': 'تاريخ الإنشاء',
      'add-moderator': 'إضافة مشرف',
      'update-moderator': 'تحديث المشرف',
      'new-moderator': 'مشرف جديد',
      role: 'وظيفة',
      'select-role': 'اختر الدور',
      'role-required': 'الدور مطلوب',
      notifications: 'إشعارات',
      type: 'اكتب',
      project: 'مشروع',
      'total-cost': 'التكلفة الإجمالية',
      'total-hours': 'مجموع الساعات',
      'project-todos': 'مهام المشروع',
      'track-todos': 'مهام المسار',
      'add-title': 'أضف عنوان',
      'write-note': 'اكتب ملاحظة',
      'assign-to': 'تعيين إلى',
      'add-todo': 'إضافة مهمة',
      'select-user': 'اختر المستخدم',
      week: 'أسبوع',
      'submit-time': 'إرسال الوقت',
      remarks: 'ملاحظات',
      actions: 'أجراءات',
      'project-name': 'اسم المشروع',
      'day-wise-project-costing': 'يوم حكيم تكلفة المشروع',
      'monthly-costing': 'تكلفة شهرية',
      till: 'حتى',
      'assigned-projects': 'المشاريع المعينة',
      'employee-summary-report': 'تقرير ملخص الموظف',
      'summary-report': 'تقرير ملخص',
      'no-record-found': 'لم يتم العثور على سجلات',
      'generate-report-of-project-by-resource': 'توليد تقرير المشروع حسب المورد',
      'select-project': 'اختر المشروع',
      'select-resource': 'حدد المورد',
      resource: 'موارد',
      default: 'افتراضي',
      'between-dates': 'بين التواريخ',
      'download-report': 'تحميل التقرير',
      'generate-report-of-all-projects-between-dates': 'توليد تقرير جميع المشاريع بين التواريخ',
      'generate-report-of-all-resources-between-dates': 'توليد تقرير جميع الموارد بين التواريخ',
      roles: 'أدوار',
      'create-a-new-role': 'إنشاء دور جديد',
      update: 'تحديث',
      'employee-todos': 'مهام الموظف',
      'assigned-to': 'مخصص ل',
      'delete-task': 'حذف المهمة',
      'are-you-sure-you-want-to-delete-this-task': 'هل أنت متأكد أنك تريد حذف هذه المهمة؟',
      'specify-the-reason': 'حدد السبب',
      reason: 'السبب',
      repeated: 'متكرر',
      incorrect: 'غير صحيح',
      'not-in-scope': 'ليس في النطاق',
      delete: 'حذف',
      'add-timeline': 'إضافة الجدول الزمني',
      'add-remarks': 'إضافة ملاحظات',
      'hours-required': 'الساعات مطلوبة',
      remarks: 'ملاحظات',
      'update-remarks': 'تحديث الملاحظات',
      view: 'رأي',
      'no-new-notifications': 'لا إشعارات جديدة',
      'show-all-notifications': 'عرض جميع الإشعارات',
      'project-budget': 'ميزانية المشروع',
      budget: 'ميزانية',
      leave: 'يترك',
      'leave-request': 'طلب إجازة',
      'date-required': 'التاريخ مطلوب',
      reason: 'سبب',
      'reason-required': 'السبب مطلوب',
      request: 'طلب',
      'leave-requests': 'طلب الإجازة',
      'calendar-summary': 'ملخص التقويم',
      announcement: 'إعلان',
      'notice-board': 'لوح الإعلانات',
      birthday: 'عيد ميلاد',
      'date-of-birth': 'تاريخ الميلاد',
      'date-of-birth-required': 'تاريخ الميلاد مطلوب',
      notice: 'يلاحظ',
      'create-notice': 'إنشاء إشعار',
      hr: 'ساعة',
      'organization-hierarchy': 'التسلسل الهرمي للمؤسسة',
      accessories: 'مُكَمِّلات',
      documents: 'وثائق',
      'add-document': 'أضف وثيقة',
      'employee-history': 'تاريخ الموظف',
      history: 'تاريخ',
      tickets: 'تذاكر',
      ticket: 'تذكرة',
      'ticket-issue': 'إصدار التذكرة',
      'create-ticket': 'إنشاء تذكرة',
      'ticket-issue-to-employee': 'إصدار تذكرة للموظف',
      'ticket-issue-to-employee-required': 'اصدار تذكرة للموظف مطلوب',
      'select-employee': 'حدد الموظف',
      issue: 'قضية',
      'issue-against': 'قضية ضد',
      'date-already-exists': 'التاريخ موجود بالفعل',
      'shift-start': 'بداية التحول',
      'shift-end': 'نهاية التحول',
    },
  },
}

export default resources
