import { useState, useEffect } from 'react'
import { Switch, Table, Select, Button, Tabs, Input } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AddEmployee from '../components/modal/AddEmployee'
import Layout from '../layout/Layout'
import {
  blockEmployee,
  forceLogout,
  getDesignations,
  getEmployees,
  unblockEmployee,
} from '../redux'
import InfinityLoader from '../components/InfinityLoader'
import { TbReportAnalytics } from 'react-icons/tb'
import { useHistory } from 'react-router-dom'
import AddDepartment from '../components/modal/AddDepartment'
import { getDepartments } from '../redux/actions/department'

const AdminEmployees = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const history = useHistory()
  const employees = useSelector((state) => state.employees.employees)
  const departments = useSelector((state) => state.departments.departments)
  const designations = useSelector((state) => state.employees.designations)
  const [employeeData, setEmployeesData] = useState(null)
  const [state, setState] = useState(null)
  const [state2, setState2] = useState(null)
  const [search, setSearch] = useState('')
  const deptFilter = async (value) => {
    // const data = await getDashboard();

    // console.log(value);
    setState(value)
  }
  const desgFilter = async (value) => {
    setState2(value)
  }
  useEffect(() => {
    if (search == '') {
      dispatch(getEmployees(state, state2, search))
    } else {
      setTimeout(() => {
        dispatch(getEmployees(state, state2, search))
      }, 1000)
    }
  }, [state, state2, search])

  const userType = localStorage.getItem('userType')
  const blockEmp = (id, checked) => {
    if (checked === true) {
      dispatch(blockEmployee(id))
    } else {
      dispatch(unblockEmployee(id))
    }
  }
  useEffect(() => {
    dispatch(getEmployees())
    dispatch(getDepartments())
    dispatch(getDesignations())
  }, [])

  useEffect(() => {
    let data = {
      active: [],
      past: [],
    }
    employees?.map((employee) => {
      if (employee.blocked === false) {
        data.active.push({
          name: employee.name,
          email: employee.email,
          phone: employee.phone,
          designation: employee.designation,
          edit: (
            <div className='action-buttons'>
              <AddEmployee newEmployee={false} data={employee} />
            </div>
          ),
          block: (
            <div className='action-buttons'>
              {/* <Switch
                defaultChecked={employee.blocked}
                onChange={(checked) => blockEmp(employee._id, checked)}
              /> */}
              <Button type='primary' onClick={() => blockEmp(employee._id, true)}>
                Block
              </Button>
            </div>
          ),
          logout: (
            <div className='action-buttons'>
              <Button type='primary' onClick={() => forceLogout(employee._id)}>
                Force Logout
              </Button>
            </div>
          ),
          summary: (
            <div className='action-buttons'>
              <TbReportAnalytics
                className='editBtn'
                onClick={() => history.push(`/admin/employee/summary/${employee._id}`)}
              />
            </div>
          ),
        })
      } else {
        data?.past?.push({
          name: employee.name,
          email: employee.email,
          phone: employee.phone,
          designation: employee.designation,
          edit: (
            <div className='action-buttons'>
              <AddEmployee newEmployee={false} data={employee} />
            </div>
          ),
          block: (
            <div className='action-buttons'>
              <Button type='primary' onClick={() => blockEmp(employee._id, false)}>
                Unblock
              </Button>
            </div>
          ),
          logout: (
            <div className='action-buttons'>
              <Button type='primary' onClick={() => forceLogout(employee._id)}>
                Force Logout
              </Button>
            </div>
          ),
          summary: (
            <div className='action-buttons'>
              <TbReportAnalytics
                className='editBtn'
                onClick={() => history.push(`/admin/employee/summary/${employee._id}`)}
              />
            </div>
          ),
        })
      }
    })
    setEmployeesData(data)
  }, [employees])

  // userType !== "moderator" &&

  const columns =
    userType !== 'moderator'
      ? [
          {
            title: t('name'),
            dataIndex: 'name',
          },
          {
            title: t('email'),
            dataIndex: 'email',
          },
          {
            title: t('phone'),
            dataIndex: 'phone',
          },
          {
            title: t('designation'),
            dataIndex: 'designation',
          },
          {
            title: t('summary'),
            dataIndex: 'summary',
          },
          {
            title: t('edit'),
            dataIndex: 'edit',
          },
          {
            title: t('block'),
            dataIndex: 'block',
          },
          {
            title: t('logout'),
            dataIndex: 'logout',
          },
        ]
      : [
          {
            title: t('name'),
            dataIndex: 'name',
          },
          {
            title: t('email'),
            dataIndex: 'email',
          },
          {
            title: t('phone'),
            dataIndex: 'phone',
          },
          {
            title: t('designation'),
            dataIndex: 'designation',
          },
          {
            title: t('logout'),
            dataIndex: 'logout',
          },
          {
            title: t('summary'),
            dataIndex: 'edit',
          },
        ]

  return (
    <Layout active={'admin-employees'}>
      {employees === null ? (
        <InfinityLoader />
      ) : (
        <div className='admin-employee-container'>
          <div className='header'>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              <AddEmployee newEmployee={true} />
              <AddDepartment />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '20px',
                flex: 1,
              }}
            >
              <Input
                placeholder={t('search')}
                style={{ flex: 1 }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Select
                placeholder={t('filter-by-department')}
                onChange={deptFilter}
                value={state}
                style={{ flex: 1 }}
              >
                <Select.Option value={'all'}>{t('all')}</Select.Option>

                {departments?.departments?.map((val, ind) => {
                  return (
                    <Select.Option key={ind} value={val._id}>
                      {val.name}
                    </Select.Option>
                  )
                })}
              </Select>
              <Select
                placeholder={t('filter-by-designation')}
                onChange={desgFilter}
                value={state2}
                style={{ flex: 1 }}
              >
                <Select.Option value={'all'}>{t('all')}</Select.Option>

                {designations?.employee?.map((val, ind) => {
                  return (
                    <Select.Option key={ind} value={val}>
                      {val}
                    </Select.Option>
                  )
                })}
              </Select>
            </div>
          </div>
          <Tabs defaultActiveKey='0'>
            <Tabs.TabPane
              tab={
                <span
                  style={{
                    fontSize: 20,
                  }}
                >
                  Active
                </span>
              }
              key={0}
            >
              <Table
                sticky
                dataSource={employeeData?.active}
                className='project-table'
                columns={columns}
                scroll={{ x: 1200 }}
                // pagination={false}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span
                  style={{
                    fontSize: 20,
                  }}
                >
                  Past
                </span>
              }
              key={1}
            >
              <Table
                sticky
                dataSource={employeeData?.past}
                className='project-table'
                columns={columns}
                scroll={{ x: 1200 }}
                // pagination={false}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default AdminEmployees
