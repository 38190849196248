import { SET_PROJECT_CHECKIN_ID, SET_SHIFT_CHECKINS, SET_START_TIME } from '../types/generalTypes'

// Action creator to set shift check-ins
export const setShiftCheckins = (checkins) => ({
  type: SET_SHIFT_CHECKINS,
  payload: checkins,
})

// Action creator to set start time
export const setStartTime = (startTime) => ({
  type: SET_START_TIME,
  payload: startTime,
})

// Action creator to set project check-in ID
export const setProjectCheckinId = (checkinId) => ({
  type: SET_PROJECT_CHECKIN_ID,
  payload: checkinId,
})
