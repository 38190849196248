import { useState } from 'react'
import { Modal, Button, Form, Input, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { createProject, updateProject } from '../../redux'
import { useTranslation } from 'react-i18next'

const AddProject = ({ newProject, data, employees, getData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const showModal = () => {
    setIsModalVisible(true)
  }

  const onFinish = async (values) => {
    setLoading(true)
    if (newProject) {
      await dispatch(
        createProject({
          ...values,
          links: values.links?.split(','),
        }),
      )
      form.resetFields()
    } else {
      const flag = await dispatch(
        updateProject({
          ...values,
          links: values.links?.split(','),
          id: data._id,
        }),
      )
      if (flag) {
        getData()
      }
    }
    setLoading(false)
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      {newProject ? (
        <Button type='primary' className='addProjectBtn' onClick={showModal}>
          {t('add-project')}
        </Button>
      ) : (
        <Button
          type='primary'
          // style={{ marginTop: '20px', marginRight: '20px' }}
          onClick={showModal}
        >
          {t('update-project')}
        </Button>
      )}
      <Modal
        title={newProject ? t('new-project') : t('update-project')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
        style={{
          top: '20px',
        }}
      >
        <Form
          layout='vertical'
          form={form}
          fields={
            data
              ? [
                  {
                    name: 'title',
                    value: data.title,
                  },
                  {
                    name: 'description',
                    value: data.description,
                  },
                  {
                    name: 'assignedTo',
                    value: data.assignedTo,
                  },
                  {
                    name: 'links',
                    value: data?.links?.join(','),
                  },
                  {
                    name: 'reference',
                    value: data?.reference,
                  },
                  {
                    name: 'budget',
                    value: data?.budget,
                  },
                  {
                    name: 'teamLead',
                    value: data?.teamLead,
                  },
                ]
              : []
          }
          onFinish={onFinish}
        >
          <Form.Item label={t('assign-employees')} name={'assignedTo'}>
            <Select
              showSearch
              optionFilterProp='children'
              mode='multiple'
              // className='select-custom'
              maxTagCount={3}
              placeholder={t('select-employees')}
              style={{
                border: '1px solid var(--borderLight)',
              }}
            >
              {employees?.employee?.map((project, ind) => (
                <Select.Option key={ind} value={project._id}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('select-team-lead')} name={'teamLead'}>
            <Select
              showSearch
              optionFilterProp='children'
              maxTagCount={3}
              mode='multiple'
              placeholder={t('select-employees')}
              style={{
                border: '1px solid var(--borderLight)',
              }}
            >
              {employees?.employee?.map((project, ind) => (
                <Select.Option key={ind} value={project._id}>
                  {project.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Select Project Reference'}
            name={'reference'}
            rules={[
              {
                required: true,
                message: 'Project Presence Required',
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp='children'
              // mode="multiple"
              placeholder={'Reference'}
              style={{
                border: '1px solid var(--borderLight)',
              }}
            >
              <Select.Option value={'local'}>Local</Select.Option>
              <Select.Option value={'international'}>International</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t('project-name')}
            name={'title'}
            rules={[
              {
                required: true,
                message: t('project-name-required'),
              },
            ]}
            requiredMark={'optional'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('project-budget')}
            name={'budget'}
            // rules={[
            //   {
            //     required: true,
            //     message: t('project-name-required'),
            //   },
            // ]}
            // requiredMark={'optional'}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item label={t('project-description')} name={'description'}>
            <Input.TextArea rows={4} style={{ resize: 'none' }} />
          </Form.Item>

          <Form.Item label={t('project-links')} name={'links'}>
            <Input.TextArea rows={3} style={{ resize: 'none' }} />
          </Form.Item>

          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary'>
              {newProject ? t('add-project') : t('update-project')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default AddProject
