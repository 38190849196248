import swal from 'sweetalert'
import { notification } from 'antd'

import privateAPI, { attachToken } from '../../config/constants'
import * as types from '../types/generalTypes'

export const getExpenses = (month) => {
  return async (dispatch) => {
    try {
      attachToken()
      let res
      if (month) {
        res = await privateAPI.post(`/expense/get-all-by-month`, { date: month })
      } else {
        res = await privateAPI.post(`/expense/get-all-by-month`)
      }
      if (res) {
        dispatch({
          type: types.GET_ADMIN_EXPENSE,
          payload: {
            expenses: res.data.expenses,
            expenseTotal: res.data.total?.[0] ? res.data.total?.[0]?.total : 0,
          },
        })
      }
    } catch (err) {
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const createExpenses = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/expense/create`, payload)
      if (res) {
        dispatch(getExpenses())
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const updateExpenses = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.post(`/expense/update`, payload)
      if (res) {
        dispatch(getExpenses())

        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}

export const deleteExpenses = (payload) => {
  return async (dispatch) => {
    try {
      attachToken()
      const res = await privateAPI.delete(`/expense/delete/${payload}`)
      if (res) {
        dispatch(getExpenses())
        notification.success({
          description: res.data.message,
          duration: 2,
        })
      }
    } catch (err) {
      // console.log(err);
      swal('', err?.response?.data?.message || 'Server Error', 'error')
    }
  }
}
