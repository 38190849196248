import * as types from '../types/generalTypes'

const initialState = {
  allAccessories: null,
}

const accessoriesReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case types.GET_ALL_ACCESSORIES:
      return {
        ...state,
        allAccessories: payload,
      }
    default:
      return state
  }
}

export default accessoriesReducer
