import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Table, Tooltip } from 'antd'
import moment from 'moment'

const CalendarLeaveModal = ({
  isModalVisible,
  setIsModalVisible,
  setDateSelect,
  dateSelect,
  data,
}) => {
  const { t } = useTranslation()
  const [finalData, setfinalData] = useState([])
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (dateSelect) {
      let tempArr = []
      data?.map((leave) => {
        if (leave?.date === dateSelect) {
          tempArr.push(leave)
        }
      })
      setfinalData(tempArr)
    }
  }, [dateSelect])

  const handleCancel = () => {
    setIsModalVisible(false)
    setDateSelect(null)
  }

  useEffect(() => {
    let tempArr = []
    finalData?.map((employee) => {
      tempArr.push({
        employee: employee?.employee?.name,
        date: `${moment(employee?.date).format('DD-MM-YYYY')}`,
        reason:
          employee?.reason?.length > 20 ? (
            <Tooltip placement='top' title={employee?.reason}>
              {employee?.reason?.slice(0, 20)}...
            </Tooltip>
          ) : (
            employee?.reason
          ),
        status: <span style={{ fontWeight: '600' }}>{employee?.status}</span>,
      })
    })
    setTableData(tempArr)
  }, [finalData])

  const columns = [
    {
      title: 'Employee',
      dataIndex: 'employee',
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ]

  return (
    <>
      <Modal
        title={t('leave-request')}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        <Table sticky dataSource={tableData} columns={columns} pagination={false} />
      </Modal>
    </>
  )
}

export default CalendarLeaveModal
