import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Tabs, Tooltip } from 'antd'

import Layout from '../../layout/Layout'
import InfinityLoader from '../../components/InfinityLoader'
import { useDispatch } from 'react-redux'
import { getEmployeeAllTickets } from '../../redux'
import moment from 'moment'
import CreateTicketModal from '../../components/modal/CreateTicketModal'

const Ticket = () => {
  const { TabPane } = Tabs
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [tickets, setTickets] = useState(null)
  const [data, setData] = useState([])
  const [activeTab, setActiveTab] = useState('issue')
  const [loading, setLoading] = useState(false)

  const getEmployeeTickets = async () => {
    try {
      setLoading(true)
      const result = await dispatch(getEmployeeAllTickets(activeTab))
      if (result) {
        setTickets(result)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      // console.log('error', error)
    }
  }

  useEffect(() => {
    getEmployeeTickets()
  }, [activeTab])

  useEffect(() => {
    let tempArr = []
    tickets?.map((employee) => {
      tempArr.push({
        ticketIssueTo: employee?.ticketIssueTo?.name,
        date: moment(employee?.createdAt).format('DD/MM/YYYY'),
        description:
          employee?.description?.length > 20 ? (
            <Tooltip placement='top' title={employee?.description}>
              {employee?.description?.slice(0, 20)}...
            </Tooltip>
          ) : (
            employee?.description
          ),
        status:
          employee?.status === 'Active' ? (
            <span className='greentag'>{employee?.status}</span>
          ) : (
            <span className='redtag'>{employee?.status}</span>
          ),
        remarks: employee?.remarks || '-',
      })
    })
    setData(tempArr)
  }, [tickets])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: activeTab === 'issue' ? 'Ticket Issue To' : 'Ticket Issued From',
      dataIndex: 'ticketIssueTo',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
  ]

  return (
    <Layout active={'employee-ticket'}>
      {data === null ? (
        <InfinityLoader />
      ) : (
        <div className='leave-container'>
          <Tabs
            defaultActiveKey={activeTab}
            animated
            onChange={(e) => setActiveTab(e)}
            tabBarExtraContent={<CreateTicketModal getEmployeeTickets={getEmployeeTickets} />}
          >
            <TabPane tab={t('issue')} key='issue' style={{ padding: '20px 0' }}>
              <Table sticky dataSource={data} columns={columns} loading={loading} />
            </TabPane>
            <TabPane tab={t('issue-against')} key='against' style={{ padding: '20px 0' }}>
              <Table sticky dataSource={data} columns={columns} />
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default Ticket
