import { Col, DatePicker, Row, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'
import InfinityLoader from '../components/InfinityLoader'
import Layout from '../layout/Layout'
import { deleteRemark, getEmployeeCostingOwn, getEmployeeDailyCostingOwn } from '../redux'
const EmployeeSummary = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const id = localStorage.getItem('userId')
  const [DailyData, setDailyData] = useState(null)
  const [costingData, setcostingData] = useState(null)
  // const [selectedYear, setselectedYear] = useState(moment().format('YYYY'))
  const [SelectedMonth, setSelectedMonth] = useState(moment().format('MMMM'))

  const [dailyTableData, setDailyTableData] = useState(null)

  const GetData = async () => {
    const dailyResponse = await dispatch(getEmployeeDailyCostingOwn(id))
    setDailyData(dailyResponse)

    const costResponse = await dispatch(getEmployeeCostingOwn(id))
    setcostingData(costResponse)
  }
  useEffect(() => {
    GetData()
  }, [])

  const OnDailyDateChange = async (value) => {
    if (value) {
      setSelectedMonth(moment(value?.toISOString()).format('MMMM'))
      const dailyResponse = await dispatch(
        getEmployeeDailyCostingOwn(id, { month: moment(value?.toISOString()).format('MM') }),
      )
      setDailyData(dailyResponse)
    } else {
      const dailyResponse = await dispatch(getEmployeeDailyCostingOwn(id))
      setDailyData(dailyResponse)
    }
  }

  const OnDailyDateChangeDate = async (value, dateString) => {
    setSelectedMonth(moment(value?.toISOString()).format('MMMM'))
    if (dateString) {
      const dailyResponse = await dispatch(
        getEmployeeDailyCostingOwn(id, {
          date: moment(dateString).format('YYYY-MM-DD'),
        }),
      )
      setDailyData(dailyResponse)
    } else {
      const dailyResponse = await dispatch(getEmployeeDailyCostingOwn(id))
      setDailyData(dailyResponse)
    }
  }

  // const OnCostingDateChange = async (value, dateString) => {
  //   setselectedYear(moment(value).format('YYYY'))
  //   const costResponse = await dispatch(
  //     getEmployeeCostingOwn(id, { year: moment(value).format('YYYY') }),
  //   )
  //   setcostingData(costResponse)
  // }

  useEffect(() => {
    let data = []
    costingData?.costing?.map((i) => {
      data.push({
        month: i.month,
        hours: i.hours,
        // cost: "Rs. " + i.cost,
      })
    })
    // settableData(data)
  }, [costingData])

  useEffect(() => {
    let data = []
    let tempData = DailyData?.costing?.sort((a, b) => {
      return new Date(b.CreatedAt) - new Date(a.CreatedAt)
    })
    tempData?.map((i) => {
      data.push({
        date: moment(i.CreatedAt).format('DD MMMM'),
        hours: Number(i.hours).toFixed(2) + ' hours',
        project: i?.project?.title,
        actions: (
          <div className='action-div'>
            {/* <AddRemarks
              state={i}
              id={i?._id}
              // getData={getData}
            /> */}
            <MdDelete
              className='icon delete'
              onClick={async () => {
                let res = await dispatch(
                  deleteRemark({
                    id: i?._id,
                  }),
                )
                if (res) {
                  GetData()
                }
              }}
            />
          </div>
        ),
      })
    })
    setDailyTableData(data)
  }, [DailyData])

  // const columns = [
  //   {
  //     title: t('month'),
  //     dataIndex: 'month',
  //   },
  //   {
  //     title: t('hours'),
  //     dataIndex: 'hours',
  //   },
  // ]

  const dailyColumns = [
    {
      title: t('date'),
      dataIndex: 'date',
    },
    {
      title: t('project'),
      dataIndex: 'project',
    },
    {
      title: t('hours'),
      dataIndex: 'hours',
    },
    {
      title: t('actions'),
      dataIndex: 'actions',
    },
  ]

  return (
    <Layout active={'employee-summary'}>
      {DailyData === null ? (
        <InfinityLoader />
      ) : (
        <div className='summary-container'>
          <br />
          <Row gutter={[20, 40]}>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('name')}: <span>{DailyData?.employee?.name}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('email')}: <span>{DailyData?.employee?.email}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('phone')}: <span>{DailyData?.employee?.phone}</span>{' '}
              </h2>
            </Col>
            <Col xs={24} md={12} xl={8}>
              <h2 className='label'>
                {t('designation')} : <span>{DailyData?.employee?.designation}</span>{' '}
              </h2>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col xs={24}>
              <div className='header'>
                <h3 className='summaryHeading'>
                  {SelectedMonth} {t('day-wise-daily-hours')}
                </h3>
                <div>
                  <DatePicker
                    placeholder={t('select-date')}
                    onChange={OnDailyDateChangeDate}
                    style={{ width: '200px', marginRight: '10px' }}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                  />
                  <DatePicker
                    placeholder={t('select-month')}
                    onChange={OnDailyDateChange}
                    style={{ width: '200px' }}
                    picker={'month'}
                    disabledDate={(d) => {
                      return d?.isAfter(moment())
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>

              <Table
                sticky
                dataSource={dailyTableData}
                className='project-table'
                columns={dailyColumns}
                pagination={false}
                scroll={{ x: 'auto', y: 500 }}
              />
              {/* <div className="card">
              <LineChart
                data={DailyData?.costing ? DailyData?.costing : []}
                yField="dailyHours"
                xField="day"
              />
            </div> */}
            </Col>
            {/* <Col xs={24}>
            <div className="header header2">
              <h3 className="summaryHeading ">
                Year {selectedYear} Monthly Hours
              </h3>
              <DatePicker
                placeholder="Select Year"
                onChange={OnCostingDateChange}
                picker="year"
                style={{ width: "200px" }}
                disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
              />
            </div>
            <div className="card card2">
              <ColumnChart
                data={costingData?.costing ? costingData?.costing : []}
                yField="hours"
                xField="month"
                alias
              />
            </div>
          </Col>
          <Col xs={24}>
            <br />
            <Table
              sticky
              dataSource={tableData}
              className="project-table"
              columns={columns}
              pagination={false}
            />
          </Col> */}
            {/* <Col
              xs={24}
              style={{
                marginTop: "20px",
              }}
            >
              <div className="header">
                <h3 className="summaryHeading">{SelectedMonth} Checkins</h3>
                <div>
                  <DatePicker
                    placeholder="Select Date"
                    onChange={OnDailyDateChangeDate}
                    style={{ width: "200px", marginRight: "10px" }}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                  />
                  <DatePicker
                    placeholder="Select Month"
                    onChange={OnDailyDateChange}
                    style={{ width: "200px" }}
                    picker={"month"}
                    disabledDate={(d) => {
                      return d?.isAfter(moment());
                      // return moment(d).day() === 0 || d?.isAfter(moment());
                    }}
                  />
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      )}
    </Layout>
  )
}

export default EmployeeSummary
