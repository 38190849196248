import { useState, useEffect } from 'react'
import { Table, Tooltip } from 'antd'
// import { useDispatch } from 'react-redux'
// import { MdDelete } from 'react-icons/md'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
// import { deleteRemark } from '../../redux'
// import AddRemarks from '../modal/AddRemarks'

const ProjectDetailTableTwo = ({ userType, detail, getData }) => {
  // console.log(getData)
  // console.log(userType)
  // const [TableData, setTableData] = useState([])
  const [tableDataTemp, setTableDataTemp] = useState([])
  // const dispatch = useDispatch()
  const { t } = useTranslation()
  // useEffect(() => {
  //   let data = []
  //   detail?.allUsersTimeline?.map((timeline) => {
  //     data.push({
  //       name: timeline?.user?.name,
  //       hours: timeline?.hours,
  //       cost: 'Rs. ' + timeline?.cost?.toFixed(0),
  //       remarks: !timeline?.remarks ? (
  //         '-'
  //       ) : (
  //         <Tooltip placement='top' title={timeline?.remarks}>
  //           {timeline?.remarks?.length > 20
  //             ? `${timeline?.remarks?.slice(0, 20)}...`
  //             : timeline?.remarks}
  //         </Tooltip>
  //       ),
  //       date: moment(timeline?.CreatedAt).local().format('D-MM-YYYY'),
  //       time: moment(timeline?.CreatedAt).local().format('hh:mm a'),
  //       //   actions: (
  //       //     <div className='action-div'>
  //       //       <AddRemarks state={timeline} id={timeline?._id} getData={getData} />
  //       //       <MdDelete
  //       //         className='icon delete'
  //       //         onClick={async () => {
  //       //           let res = await dispatch(
  //       //             deleteRemark({
  //       //               id: timeline?._id,
  //       //             }),
  //       //           )
  //       //           if (res) {
  //       //             getData()
  //       //           }
  //       //         }}
  //       //       />
  //       //     </div>
  //       //   ),
  //     })
  //   })
  //   setTableData(data)
  // }, [detail])

  useEffect(() => {
    let data = []
    detail?.allUsersTimeline?.map((timeline, index) => {
      if (data.length == 0) {
        data.push({
          key: index,
          id: timeline?.user?._id,
          name: timeline?.user?.name,
          email: timeline?.user?.email,
          phone: timeline?.user?.phone,
          data: [timeline],
        })
      } else {
        if (data?.find((val) => val.id == timeline?.user?._id) !== undefined) {
          // data[data.indexOf(timeline?.user?._id)].data?.append(timeline)
          let tempObj = data.filter((val) => val.id == timeline?.user?._id)
          tempObj[0]?.data?.push(timeline)
        } else {
          data.push({
            id: timeline?.user?._id,
            name: timeline?.user?.name,
            email: timeline?.user?.email,
            phone: timeline?.user?.phone,
            data: [timeline],
          })
        }
      }
      // data.push({
      //   name: timeline?.user?.name,
      //   hours: timeline?.hours,
      //   cost: 'Rs. ' + timeline?.cost?.toFixed(0),
      //   remarks: !timeline?.remarks ? (
      //     '-'
      //   ) : (
      //     <Tooltip placement='top' title={timeline?.remarks}>
      //       {timeline?.remarks?.length > 20
      //         ? `${timeline?.remarks?.slice(0, 20)}...`
      //         : timeline?.remarks}
      //     </Tooltip>
      //   ),
      //   date: moment(timeline?.CreatedAt).local().format('D-MM-YYYY'),
      //   time: moment(timeline?.CreatedAt).local().format('hh:mm a'),
      // })
    })
    setTableDataTemp(data)
  }, [detail])

  const Usercolumn = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('email'),
      dataIndex: 'email',
    },
    // {
    //   title: t('name'),
    //   dataIndex: 'name',
    // },
    {
      title: t('phone'),
      dataIndex: 'phone',
    },
  ]

  const subColumn = [
    {
      title: t('date'),
      dataIndex: 'date',
    },
    {
      title: t('submit-time'),
      dataIndex: 'time',
    },
    // {
    //   title: t('name'),
    //   dataIndex: 'name',
    // },
    {
      title: t('hours'),
      dataIndex: 'hours',
    },
    {
      title: t('remarks'),
      dataIndex: 'remarks',
    },
  ]

  return (
    <div>
      {/* <Table
          sticky
          dataSource={TableData}
          className='project-table'
          columns={Usercolumn}
          scroll={{ x: 700 }}
          pagination={TableData.length > 10 ? true : false}
        /> */}
      <Table
        sticky
        dataSource={tableDataTemp}
        columns={Usercolumn}
        expandedRowRender={(record) => {
          return (
            <Table
              columns={subColumn}
              dataSource={record.data?.map((timeline) => {
                return {
                  name: timeline?.user?.name,
                  hours: timeline?.hours,
                  cost: 'Rs. ' + timeline?.cost?.toFixed(0),
                  remarks: !timeline?.remarks ? (
                    '-'
                  ) : (
                    <Tooltip placement='top' title={timeline?.remarks}>
                      {timeline?.remarks?.length > 20
                        ? `${timeline?.remarks?.slice(0, 20)}...`
                        : timeline?.remarks}
                    </Tooltip>
                  ),
                  date: moment(timeline?.CreatedAt).local().format('D-MM-YYYY'),
                  time: moment(timeline?.CreatedAt).local().format('hh:mm a'),
                }
              })}
            />
          )
        }}
        rowExpandable={() => true}
        // className='project-table'
        scroll={{ x: 700 }}
        // pagination={TableData.length > 10 ? true : false}
        pagination={tableDataTemp.length > 10 ? true : false}
      />
    </div>
  )
}

export default ProjectDetailTableTwo
