import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BiArrowBack } from 'react-icons/bi'

import { deleteDocument, getEmployeeDocs } from '../../../../redux'
import { FiDelete, FiDownload } from 'react-icons/fi'
import { AiOutlineFileText } from 'react-icons/ai'
import { MdClose } from 'react-icons/md'

import moment from 'moment'

// Document Viewer
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

import { Modal } from 'antd'
import swal from 'sweetalert'

const DocumentView = (props) => {
  const {
    selectedEmployeeId,
    setActiveScreen,
    setSelectedEmployeeId,
    employeeDocs,
    setEmployeeDocs,
  } = props
  const dispatch = useDispatch()
  const [previewDoc, setPreviewDoc] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => setIsModalOpen(true)

  const handleCancel = () => setIsModalOpen(false)

  const getAllDocs = async () => {
    try {
      const result = await dispatch(getEmployeeDocs(selectedEmployeeId))
      if (result) {
        setEmployeeDocs(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    getAllDocs()
  }, [selectedEmployeeId])

  const downloadDoc = async (doc) => {
    const response = await fetch(doc?.documents[0])
    const blob = await response.blob()
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.target = '_blank'
    downloadLink.download = doc?.documents[0]
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }

  const previewDocument = (doc) => {
    const getType = (type) => {
      switch (type) {
        case '.pdf':
          return 'application/pdf'
        case '.jpg':
          return 'image/jpeg'
        case '.jpeg':
          return 'image/jpeg'
        case '.png':
          return 'image/png'
        case '.docx':
          return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        case '.xlsx':
          return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        case '.zip':
          return 'application/zip'
      }
    }
    setPreviewDoc([
      {
        uri: doc?.documents[0],
        fileType: getType(doc?.documents[0]?.slice(doc?.documents[0]?.lastIndexOf('.'))),
      },
    ])
    showModal()
  }

  // Component
  const Viewer = () => {
    return (
      <DocViewer
        documents={previewDoc}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
            disableFileName: false,
            retainURLParams: false,
          },
        }}
        style={{ height: '100%' }}
      />
    )
  }

  const deleteDoc = async (id) => {
    const willDelete = await swal({
      title: 'Are you sure?',
      text: 'Are you sure that you want to delete this Document?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancel', 'Delete'],
    })
    if (willDelete) {
      const res = await dispatch(
        deleteDocument({
          documentId: id,
        }),
      )
      if (res) {
        getAllDocs()
      }
    }
  }

  return (
    <>
      {/* Document Viewer Modal */}
      <Modal
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        height='100%'
        width='100%'
        bodyStyle={{ height: '100%' }}
        centered
        className='docViewerModal'
        closeIcon={
          <MdClose
            style={{
              color: 'black',
              backgroundColor: '#fff',
              borderRadius: '50%',
              fontSize: '20px',
            }}
          />
        }
      >
        <Viewer doc={previewDoc} />
      </Modal>

      {/* Card */}
      <div className='doc-view'>
        <BiArrowBack
          onClick={() => {
            setActiveScreen('table')
            setSelectedEmployeeId(null)
          }}
          className='backArrow'
        />
        <div className='main'>
          {employeeDocs?.map((doc, ind) => (
            <div key={ind} className='document-div' onClick={() => previewDocument(doc)}>
              <FiDownload
                className='download-icon'
                style={{ marginRight: '25px' }}
                onClick={(event) => {
                  event.stopPropagation()
                  downloadDoc(doc)
                }}
              />
              <FiDelete
                className='download-icon'
                onClick={(event) => {
                  event.stopPropagation()
                  deleteDoc(doc?._id)
                }}
              />
              <span className='date'>{moment(doc?.createdAt).format('DD-MM-YYYY')}</span>
              <AiOutlineFileText className='file-icon' />
              <p>{doc?.title}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DocumentView
