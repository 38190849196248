export const timeFormatter = (time) => {
  if (time !== '-') {
    const mins = `${time - Math.floor(time)}`
    if (mins !== '0') {
      return `${Math.trunc(time)} hrs ${Math.round(Number(mins) * 60)} mins`
    } else {
      return `${Math.trunc(time)} hrs`
    }
  } else {
    return '--'
  }
}

export function hoursToString(hours) {
  const totalSeconds = hours * 3600
  const hoursValue = Math.floor(totalSeconds / 3600)
  const minutesValue = Math.floor((totalSeconds % 3600) / 60)
  const secondsValue = totalSeconds % 60

  const formattedHours = String(hoursValue).padStart(2, '0')
  const formattedMinutes = String(minutesValue).padStart(2, '0')
  const formattedSeconds = String(secondsValue?.toFixed(0)).padStart(2, '0')

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const getTimeInHoursMins = (hours) => {
  if (Math.trunc(hours) === 0) return `${Math.round(Math.abs(hours % 1) * 60)} mins`
  return `${Math.trunc(hours)} hrs ${Math.round(Math.abs(hours % 1) * 60)} mins`
}
