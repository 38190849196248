import { useEffect, useState } from 'react'
import {
  Typography,
  Row,
  Col,
  Select,
  Pagination,
  Tabs,
  // DatePicker,
  Button,
  Table,
  notification,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import InfinityLoader from '../components/InfinityLoader'
import AttendanceCard from '../components/AttendanceCard'
import {
  getAttendanceData,
  getCustomAttendanceData,
  getDesignations,
  getEmployeesName,
} from '../redux'
import { getDepartments } from '../redux/actions/department'
import Layout from '../layout/Layout'
import moment from 'moment'
import { timeFormatter } from '../functions/timeFormatter'

const Todos = () => {
  const { TabPane } = Tabs
  const { t } = useTranslation()
  const [Data, setData] = useState(null)
  const dispatch = useDispatch()
  const departments = useSelector((state) => state.departments.departments)
  const designations = useSelector((state) => state.employees.designations)
  const employees = useSelector((state) => state.employees.employeesName)
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(1)
  const [dept, setDept] = useState(null)
  const [desg, setDesg] = useState(null)
  const [employee, setEmployee] = useState(null)
  const [DateRange, setDateRange] = useState(moment().format('YYYY-MM-DD'))
  const [TableData, setTableData] = useState([])
  const [filterLoader, setFilterLoader] = useState(false)
  const [totalPresents, setTotalPresents] = useState(null)
  const [totalAbsents, setTotalAbsents] = useState(null)
  const newData = { ...Data, response: { newValue: 'newValue' } }

  const deptFilter = async (value) => {
    setDept(value)
  }
  const desgFilter = async (value) => {
    setDesg(value)
  }
  const paginate = (page) => {
    setCurrent(page)
  }
  useEffect(() => {
    dispatch(getDepartments())
    dispatch(getDesignations())
    dispatch(getEmployeesName())
  }, [])

  const getAttendance = async () => {
    setLoading(true)
    const data = await getAttendanceData(dept, desg, current)
    setData(data)
    setLoading(false)
  }

  useEffect(() => {
    getAttendance()
  }, [dept, desg, current])

  useEffect(() => {
    let tempPresents = 0
    let tempAbsents = 0
    TableData?.last7Day?.map((value) => {
      if (value?.noOfCheckins > 0) {
        tempPresents++
      } else {
        tempAbsents++
      }
    })
    setTotalPresents(tempPresents)
    setTotalAbsents(tempAbsents)
  }, [TableData])

  // const onDateRangeChange = (date, dateString) => {
  //   setDateRange({
  //     start: dateString[0],
  //     end: dateString[1],
  //   })
  // }
  const employeeFilter = (e) => {
    setEmployee(e)
  }
  const handleChnage = (e) => {
    if (e === 'today') {
      setDateRange({
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      })
    }
    if (e === 'yesterday') {
      setDateRange({
        start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      })
    }
    if (e === 'last7Days') {
      setDateRange({
        start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      })
    }
    if (e === 'last30Days') {
      setDateRange({
        start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      })
    }
    if (e === 'thisMonth') {
      setDateRange({
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      })
    }
    if (e === 'lastMonth') {
      setDateRange({
        start: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      })
    }
  }
  const onFilter = async () => {
    if (!employee) {
      notification.error({
        message: 'Select Employee',
        duration: 4,
      })
    }
    if (!DateRange) {
      notification.error({
        message: 'Select Date Range',
        duration: 4,
      })
    }
    if (employee && DateRange) {
      setTableData([])
      setFilterLoader(true)
      const res = await getCustomAttendanceData(employee, DateRange)
      setTableData(res?.data)
      setFilterLoader(false)
    }
  }

  const attendanceColumns = [
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: '# of Checkins',
      dataIndex: 'noOfCheckins',
    },
    {
      title: 'First Check In',
      dataIndex: 'checkin',
    },
    {
      title: 'Last Check Out',
      dataIndex: 'checkout',
    },
    {
      title: 'Total Hours',
      dataIndex: 'hours',
    },
    {
      title: 'Timeline Hours',
      dataIndex: 'timeline',
    },
  ]

  return (
    <Layout active={'attendance'}>
      {loading === true ? (
        <InfinityLoader />
      ) : (
        <div className='attendance-container'>
          <Tabs defaultActiveKey='1' animated>
            <TabPane tab='Daily Attendance' key='1' style={{ padding: '20px 0' }}>
              <div className='todo-main'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography.Title level={4}>
                    {t('employees')} {t('attendance')}
                  </Typography.Title>
                  <Row
                    style={{
                      width: 'max-content',
                    }}
                    justify='space-between'
                  >
                    <Col md={11} sm={23}>
                      <Select
                        placeholder={t('filter-by-department')}
                        onChange={deptFilter}
                        value={dept}
                        style={{
                          width: 200,
                          marginRight: 10,
                          marginBottom: 10,
                          float: 'right',
                        }}
                      >
                        <Select.Option value={'all'}>{t('all')}</Select.Option>
                        {departments?.departments?.map((val, ind) => {
                          return (
                            <Select.Option key={ind} value={val._id}>
                              {val.name}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Col>
                    <Col md={11} sm={23}>
                      <Select
                        placeholder={t('filter-by-designation')}
                        onChange={desgFilter}
                        value={desg}
                        style={{
                          width: 200,
                          marginRight: 10,
                          marginBottom: 10,
                          float: 'right',
                        }}
                      >
                        <Select.Option value={'all'}>{t('all')}</Select.Option>

                        {designations?.employee?.map((val, ind) => {
                          return (
                            <Select.Option key={ind} value={val}>
                              {val}
                            </Select.Option>
                          )
                        })}
                      </Select>
                    </Col>
                  </Row>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: 20,
                    marginTop: 20,
                    marginRight: 20,
                  }}
                >
                  <Pagination current={current} total={Data?.totalPages * 10} onChange={paginate} />
                </div>
                <div className='attendance-container'>
                  {/* <Row className='projects' style={{ gap: 0 }} gutter={[20, 20]}>
                    {Data &&
                      Data?.response?.map((data, ind) => (
                        // {[1, 2, 3, 4, 5, 6].map((data) => (
                        <Col xs={24} lg={12} key={ind}>
                          <AttendanceCard Data={data} />
                        </Col>
                      ))}
                  </Row> */}
                  <Row>
                    {Data && (
                      <Col xs={24}>
                        <AttendanceCard tableData={Data} />
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </TabPane>
            <TabPane tab='Custom Attendance' key='2' style={{ padding: '20px 0' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography.Title level={4}>
                  {t('employees')} {t('attendance')}
                </Typography.Title>
                <Row
                  style={{
                    width: 'max-content',
                  }}
                  justify='space-between'
                >
                  <Col md={9} sm={23}>
                    <Select
                      placeholder={'Select Employee'}
                      // placeholder={t('filter-by-designation')}
                      onChange={employeeFilter}
                      value={employee}
                      style={{
                        width: 200,
                        marginRight: 10,
                        marginBottom: 10,
                        float: 'right',
                      }}
                    >
                      {employees?.employee?.map((val, ind) => {
                        return (
                          <Select.Option key={ind} value={val?._id}>
                            {val?.name}
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </Col>
                  {/* <Col md={5} sm={23}>
                    <DatePicker.RangePicker style={{ width: '95%' }} onChange={onDateRangeChange} />
                  </Col> */}
                  <Col md={5} sm={23}>
                    <Select
                      onChange={(e) => handleChnage(e)}
                      defaultValue='today'
                      style={{
                        width: 120,
                      }}
                      options={[
                        {
                          value: 'today',
                          label: 'Today',
                        },
                        {
                          value: 'yesterday',
                          label: 'Yesterday',
                        },
                        {
                          value: 'last7Days',
                          label: 'Last 7 Days',
                        },
                        {
                          value: 'last30Days',
                          label: 'Last 30 Days',
                        },
                        {
                          value: 'thisMonth',
                          label: 'This Month',
                        },
                        {
                          value: 'lastMonth',
                          label: 'Last Month',
                        },
                      ]}
                    />
                  </Col>
                  <Col md={5} sm={23}>
                    <Button
                      style={{ width: '100%' }}
                      type='primary'
                      onClick={onFilter}
                      loading={filterLoader}
                    >
                      Filter
                    </Button>
                  </Col>
                </Row>
              </div>
              <div>
                {TableData?.last7Day && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '1rem',
                      marginBottom: '1rem ',
                    }}
                  >
                    <div
                      style={{ color: 'var(--primary)', fontSize: '16px' }}
                    >{`Total Presents - ${totalPresents}`}</div>
                    <div
                      style={{ color: 'var(--primary)', fontSize: '16px' }}
                    >{`Total Absents - ${totalAbsents}`}</div>
                    {/* {console.log('TableData', TableData)} */}
                  </div>
                )}
                <Table
                  columns={attendanceColumns}
                  pagination={{ defaultPageSize: 31 }}
                  dataSource={
                    TableData?.last7Day?.map((val, ind) => {
                      return {
                        key: ind,
                        date: val?.date ? moment(val?.date)?.format('DD-MM-YYYY') : '--',
                        hours: timeFormatter(val?.hours),
                        noOfCheckins: val?.noOfCheckins,
                        timeline: timeFormatter(val?.timeline),
                        checkin: val?.checkin ? moment(val?.checkin).format('hh:mm a') : '--',
                        checkout: val?.checkout ? moment(val?.checkout).format('hh:mm a') : '--',
                      }
                    }) || []
                  }
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  )
}

export default Todos
