import { useState } from 'react'
import { Modal, Button } from 'antd'

const AddProject = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Button
        type='primary'
        className='addProjectBtn'
        onClick={showModal}
        style={{ marginRight: '20px' }}
      >
        View Links
      </Button>

      <Modal
        title={data?.title}
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        width={'600px'}
      >
        {data?.links?.map((link, ind) => (
          <div
            key={ind}
            style={{
              margin: '10px 0',
            }}
          >
            <a href={link} target='_blank' style={{ color: 'var(--blue)' }} rel='noreferrer'>
              {link}
            </a>
          </div>
        ))}
      </Modal>
    </>
  )
}

export default AddProject
