import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TbReportAnalytics } from 'react-icons/tb'
import { Table } from 'antd'

import { getAllEmployees } from '../../../../redux'
import DocumentView from './DocumentView'
import InfinityLoader from '../../../../components/InfinityLoader'

const Documents = ({
  activeTab,
  setShowDocModal,
  setSelectedEmployeeId,
  selectedEmployeeId,
  employeeDocs,
  setEmployeeDocs,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [employees, setEmployees] = useState(null)
  const [tableData, setTableData] = useState([])
  const [activeScreen, setActiveScreen] = useState('table')
  const [activePage, setActivePage] = useState(1)

  const getAllEmployeesData = async () => {
    try {
      const result = await dispatch(getAllEmployees())
      if (result) {
        setEmployees(result)
      }
    } catch (error) {
      // console.log('error', error)
    }
  }

  useEffect(() => {
    if (activeTab === 'documents') {
      getAllEmployeesData()
      setActiveScreen('table')
    } else {
      setSelectedEmployeeId(null)
    }
  }, [activeTab])

  useEffect(() => {
    if (activeScreen === 'table') {
      setShowDocModal(false)
    } else {
      setShowDocModal(true)
    }
  }, [activeScreen, activeTab])

  const onDocClick = (id) => {
    setActiveScreen('detail')
    setShowDocModal(true)
    setSelectedEmployeeId(id)
  }

  useEffect(() => {
    let data = []
    employees?.map((employee) => {
      data.push({
        name: employee?.name,
        email: employee?.email,
        phone: employee?.phone,
        docs: (
          <div className='action-buttons'>
            <TbReportAnalytics className='editBtn' onClick={() => onDocClick(employee?._id)} />
          </div>
        ),
      })
    })
    setTableData(data)
  }, [employees])

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('email'),
      dataIndex: 'email',
    },
    {
      title: t('phone'),
      dataIndex: 'phone',
    },
    {
      title: t('documents'),
      dataIndex: 'docs',
    },
  ]

  return (
    <div className='document-container'>
      {activeScreen === 'table' ? (
        employees ? (
          <Table
            pagination={{
              current: activePage,
              onChange: (page) => {
                setActivePage(page)
              },
            }}
            dataSource={tableData}
            className='project-table'
            columns={columns}
          />
        ) : (
          <div style={{ height: '60vh' }}>
            <InfinityLoader />
          </div>
        )
      ) : (
        <>
          {selectedEmployeeId && (
            <DocumentView
              selectedEmployeeId={selectedEmployeeId}
              setActiveScreen={setActiveScreen}
              setSelectedEmployeeId={setSelectedEmployeeId}
              employeeDocs={employeeDocs}
              setEmployeeDocs={setEmployeeDocs}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Documents
